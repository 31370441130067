export const getActiveTeamPlayers = (teamPlayers) =>
  Object.keys(teamPlayers).reduce((carrier, key) => {
    const teamPlayer = teamPlayers[key];

    if (!teamPlayer.active) {
      return carrier;
    }

    carrier[teamPlayer.club_member.id.toString()] = teamPlayer;

    return carrier;
  }, {});

export const getActiveTeamPlayersCount = (teamPlayers) =>
  Object.keys(getActiveTeamPlayers(teamPlayers)).length;

export const getMatchPlayersByTeamId = (matchPlayers, teamId) =>
  Object.keys(matchPlayers).reduce((result, clubMemberId) => {
    const matchPlayer = matchPlayers[clubMemberId];

    if (matchPlayer.team.id === teamId) {
      result[clubMemberId] = matchPlayer;
    }

    return result;
  }, {});
