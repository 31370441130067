import { useCallback, useEffect } from 'react';

type MaxLengthOptions = {
  // eslint-disable-next-line
  input: HTMLInputElement;
  maxLength?: number;
  value: string;
};

export const useMaxLength = ({ input, maxLength, value }: MaxLengthOptions) => {
  const data = useCallback(() => {
    // If ref is set, use that, otherwise use initial value from prop
    const val = input?.value ? input?.value.trim() : value.trim();
    const valLength = val?.length;
    const max = maxLength ? maxLength : input?.maxLength || 0;
    const remaining = max - valLength;
    const maxReached = remaining <= 0;

    return {
      val,
      valLength,
      max,
      remaining,
      maxReached,
    };
  }, [input, value, maxLength]);

  const validate = () => {
    const { maxReached, max } = data();
    if (maxReached) {
      input.setCustomValidity(
        `De lengte van de invoer is te lang, de maximale toegestane lengte is ${max} karakters.`
      );
    } else {
      input.setCustomValidity('');
    }
  };

  useEffect(() => {
    const onChange = () => {
      validate();
    };

    input?.addEventListener('change', onChange);

    return () => input?.removeEventListener('change', onChange);
  }, [input]);

  return data();
};
