import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../@types/Permissions';
import { withMatch } from '../../contexts/MatchContext';
import { useMatch } from '../../hooks/useMatch';
import { AuthorizedCan } from '../atoms/AuthorizedCan';
import ChevronRight from '../atoms/Icons/ChevronRight';
import IconButton from '../molecules/IconButton';
import { theme } from '../templates/ui';

const StyledIconButton = styled(IconButton)`
  background-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};
  padding-right: 45px;
  font-size: ${theme.font.base};

  &:hover,
  &:focus {
    background-color: ${theme.color.gray.alt};
  }
`;

const StyledChevron = styled(ChevronRight)`
  fill: ${theme.color.gray.darkest};
`;

const MatchCancellation = ({ matchGame, onUnCancelMatch }) => {
  const [disableUnCancelMatchButton, setDisableUnCancelMatchButton] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const { isCancelled } = useMatch();

  return (
    <>
      <AuthorizedCan has={[PERMISSIONS.CANCEL_MATCH]}>
        {/* <StyledContainer className={className}>
          <StyledWrapper> */}
        {/* Cancel match */}
        {!isCancelled && (
          <StyledIconButton
            data-testid="cancel-match-button"
            onClick={() => {
              history.push(`${match.url}/cancellation`);
            }}
          >
            Wedstrijd afgelasten of staken
            <StyledChevron />
          </StyledIconButton>
        )}

        {/* Uncancel match */}
        {isCancelled && (
          <StyledIconButton
            onClick={() => {
              setDisableUnCancelMatchButton(true);
              onUnCancelMatch(matchGame.uuid).then(() => {
                setDisableUnCancelMatchButton(false);
              });
            }}
            disabled={disableUnCancelMatchButton}
            data-testid="uncancel-match-button"
          >
            Wedstrijd afgelasting of staking ongedaan maken
            <StyledChevron />
          </StyledIconButton>
        )}
        {/* </StyledWrapper>
        </StyledContainer> */}
      </AuthorizedCan>
    </>
  );
};

export default withMatch(MatchCancellation);
