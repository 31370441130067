import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledIndicator = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.primary.base};
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: ${theme.color.body.base};
  font-size: ${theme.font.medium};
  font-weight: 700;
`;

export const TeamRoleIndicator = (props) => (
  <StyledIndicator data-testid="team-role-indicator" {...props} />
);
