import React from 'react';
import { Helmet } from 'react-helmet';
import { styled } from 'styled-components';
import TopBar from '../../molecules/TopBar';
import { Layout } from '../../templates/Layout';
import { theme } from '../../templates/ui';

const StyledHeader = styled.h1`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.xxxxlarge};
`;

const StyledTitle = styled.h1`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.xxxlarge};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.sizing.xlarge};
  text-align: center;
`;

const TransparentButton = styled.button`
  background-color: transparent;
  border-color: transparent;
  color: ${theme.color.primary.base};
  cursor: pointer;
  padding-left: 0;

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

export const NotFound = (props) => (
  <Layout>
    <Helmet>
      <title>Digitaal Wedstrijd Formulier - Pagina niet gevonden</title>
      <meta name="robots" content="noindex" />
    </Helmet>
    <TopBar back={props.back}>Niet gevonden</TopBar>
    <Wrapper>
      <div>
        <StyledHeader>Niet gevonden</StyledHeader>
        <StyledTitle>{props.message}</StyledTitle>
        <p>
          Ga terug naar
          <TransparentButton onClick={props.back}>de homepagina</TransparentButton>
        </p>
      </div>
    </Wrapper>
  </Layout>
);
