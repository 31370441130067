import React from 'react';
import { styled } from 'styled-components';
import Caution from '../atoms/Icons/Caution';
import ChevronDown from '../atoms/Icons/ChevronDown';
import { theme } from '../templates/ui';

const StyledAnnouncementToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.h4`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.base};
  font-weight: 700;
  font-size: ${theme.font.medium};
  margin: 0;
`;

const Toggle = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.color.primary.base};
  font-weight: 600;
  font-size: ${theme.font.small};
`;

const StyledCaution = styled(Caution)`
  fill: ${theme.color.primary.base};
  width: 15px;
  height: 14px;
  margin-right: ${theme.sizing.small};
`;

const StyledChevronDown = styled((isOpen, props) => <ChevronDown {...props} />)`
  fill: ${theme.color.primary.base};
  width: 10px;
  height: 6px;
  margin-top: ${theme.sizing.xsmall};
  margin-left: ${theme.sizing.small};
  transform: ${(props) => (props.isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)')};
`;

const Open = styled.span`
  opacity: ${(props) => (props.isOpen ? '0' : '1')};
  display: ${(props) => (props.isOpen ? 'none' : 'block')};
`;

const Close = styled.span`
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

const AnnouncementToggle = ({ onClick, isOpen, hasMultipleAnnouncements }) => (
  <StyledAnnouncementToggle onClick={onClick} data-testid="announcement-toggle">
    <Title>
      <StyledCaution />
      {`Mededeling${!!hasMultipleAnnouncements ? `en` : ``}`}
    </Title>
    <Toggle>
      <Open isOpen={isOpen}>Openen</Open>
      <Close isOpen={isOpen}>Sluiten</Close>
      <StyledChevronDown isOpen={isOpen} />
    </Toggle>
  </StyledAnnouncementToggle>
);

export default AnnouncementToggle;
