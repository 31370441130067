const handleScrollToInput = (input, container) => {
  const { top, height } = input.getBoundingClientRect();
  const offset = 10;
  const scroll = top - height - offset; // Make sure we don't just scroll to the top of the element, we want the entire element to be visible

  try {
    container.scrollBy({ top: scroll, behavior: 'smooth' });
  } catch (e) {
    container.scrollBy(0, scroll);
  }
};

export const scrolltoElementWhenMobileKeyboardAppears = (element, container = window) => {
  window.addEventListener(
    'resize',
    () => {
      handleScrollToInput(element, container);
    },
    {
      once: true,
    }
  );
};
