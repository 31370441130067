import React from 'react';
import { theme } from '../../templates/ui';

type Props = React.SVGAttributes<SVGElement>;

const Info = ({ className }: Props) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g transform="translate(1 1)" fill="none">
      <path
        d="M14.415 6.191c.39-.363.585-.803.585-1.32 0-.515-.195-.955-.585-1.32A1.982 1.982 0 0 0 13.008 3a2 2 0 0 0-1.411.55c-.39.366-.587.806-.587 1.322s.197.956.587 1.319a2.01 2.01 0 0 0 1.411.545c.55 0 1.017-.182 1.407-.545zm-.898 12.125c.703-.329 1.075-.948.71-1.23-.21-.163-.486.106-.688.106-.432 0-.737-.07-.912-.213-.177-.144-.262-.41-.262-.803 0-.157.025-.387.079-.692.053-.305.116-.578.183-.816l.836-2.96a4.94 4.94 0 0 0 .168-.895c.03-.327.045-.554.045-.682 0-.623-.22-1.13-.657-1.522-.434-.39-1.057-.586-1.864-.586-.448 0-.925.066-1.425.24-.979.343-.761 1.196-.544 1.196.441 0 .737.075.894.225.157.148.237.412.237.793 0 .208-.029.443-.078.697-.052.254-.112.524-.188.808l-.84 2.971c-.072.312-.126.592-.162.84-.033.248-.049.49-.049.729 0 .61.224 1.112.674 1.51.45.399 1.143.768 1.954.768.527 0 1.28-.196 1.889-.484z"
        fill={theme.color.gray.white}
      />
      <circle stroke={theme.color.gray.white} cx="11.5" cy="11.5" r="11.5" />
    </g>
  </svg>
);

export default Info;
