import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Edit = ({ className }: Props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M4.764 10.09l3.054 3.054.005-.006 8.127-8.127-3.054-3.054-.005.006zM4.057 10.823l-.003.008-.003-.003-1.255 4.284 4.283-1.256-.003-.003.008-.002zM17.401 1.117l-.61-.611a1.727 1.727 0 0 0-2.444 0L14.054.8l-.438.437 3.054 3.054.005-.005.288-.288.432-.432.006-.006a1.728 1.728 0 0 0 0-2.443z" />
    <path d="M15.278 16.666H1.427V2.816h9.087l1.334-1.335H.093V18h16.519V5.873l-1.334 1.334z" />
  </svg>
);

export default Edit;
