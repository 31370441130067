import React, { createContext } from 'react';

const ErrorContext = createContext({
  message: '',
  title: '',
  showError: null,
  showGenericError: null,
});

export const withErrorHandler = (Component) => {
  function withErrorHandler(props) {
    return (
      <ErrorContext.Consumer>
        {({ _message, _title, showError, showGenericError }) => (
          <Component {...props} showError={showError} showGenericError={showGenericError} />
        )}
      </ErrorContext.Consumer>
    );
  }
  withErrorHandler.displayName = `withErrorHandler(${Component.displayName})`;

  return withErrorHandler;
};

export default ErrorContext;
