import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledTitle = styled.h3`
  color: ${theme.color.base.base};
  font-size: ${theme.font.large};
  padding-bottom: ${theme.sizing.small};
  margin-top: ${theme.sizing.large};
  margin-bottom: 0;
  border-bottom: 2px solid ${theme.color.primary.base};
`;

export const TitleUnderline = ({ children, className }) => (
  <StyledTitle className={className}>{children}</StyledTitle>
);
