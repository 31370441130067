import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Pencil = ({ className, fill }: Props) => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" className={className} fill={fill}>
    <path d="M2.764 10.09l3.054 3.054.005-.006 8.127-8.127-3.054-3.054-.005.006-8.127 8.127zm-.707.733l-.003.008-.003-.003-1.255 4.284 4.283-1.256-.003-.003.008-.002-3.027-3.028zm13.344-9.706l-.61-.611a1.727 1.727 0 0 0-2.444 0L12.054.8l-.438.437 3.054 3.054.005-.005.288-.288.432-.432.006-.006a1.728 1.728 0 0 0 0-2.443z" />
  </svg>
);

export default Pencil;
