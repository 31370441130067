import env from '../env';

export default {
  sso_client_id: env.sso_client_id,
  sso_scope: env.sso_scope,
  sso_callback_uri: env.sso_callback_uri,
  sso_configuration_endpoint: env.sso_configuration_endpoint,
  sso_endpoint: env.sso_endpoint,
};

export const redirect_after_logout = env.sso_endpoint + '?logout=true';
