import { nanoid } from 'nanoid';

const VERIFIER_KEY = 'VERIFIER';
const NONCE_KEY = 'NONCE';
const STATE_KEY = 'STATE';

const memoizeToLocalStorage = (cacheKey, newValue) => {
  let value = window.localStorage.getItem(cacheKey);

  if (!value) {
    window.localStorage.setItem(cacheKey, newValue);

    value = newValue;
  }

  return value;
};

export const getPKCECodeVerifier = () => memoizeToLocalStorage(VERIFIER_KEY, nanoid(128));
export const clearPKCECodeVerifier = () => window.localStorage.removeItem(VERIFIER_KEY);
export const getPKCECodeChallenge = getPKCECodeVerifier;
export const getPKCENonce = () => memoizeToLocalStorage(NONCE_KEY, nanoid(32));
export const clearPKCENonce = () => window.localStorage.removeItem(NONCE_KEY);
export const getPKCEState = () => memoizeToLocalStorage(STATE_KEY, nanoid(32));
export const clearPKCEState = () => window.localStorage.removeItem(STATE_KEY);

export const clearPKCEData = () => {
  clearPKCECodeVerifier();
  clearPKCENonce();
  clearPKCEState();
};
