import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Checkmark = ({ className }: Props) => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M14.494.583l-8.02 8.02L2.12 4.249.417 6.013l6.058 6.009 9.727-9.728z" />
  </svg>
);

export default Checkmark;
