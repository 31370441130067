import React, { useContext } from 'react';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../@types/Permissions';
import { withMatch } from '../../contexts/MatchContext';
import { RoleContext, hasPermissionsCheck } from '../../contexts/RoleContext/RoleContext';
import { useMatch } from '../../hooks/useMatch';
import { getGivenCardsForPlayer } from '../../services/card';
import { canAddPlayer } from '../../services/permissions';
import { fullName } from '../../services/person';
import { getActiveTeamPlayersCount } from '../../services/players';
import { validateMaxPlayers, validateMinPlayers } from '../../services/validator';
import { Player } from '../atoms/Player';
import { theme } from '../templates/ui';

const PlayerWrapper = styled.ul`
  margin: 0;
  padding: 0 0 ${theme.sizing.xxxxlarge} 0;
  list-style: none;
`;

const StyledPlayersAmount = styled.div`
  border-top: 1px solid ${theme.color.gray.light};
  padding: ${theme.sizing.xlarge};
`;

const StyledPlayersAmountRow = styled.div`
  margin-bottom: ${theme.sizing.large};
  padding-bottom: ${theme.sizing.large};
  border-bottom: 1px dashed ${theme.color.gray.light};

  &:last-child {
    border: none;
    margin: 0;
  }
`;

const StyledPlayersAmountLabel = styled.label`
  font-weight: 600;
`;

const StyledPlayersAmountNumber = styled.span`
  display: inline-block;
  color: ${theme.color.primary.base};
  font-weight: 700;
  min-width: 22px;
  text-align: right;
  margin-right: ${theme.sizing.small};
  ${({ error }) => error && `color: ${theme.color.error.base};`};
  ${({ success }) => success && `color: ${theme.color.secondary.base};`};
`;

const StyledOuterWrapper = styled.div`
  background-color: ${theme.color.gray.base};
  border-bottom: 1px solid ${theme.color.gray.light};
  margin-bottom: 25px;

  @media only screen and (max-width: ${theme.breakpoints.medium}) {
    margin-bottom: 45px;
  }
`;

const getPlayers = ({
  players,
  user,
  onJerseyNumberChange,
  toggleMatchPlayer,
  matchRefereeConfirmed,
  hasStarted,
  jerseyNumbersActive,
  matchGame,
  teamId,
  role,
}) =>
  Object.values(players)
    .sort((a, b) => {
      const result =
        a.club_member.person.family_name.localeCompare(b.club_member.person.family_name) ||
        a.club_member.person.given_name.localeCompare(b.club_member.person.given_name);

      return result;
    })
    .filter((player) => {
      const playerIsActive = player.active;

      // Active players should always be shown to the user
      if (playerIsActive) {
        return true;
      }

      const hasCorrectPermissions = hasPermissionsCheck(
        role,
        [PERMISSIONS.CORRECT_AFTER_CLOSING, PERMISSIONS.MANAGE_ALL_TEAMS],
        { policy: 'some' }
      );

      const hasManagePermissions = hasPermissionsCheck(role, [PERMISSIONS.MANAGE_TEAM], {
        teamId: role.team_id,
      });

      // Is the current user part of this team
      const isTeam = role.team_id === teamId;

      // User has correct permissions or can manage this team
      if (hasCorrectPermissions || (isTeam && hasManagePermissions)) {
        return true;
      }

      // User is not in the correct team and does not have the correct permissions
      return false;
    })
    .map((player) => {
      const playerIsActive = !!player.active;
      const substitute = !!player.is_substitute;
      let disabled = false;

      if (!canAddPlayer(player) || (window.location.search.includes('ws=true') && user.secretary)) {
        disabled = true;
      }

      return (
        <Player
          matchRefereeConfirmed={matchRefereeConfirmed}
          jerseyNumber={player.jersey_number}
          name={fullName(player.club_member.person)}
          checkboxId={player.id}
          key={player.club_member.id}
          active={playerIsActive}
          clubMemberId={player.club_member.id}
          onJerseyNumberChange={onJerseyNumberChange}
          toggleMatchPlayer={toggleMatchPlayer}
          hasStarted={hasStarted}
          jerseyNumbersActive={jerseyNumbersActive}
          disabled={disabled}
          substitute={substitute}
          cards={getGivenCardsForPlayer(player.club_member.id, matchGame)}
          teamId={teamId}
        />
      );
    });

const Players = ({
  user,
  club,
  className,
  players,
  onJerseyNumberChange,
  toggleMatchPlayer,
  matchGame,
  matchRefereeConfirmed,
  jerseyNumbersActive,
  teamId,
}) => {
  const activePlayerCount = getActiveTeamPlayersCount(players);

  const { role, permissions, team_id } = useContext(RoleContext);

  const { hasStarted } = useMatch();

  const error =
    !validateMinPlayers(matchGame.dwf_options.players_amount_min, activePlayerCount) ||
    !validateMaxPlayers(matchGame.dwf_options.players_amount_max, activePlayerCount);

  const success =
    validateMinPlayers(matchGame.dwf_options.players_amount_min, activePlayerCount) &&
    validateMaxPlayers(matchGame.dwf_options.players_amount_max, activePlayerCount);

  const activePlayersCount = getActiveTeamPlayersCount(players);

  return (
    <StyledOuterWrapper>
      <StyledPlayersAmount error={error} success={success}>
        <StyledPlayersAmountRow>
          <StyledPlayersAmountNumber error={error} success={success}>
            {activePlayersCount}
          </StyledPlayersAmountNumber>
          <StyledPlayersAmountLabel>
            {activePlayersCount === 1 ? 'speler opgesteld' : 'spelers opgesteld'}
          </StyledPlayersAmountLabel>
        </StyledPlayersAmountRow>
        {!!matchGame.dwf_options.players_amount_min && (
          <StyledPlayersAmountRow>
            <StyledPlayersAmountNumber>
              {matchGame.dwf_options.players_amount_min}
            </StyledPlayersAmountNumber>
            <StyledPlayersAmountLabel>spelers minimaal op te stellen</StyledPlayersAmountLabel>
          </StyledPlayersAmountRow>
        )}
        {!!matchGame.dwf_options.players_amount_max && (
          <StyledPlayersAmountRow>
            <StyledPlayersAmountNumber error={error} success={success}>
              {matchGame.dwf_options.players_amount_max}
            </StyledPlayersAmountNumber>
            <StyledPlayersAmountLabel>spelers maximaal op te stellen</StyledPlayersAmountLabel>
          </StyledPlayersAmountRow>
        )}
      </StyledPlayersAmount>
      <PlayerWrapper className={className} id="player_wrapper">
        {getPlayers({
          players,
          club,
          user,
          onJerseyNumberChange,
          toggleMatchPlayer,
          matchRefereeConfirmed,
          hasStarted,
          jerseyNumbersActive,
          matchGame,
          teamId,
          role: { role, permissions, team_id },
        })}
      </PlayerWrapper>
    </StyledOuterWrapper>
  );
};

export default withMatch(Players);
