import React from 'react';
import { theme } from '../../templates/ui';

type Props = React.SVGAttributes<SVGElement>;

const RedCard = (props: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="card-red"
    {...props}
  >
    <circle cx="50%" cy="50%" r="50%" fill={theme.color.cards.red} />
  </svg>
);

export default RedCard;
