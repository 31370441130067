import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledLabel = styled.label`
  display: block;
  margin-bottom: ${theme.sizing.small};
  font-weight: 700;
  font-size: ${theme.font.base};
  color: ${theme.color.base.base};
`;

export const Label = ({ className, children, ...props }) => (
  <StyledLabel className={className} {...props}>
    {children}
  </StyledLabel>
);
