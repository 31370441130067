import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const PlusIcon = ({ className }: Props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M12.077 19.358v-5.435H6.642v-1.846h5.435V6.642h1.846v5.435h5.435v1.846h-5.435v5.435h-1.846zM13 1.847C6.85 1.847 1.847 6.849 1.847 13c0 6.15 5.003 11.153 11.153 11.153S24.153 19.15 24.153 13 19.151 1.847 13 1.847zM13 26C5.832 26 0 20.168 0 13S5.832 0 13 0c7.169 0 13 5.832 13 13s-5.831 13-13 13z" />
  </svg>
);

export default PlusIcon;
