import { useCallback, useContext } from 'react';
import { RoleContext } from '../contexts/RoleContext/RoleContext';
import { isCoachOfTeam as isCoachOfTeamService } from '../services/user';
import { useHasRole } from './useHasRole';

/**
 * useHasTeam
 * uses the current user to check if he has a certain team or is active as a role in a certain team
 * @example const { hasTeam, isTeam } = useHasTeam();
 * @returns {Object} various functions to check if the user has roles for a team or is active as a role in a team
 */

export const useHasTeam = () => {
  const { role } = useContext(RoleContext);
  const { isCoach } = useHasRole();

  /*
   * Checks if a user is currently active as role in a certain team.
   */

  const isTeam = useCallback(
    (teamIdToCheck) => {
      return role?.team_id === teamIdToCheck;
    },
    [role]
  );

  /*
   * Checks if a user is currently active as a coach for a certain team.
   */

  const isCoachOfTeam = useCallback(
    (teamIdToCheck) => {
      return isCoach && isCoachOfTeamService(teamIdToCheck, role);
    },
    [role]
  );

  return {
    isTeam,
    isCoachOfTeam,
  };
};
