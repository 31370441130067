import { useContext, useMemo } from 'react';
import { PERMISSIONS } from '../@types/Permissions';
import MatchContext from '../contexts/MatchContext';
import { RoleContext } from '../contexts/RoleContext/RoleContext';
import UserContext from '../contexts/UserContext';
import {
  canCancelMatch as canCancelMatchService,
  canConfirmMatch as canConfirmMatchService,
  canConfirmPlayers as canConfirmPlayersService,
  canManageTeamSupport as canManageTeamSupportService,
  canMatchScoreBeUpdated,
  canSeeQuestions as canSeeQuestionsService,
  canUnCancelMatch as canUnCancelMatchService,
} from '../services/permissions';
import { useHasRole } from './useHasRole';
import { useMatch } from './useMatch';

/**
 * useHasPermission
 * uses the current user to check if he has a certain permission
 * @example const { canCancelMatch } = useHasPermission();
 * @returns {Object} various booleans to indicate if a user has a certain permission based on the current role and the state of the match
 */

export const useHasPermission = () => {
  const { role, hasPermissions } = useContext(RoleContext);
  const { match } = useContext(MatchContext);
  const user = useContext(UserContext);

  const { isSecretary } = useHasRole();

  const { isOpen, hasStarted } = useMatch();

  const canCancelMatch = useMemo(() => {
    return match && canCancelMatchService(match, isSecretary, role);
  }, [match, role]);

  const canUnCancelMatch = useMemo(() => {
    return match && canUnCancelMatchService(match, isSecretary, role);
  }, [match, role]);

  const canConfirmPlayers = useMemo(() => {
    return match && canConfirmPlayersService(match, role?.team_id, role);
  }, [match, role]);

  const canConfirmMatch = useMemo(() => {
    return match && canConfirmMatchService(user, match, isSecretary, role);
  }, [match, role, user]);

  const canSeeQuestions = useMemo(() => {
    return canSeeQuestionsService();
  }, [match, role, user]);

  const canUpdateMatchScore = useMemo(() => {
    const hasPermission = hasPermissions([PERMISSIONS.MANAGE_SCORE]);
    const hasPermissionAfterClosing = hasPermissions([PERMISSIONS.CORRECT_AFTER_CLOSING]);

    return (
      match && ((hasPermission && canMatchScoreBeUpdated(match, user)) || hasPermissionAfterClosing)
    );
  }, [match, user, role]);

  const canManageCards = useMemo(() => {
    return hasStarted && hasPermissions([PERMISSIONS.MANAGE_CARDS]);
  }, [role]);

  const canAddCards = useMemo(() => {
    return hasStarted && hasPermissions([PERMISSIONS.MANAGE_CARDS]);
  }, [role]);

  const canAddComments = useMemo(() => {
    return (
      (isOpen && hasPermissions([PERMISSIONS.ENTER_COMMENT])) ||
      hasPermissions([PERMISSIONS.ENTER_COMMENT, PERMISSIONS.CORRECT_AFTER_CLOSING])
    );
  }, [role, isOpen]);

  const canEditAllComments = useMemo(() => {
    return hasPermissions([PERMISSIONS.ENTER_COMMENT, PERMISSIONS.MANAGE_COMMENTS]);
  }, [role]);

  const canManageTeamSupport = useMemo(() => {
    return match && canManageTeamSupportService(role);
  }, [role]);

  return {
    canManageTeamSupport,
    canConfirmPlayers,
    canCancelMatch,
    canUnCancelMatch,
    canConfirmMatch,
    canUpdateMatchScore,
    canSeeQuestions,
    canAddCards,
    canManageCards,
    canAddComments,
    canEditAllComments,
  };
};
