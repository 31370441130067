import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: ${theme.font.medium};
  line-height: 14px;
  font-weight: ${(props) => {
    if (props.cancellation) {
      return '600';
    }

    return 'inherit';
  }};
  color: ${(props) => {
    if (props.cancellation) {
      return theme.color.error.base;
    }

    return 'inherit';
  }};
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 5px;
`;

export const Meta = ({ icon, value, className, children, cancellation, ...props }) => (
  <StyledMeta className={className} cancellation={cancellation} {...props}>
    <IconWrapper>{icon}</IconWrapper>
    {value} {children}
  </StyledMeta>
);
