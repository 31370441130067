const removeSpaces = (str) =>
  str
    .split(/\s+/)
    .filter((word) => !!word)
    .join(' ');

export const fullName = (person) =>
  removeSpaces(
    `${person.given_name} ${person.name_prefix || person.middle_name || ''} ${
      person.family_name || ''
    } `
  );
