import React, { Component, useContext } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { produce } from 'immer';
import 'normalize.css/normalize.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import { StyleSheetManager, styled } from 'styled-components';
import { getPersonByFederationReferenceId } from '../api/auth';
import {
  ID_TOKEN_KEY,
  clearErrorFromLocalStorage,
  getErrorFromLocalStorage,
  getIdToken,
  getJWTFromAuthenticationCode,
  hasErrorSavedInLocalStorage,
  logout,
  redirectToSso,
  saveErrorToLocalStorage,
  saveIdToken,
} from '../api/sso';
import { BAD_REQUEST } from '../api/status_codes';
import AppContext from '../contexts/AppContext';
import AppError from '../contexts/AppErrorContext';
import ErrorContext from '../contexts/ErrorContext';
import MatchContext from '../contexts/MatchContext';
import { RoleContext, RoleContextProvider } from '../contexts/RoleContext/RoleContext';
import RolesContextProvider, { RolesContext } from '../contexts/RolesContext/RolesContext';
import UserContext from '../contexts/UserContext';
import { savePerson } from '../services/auth';
import { notifyError } from '../services/bugsnag';
import { getAuthRedirectUrl } from '../services/localStorage';
import { clearPKCEData, getPKCENonce, getPKCEState } from '../services/pkce';
import { SSORetriesToHigh, clearSSORetries, getSSOConfiguration } from '../services/sso';
import Storage from '../services/storage';
import PrivateRoute from './containers/PrivateRoute';
import Callback from './containers/auth/Callback';
import Index from './containers/matches/Index';
import Show from './containers/matches/Show';
import ErrorPopover from './molecules/ErrorPopover';
import ModalRoleSwitch from './organisms/ModalRoleSwitch';
import Playerlist from './organisms/Playerlist';
import { Page404 } from './pages/404';
import { Account } from './pages/Account';
import { BadRequest } from './pages/BadRequest';
import { Faq } from './pages/Faq';
import { FaqCoach } from './pages/FaqCoach';
import { FaqIntroduction } from './pages/FaqIntroduction';
import { FaqLegend } from './pages/FaqLegend';
import { FaqReferee } from './pages/FaqReferee';
import { FatalErrorScreen } from './pages/FatalErrorScreen';
import { Home } from './pages/Home';
import { LoadingScreen } from './pages/LoadingScreen';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { Questions } from './pages/Questions';
import { theme } from './templates/ui';

const StyledApp = styled.div`
  *,
  & {
    box-sizing: border-box;
    font-family: ${theme.font.family};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: ${theme.color.gray.darkest};
  transition: all 0.3s ease-out;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
});

/*
 * The App component itself has not yet been refactored to be a
 * functional component, so we wrap the ModalRoleSwitch component in a functional
 * component, to make sure we can use the Permissions and Role contexts in the props
 * we attach to the ModalRoleSwitch component.
 */

const RoleSwitch = () => {
  const { roleSwitchModalActive, setRoleSwitchModalActive, isLoading } = useContext(RolesContext);

  const { roleManuallySelected, setRoleManuallySelected } = useContext(RoleContext);

  const routeMatch = useRouteMatch('/matches/:id');

  return (
    <ModalRoleSwitch
      showPopup={roleSwitchModalActive}
      showAdditionalCloseButton={false}
      onClick={() => {
        setRoleSwitchModalActive(false);

        // No role selected, but we're already on a MDP, so we have a default role
        if (!roleManuallySelected && routeMatch?.params?.id) {
          setRoleManuallySelected(true);
        }
      }}
      isLoading={isLoading}
    />
  );
};

class App extends Component {
  localStorageNotSupportedMessage =
    'U bekijkt het DWF in een browser die dit niet ondersteunt. Zet de' +
    ' incognitomodus van de browser uit of probeer het DWF te openen in Google Chrome.';
  SSOConfigurationFailureMessage =
    'Het is niet gelukt om de login portaal gegevens op te halen, probeer op een later moment het DWF opnieuw te openen.';
  SSOTokenCallBrokenMessage =
    'Het is niet mogelijk om je gegevens op te halen, probeer op een later moment het DWF opnieuw te openen.';

  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === 'development') {
      this.SSOConfigurationFailureMessage = `${this.SSOConfigurationFailureMessage} -- Staat je MAMP aan?`;
    }
  }

  showError = (message, title = 'Oops..', showCloseButton = true) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        error: { ...prevState.error, message, title, showCloseButton },
      };
    });
  };

  showGenericError = (error, showCloseButton = true, displayError = false) => {
    if (displayError && error.response.data.message) {
      this.showError(error.response.data.message, 'Melding', showCloseButton);

      return;
    }

    if (
      displayError ||
      (error.response && error.response.status >= 500 && error.response.status < 600)
    ) {
      this.showError(
        'Er is een fout opgetreden. Probeer het later nogmaals.',
        'Er is een fout opgetreden',
        showCloseButton
      );

      return;
    }

    if (error.response && error.response.status === 404) {
      this.handleAppError(error);

      return;
    }
  };

  updateMatch = async (match, callback, _pageKey) => {
    this.setState(
      produce((draft) => {
        draft.match.match = {
          ...draft.match.match,
          ...match,
        };
      }),
      () => {
        if (callback) callback();
      }
    );
  };

  handleLocalStorageError = () => {
    const { pageKey, apiCallKey, error, metaData } = getErrorFromLocalStorage();

    notifyError(error, pageKey, apiCallKey, {
      onError: (event) => {
        if (!(metaData instanceof Object)) {
          return event;
        }

        for (const section in metaData) {
          let data = metaData[section];

          if (!(data instanceof Object)) {
            // Bugsnag needs an object in an object or else it won't render the metadata.
            data = {
              value: data,
            };
          }

          event.addMetadata(section, data);
        }

        return event;
      },
    });
  };

  async appBootingCheck() {
    let fatalError = null;

    try {
      localStorage.setItem('foo', 'bar');
      localStorage.removeItem('foo');
    } catch (e) {
      fatalError = this.localStorageNotSupportedMessage;
    }

    if (SSORetriesToHigh() && fatalError === null && hasErrorSavedInLocalStorage()) {
      this.handleLocalStorageError();

      fatalError = this.SSOTokenCallBrokenMessage;

      clearSSORetries();
      clearErrorFromLocalStorage();
    }

    if (fatalError === null) {
      try {
        await getSSOConfiguration();
      } catch (e) {
        fatalError = this.SSOConfigurationFailureMessage;
      }
    }

    this.setState({ fatalError });

    return fatalError;
  }

  setAppLoading = (isLoading) =>
    this.setState(
      produce((draft) => {
        draft.app.isLoading = isLoading;
      })
    );

  handleAppError = (error) =>
    this.setState(
      produce((draft) => {
        draft.appError.error = error;
      })
    );

  clearAppError = () =>
    this.setState(
      produce((draft) => {
        draft.appError.error = null;
      })
    );

  state = {
    error: {
      message: '',
      title: '',
      showError: this.showError,
      showGenericError: this.showGenericError,
      showCloseButton: true,
    },
    loading: 'Uw gegevens worden opgehaald',
    user: {},
    match: {
      match: null,
      updateMatch: this.updateMatch,
    },
    fatalError: '',
    app: {
      isLoading: false,
    },
    appError: {
      error: null,
      setAppError: this.handleAppError,
    },
  };

  get authenticated() {
    return !!localStorage.getItem(ID_TOKEN_KEY);
  }

  get profile() {
    return getIdToken();
  }

  componentDidMount() {
    (async () => {
      const fatalError = await this.appBootingCheck();

      if (fatalError !== null) {
        return;
      }

      // There's no fatal error, clear it from the LocalStorage & clear the retries so the user starts from 0 again
      // if something does break.
      clearSSORetries();
      clearErrorFromLocalStorage();

      Storage.addSetListener(ID_TOKEN_KEY, async (_idToken) => {
        if (this.state.match.match === null) {
          return;
        }

        try {
          this.updateMatch(this.state.match.match, null, 'JWT refresh');
        } catch (e) {
          await redirectToSso(false);
        }
      });

      this.setState({ user: getIdToken(), loading: '' });
    })();
  }

  verifyPKCEState = (state) => {
    const PKCEState = getPKCEState();

    if (state !== PKCEState) {
      clearPKCEData();

      saveErrorToLocalStorage(
        new Error(
          `State parameter komt niet overeen met die van het DWF. DWF: ${PKCEState}, SSO: ${state}`
        ),
        'authenticate',
        'verifyPKCEState'
      );

      return false;
    }

    return true;
  };

  verifyNonce = (idToken) => {
    const { nonce } = getIdToken(idToken);
    const PKCENonce = getPKCENonce();

    if (nonce !== PKCENonce) {
      clearPKCEData();

      saveErrorToLocalStorage(
        new Error(
          `IDToken nonce parameter komt niet overeen met die van het DWF. DWF: ${PKCENonce}, SSO: ${nonce}`
        ),
        'authenticate',
        'verifyNonce',
        {
          id_token: idToken,
        }
      );

      return false;
    }

    return true;
  };

  handleAuthenticate = async (code, state) => {
    if (!code || !state) {
      this.props.history.replace(getAuthRedirectUrl());

      return;
    }

    if (!this.verifyPKCEState(state)) {
      await redirectToSso(false);

      return false;
    }

    let data;

    try {
      data = await getJWTFromAuthenticationCode(code);
    } catch (e) {
      if (e.response?.status === BAD_REQUEST) {
        await redirectToSso(false);

        return;
      }

      throw e;
    }

    if (!data.id_token || !data.refresh_token) {
      notifyError(new Error('Inloggen mislukt: ' + JSON.stringify(data)));

      alert('Inloggen niet gelukt, probeer het later nog eens. (code 1)');
      logout('/');

      return;
    }

    const { id_token: idToken, refresh_token: refreshToken } = data;

    if (!this.verifyNonce(idToken)) {
      await redirectToSso(false);

      return false;
    }

    clearPKCEData();

    try {
      if (
        saveIdToken(idToken, refreshToken) &&
        savePerson((await getPersonByFederationReferenceId('app')).data)
      ) {
        const user = getIdToken();

        this.props.history.replace(getAuthRedirectUrl());
        this.setState({ user });

        return true;
      }
      notifyError(new Error('Inloggen mislukt: ' + JSON.stringify(data)));

      alert('Inloggen niet gelukt, probeer het later nog eens. (code 5)');
      logout('/');
    } catch (error) {
      notifyError(error);

      alert('Inloggen niet gelukt, probeer het later nog eens. (code 2)');

      return false;
    }
  };

  logout = () => {
    logout();
  };

  render() {
    if (!!this.state.fatalError) {
      return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <StyledApp>
            <FatalErrorScreen>{this.state.fatalError}</FatalErrorScreen>
          </StyledApp>
        </StyleSheetManager>
      );
    }

    if (!!this.state.appError.error) {
      const error = this.state.appError.error;

      if (error.response && error.response.status === 404) {
        return (
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <StyledApp>
              <NotFound
                back={() => {
                  this.clearAppError();
                  this.props.history.push('/matches');
                }}
                message={error.response.data.message}
              />
            </StyledApp>
          </StyleSheetManager>
        );
      }

      return (
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <StyledApp>
            <BadRequest />
          </StyledApp>
        </StyleSheetManager>
      );
    }

    if (this.state.loading) {
      return (
        <AppError.Provider value={this.state.appError}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <StyledApp>
              <ErrorContext.Provider value={this.state.error}>
                <>
                  {!!this.state.error.message && (
                    <ErrorPopover
                      showError={this.showError}
                      title={this.state.error.title}
                      showCloseButton={this.state.error.showCloseButton}
                    >
                      {this.state.error.message}
                    </ErrorPopover>
                  )}
                  <LoadingScreen>{this.state.loading}</LoadingScreen>
                </>
              </ErrorContext.Provider>
            </StyledApp>
          </StyleSheetManager>
        </AppError.Provider>
      );
    }

    return (
      <QueryClientProvider client={queryClient}>
        <AppContext.Provider
          value={{ setAppIsLoading: this.setAppLoading, appIsLoading: this.state.app.isLoading }}
        >
          <AppError.Provider value={this.state.appError}>
            <UserContext.Provider value={this.state.user}>
              <ErrorContext.Provider value={this.state.error}>
                <RolesContextProvider>
                  <MatchContext.Provider value={this.state.match}>
                    <RoleContextProvider>
                      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                        <StyledApp>
                          {!!this.state.error.message && (
                            <ErrorPopover
                              showError={this.showError}
                              title={this.state.error.title}
                              showCloseButton={this.state.error.showCloseButton}
                            >
                              {this.state.error.message}
                            </ErrorPopover>
                          )}
                          <RoleSwitch />
                          <Switch>
                            {/* 404 */}
                            <Route path="/404" exact>
                              <Page404 />
                            </Route>

                            {/* Callback */}
                            <Route path="/callback" exact>
                              <Callback handleAuthenticate={this.handleAuthenticate} />
                            </Route>

                            {/* Homepage */}
                            <Route path="/" exact>
                              <Home />
                            </Route>

                            {/* Match Overview Page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/matches" exact>
                              <Index />
                            </PrivateRoute>

                            {/* Match Detail Page */}
                            <PrivateRoute path="/matches/:id" loggedIn={this.authenticated}>
                              <Show />
                            </PrivateRoute>

                            {/* FAQ page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/faq" exact>
                              <Faq />
                            </PrivateRoute>

                            {/* FAQ Introduction page */}
                            <PrivateRoute
                              loggedIn={this.authenticated}
                              path="/faq/introduction"
                              exact
                            >
                              <FaqIntroduction />
                            </PrivateRoute>

                            {/* FAQ Referee page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/faq/referee" exact>
                              <FaqReferee />
                            </PrivateRoute>

                            {/* FAQ Coach page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/faq/coach" exact>
                              <FaqCoach />
                            </PrivateRoute>
                            <PrivateRoute loggedIn={this.authenticated} path="/faq/legend" exact>
                              <FaqLegend />
                            </PrivateRoute>

                            {/* Account page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/account" exact>
                              <Account userProfile={this.state.user} logout={this.logout} />
                            </PrivateRoute>

                            {/* Players page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/players" exact>
                              <Playerlist />
                            </PrivateRoute>

                            {/* Questions page */}
                            <PrivateRoute loggedIn={this.authenticated} path="/questions" exact>
                              <Questions />
                            </PrivateRoute>

                            {/* Login page */}
                            <Route path="/login" exact>
                              <Login />
                            </Route>

                            {/* Default route */}
                            <Route>
                              <NotFound />
                            </Route>
                          </Switch>
                        </StyledApp>
                      </StyleSheetManager>
                    </RoleContextProvider>
                  </MatchContext.Provider>
                </RolesContextProvider>
              </ErrorContext.Provider>
            </UserContext.Provider>
          </AppError.Provider>
        </AppContext.Provider>
      </QueryClientProvider>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.clearAppError();
    }
  }
}

// This implements the default behavior from styled-components v5
export const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }

  // For other elements, forward all props
  return true;
};

export default withRouter(App);
