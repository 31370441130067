import React from 'react';
import { styled } from 'styled-components';
import Cross from '../atoms/Icons/Cross';
import { theme } from '../templates/ui';

const StyledPopover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.85);
  border: 0;
  z-index: 99;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ErrorContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 300px;
  height: 200px;
  text-align: center;
  border-radius: 3px;
  border: solid 3px ${theme.color.primary.base};
  background-color: ${theme.color.primary.base};
  color: ${theme.color.gray.white};
  padding: ${theme.sizing.xlarge};

  @media only screen and (min-width: ${theme.breakpoints.small}) {
    min-width: 340px;
  }
`;

const Header = styled.header`
  position: relative;
  margin-right: auto;
  margin-bottom: auto;
  padding-bottom: ${theme.sizing.large};
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  min-height: 52px;
`;

const Title = styled.h1`
  margin: 0;
  padding-bottom: ${theme.sizing.small};
`;

const Content = styled.p`
  text-align: left;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: ${theme.sizing.small};
  right: 0;

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const StyledCross = styled(Cross)`
  fill: ${theme.color.body.base};
`;

const ErrorPopover = ({ title, children, showCloseButton, showError }) => {
  const onClick = () => {
    showError('', '');
  };

  return (
    <StyledPopover data-testid="error-popover">
      <Container>
        <ErrorContainer>
          <Header>
            <Title>{title}</Title>
            {showCloseButton !== false && (
              <CloseButton onClick={onClick}>
                <StyledCross />
              </CloseButton>
            )}
          </Header>
          <Content>{children}</Content>
        </ErrorContainer>
      </Container>
    </StyledPopover>
  );
};

export default ErrorPopover;
