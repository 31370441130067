import React from 'react';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../@types/Permissions';
import { withMatch } from '../../contexts/MatchContext';
import { withUser } from '../../contexts/UserContext';
import { AuthorizedCan } from '../atoms/AuthorizedCan';
import { Input } from '../atoms/Input';
import { Wrapper } from '../atoms/Wrapper';
import { theme } from '../templates/ui';

const StyledContainer = styled.div`
  padding: ${theme.sizing.xlarge} 0;
  background-color: ${theme.color.gray.base};
  border-top: 1px solid ${theme.color.gray.alt};
  border-bottom: 1px solid ${theme.color.gray.alt};
`;

const StyledLabel = styled.label`
  color: ${theme.color.base.base};
  font-size: ${theme.font.large};
  flex: 1 0 auto;
`;

const StyledWrapper = styled(Wrapper)`
  position: relative;
`;

const StyledSearchTopBar = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.sizing.large};
  max-width: 100%;
  margin-bottom: ${theme.sizing.large};
`;

const StyledTeamSelection = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  min-width: 0;
  justify-content: flex-end;
`;

const StyledTeamOption = styled.div`
  display: flex;
  overflow: hidden;

  &:first-child {
    label {
      border-radius: 3px 0 0 3px;
      border-width: 1px 0 1px 1px;
    }
    input:not(:checked) {
      + label {
        border-right-color: transparent;
      }
    }
  }
  &:last-child {
    label {
      border-radius: 0 3px 3px 0;
    }
    input:not(:checked) {
      + label {
        border-left-color: transparent;
      }
    }
  }
`;

const StyledTeamInput = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  &:checked {
    + label {
      background-color: ${theme.color.primary.base};
      color: ${theme.color.gray.white};
      border-color: ${theme.color.primary.base};
    }
  }
`;

const StyledTeamLabel = styled.label`
  transition: all 0.2s ease-in-out;
  padding: ${theme.sizing.small} ${theme.sizing.medium} 6px ${theme.sizing.medium};
  border: 1px solid #d8d8d8;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const Search = ({
  searchClub,
  label,
  icon,
  placeholder,
  onChange,
  className,
  matchGame,
  onClubChange,
  value,
}) => {
  return (
    <>
      <StyledContainer>
        <StyledWrapper>
          <form>
            {/* Team selection  */}

            <StyledSearchTopBar>
              {/* Search selection  */}
              <StyledLabel htmlFor="person-search">{label}</StyledLabel>
              <AuthorizedCan
                has={[PERMISSIONS.MANAGE_ALL_TEAMS, PERMISSIONS.MANAGE_ALL_TEAMS_SUPPORT]}
                policy="some"
              >
                <StyledTeamSelection>
                  <StyledTeamOption>
                    <StyledTeamInput
                      checked={searchClub.id === matchGame.home_team.id}
                      type="radio"
                      name="team-selection"
                      id="home_team"
                      data-testid="home-team"
                      value={matchGame.home_team.id}
                      onChange={() => onClubChange(matchGame.home_team.id)}
                    />
                    <StyledTeamLabel htmlFor="home_team">
                      {matchGame.home_team.name}
                    </StyledTeamLabel>
                  </StyledTeamOption>
                  <StyledTeamOption>
                    <StyledTeamInput
                      checked={searchClub.id === matchGame.away_team.id}
                      type="radio"
                      name="team-selection"
                      id="away_team"
                      data-testid="away-team"
                      value={matchGame.away_team.id}
                      onChange={() => onClubChange(matchGame.away_team.id)}
                    />
                    <StyledTeamLabel htmlFor="away_team">
                      {matchGame.away_team.name}
                    </StyledTeamLabel>
                  </StyledTeamOption>
                </StyledTeamSelection>
              </AuthorizedCan>
            </StyledSearchTopBar>
            <Input
              data-testid="person-search"
              id="person-search"
              placeholder={placeholder}
              icon={icon}
              onChange={onChange}
              className={className}
              value={value}
            />
          </form>
        </StyledWrapper>
      </StyledContainer>
    </>
  );
};

export default withMatch(withUser(Search));
