const AUTH_REDIRECT_URL_KEY = 'auth_redirect_url';
export const fallbackRedirectUrl = '/matches';

export function getObject(keyName, defaultValue) {
  const item = localStorage.getItem(keyName);

  if (item) {
    return JSON.parse(item);
  }

  return defaultValue || {};
}

export function insertObject(keyName, value) {
  localStorage.setItem(keyName, JSON.stringify(value));
}

export function upsertObject(keyName, value) {
  insertObject(keyName, { ...getObject(keyName), ...value });
}

export function setAuthRedirectUrl(url) {
  window.sessionStorage.setItem(
    AUTH_REDIRECT_URL_KEY,
    url || window.location.pathname + window.location.search
  );
}

export function getAuthRedirectUrl() {
  const authRedirectUrl = window.sessionStorage.getItem(AUTH_REDIRECT_URL_KEY);

  if (authRedirectUrl === null || authRedirectUrl.indexOf('/callback') === 0) {
    return fallbackRedirectUrl;
  }

  return authRedirectUrl;
}

export function clearRedirectUrl() {
  window.sessionStorage.removeItem(AUTH_REDIRECT_URL_KEY);
}
