import React, { useContext, useEffect } from 'react';
import { styled } from 'styled-components';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { RolesContext } from '../../../contexts/RolesContext/RolesContext';
import { isClosed, isExpired } from '../../../services/match';
import ChevronDown from '../../atoms/Icons/ChevronDown';
import { Paragraph } from '../../atoms/Paragraph';
import { Wrapper } from '../../atoms/Wrapper';
import TopBar from '../../molecules/TopBar';
import VerifyCode from '../../molecules/VerifyCode';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle,
} from '../../organisms/Accordion';
import Match from '../../organisms/Match';
import { theme } from '../../templates/ui';
import { LoadingScreen } from '../LoadingScreen';

const StyledAccordion = styled(Accordion)``;

const StyledAccordionItem = styled(AccordionItem)`
  margin-bottom: ${theme.sizing.xlarge};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

const StyledAccordionTitle = styled(AccordionItemTitle)`
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${theme.color.gray.base};
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};
  fill: ${theme.color.gray.darkest};
  padding: ${theme.sizing.xlarge};
  cursor: pointer;

  h3 {
    margin: 0;
    font-size: ${theme.font.large};

    @media only screen and (min-width: ${theme.breakpoints.small}) {
      font-size: ${theme.font.large};
    }
  }

  &[aria-expanded='true'] {
    .chevron-down {
      transform: rotateX(180deg);
    }
  }

  &.active {
    background: ${theme.color.secondary.base};
    border-bottom: 3px solid ${theme.color.secondary.darker};
    color: ${theme.color.body.base};
    fill: ${theme.color.body.base};
  }

  &:focus {
    outline: none;
  }

  .chevron-down {
    position: absolute;
    right: ${theme.sizing.xlarge};
    top: 50%;
    transform-origin: 0 3px;
    transform: translateY(-50%);
    width: 17px;
    height: 10px;
    transition: transform 0.25s ease;
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: ${theme.sizing.xlarge} 0;
  font-size: ${theme.font.base};
  line-height: 1.4rem;
  color: ${theme.color.gray.darkest};
`;

const TitleWrapper = styled(Wrapper)`
  width: 100%;
  padding-left: 0;

  @media only screen and (min-width: ${theme.breakpoints.large}) {
    padding-left: ${theme.sizing.large};
  }
`;

const hasMatches = (items) => items.edges && !!items.edges.length;

const getMatches = (
  edges,
  noResultMessage,
  setMatchUuid,
  setRoleSwitchModalActive,
  roles,
  matchUuid
) => {
  if (edges) {
    return edges.length > 0 ? (
      edges.map((edge) => (
        <Match
          key={edge.node.id}
          matchId={edge.node.id}
          homeTeam={edge.node.home_team}
          awayTeam={edge.node.away_team}
          date={edge.node.date}
          matchCode={edge.node.match_code}
          homeScore={edge.node.home_score}
          awayScore={edge.node.away_score}
          homeShootoutScore={edge.node.home_shootout_score}
          awayShootoutScore={edge.node.away_shootout_score}
          commentsCount={edge.node.match_comments.length}
          cancellation={edge.node.match_cancellations}
          approvals={edge.node.match_approvals}
          homeCoachApproved={edge.node.home_team_approval}
          awayCoachApproved={edge.node.away_team_approval}
          isClosed={isClosed(edge.node)}
          onClick={() => {
            if (isExpired(edge.node)) {
              return;
            }

            setMatchUuid(edge.node.uuid);

            if (matchUuid === edge.node.uuid && roles && Object.keys(roles).length > 1) {
              setRoleSwitchModalActive(true);
            }
          }}
          matchUuid={edge.node.uuid}
          matchGame={edge.node}
        />
      ))
    ) : (
      <StyledParagraph>
        {noResultMessage ? noResultMessage : <>Er zijn geen wedstrijden gevonden.</>}
      </StyledParagraph>
    );
  }

  return (
    <LoadingScreen title="Wedstrijden laden">
      Blijft dit scherm langer dan 5 seconden laden?
      <br />
      Probeer het DWF dan te benaderen via uw webbrowser.
      <br />
      Ga naar https://dwf.hockeyweerelt.nl/ en log in met de bij u bekende gegevens.
    </LoadingScreen>
  );
};

export const Index = ({ currentMatches, upcomingMatches, historyMatches }) => {
  const { setMatchUuid, matchUuid, setRoleSwitchModalActive, roles } = useContext(RolesContext);
  const { setRole } = useContext(RoleContext);

  // Reset matchId and role when entering page. This will trigger the permissions to reset.
  useEffect(() => {
    setRole(null);
    setMatchUuid(null);
  }, []);

  return (
    <>
      <TopBar>Wedstrijden</TopBar>
      <VerifyCode />
      <StyledAccordion accordion={false}>
        <StyledAccordionItem
          expanded={
            !hasMatches(currentMatches) &&
            !hasMatches(upcomingMatches) &&
            hasMatches(historyMatches)
          }
        >
          <StyledAccordionTitle data-testid="last-10-matches-toggle">
            <TitleWrapper>
              <h3>Laatste 10 gespeelde wedstrijden</h3>
            </TitleWrapper>
            <ChevronDown className="chevron-down" />
          </StyledAccordionTitle>
          <AccordionItemBody>
            <Wrapper data-testid="history-matches">
              {getMatches(
                historyMatches.edges,
                undefined,
                setMatchUuid,
                setRoleSwitchModalActive,
                roles,
                matchUuid
              )}
            </Wrapper>
          </AccordionItemBody>
        </StyledAccordionItem>
        <StyledAccordionItem expanded={hasMatches(currentMatches)}>
          <StyledAccordionTitle className="active" data-testid="todays-matches-toggle">
            <TitleWrapper>
              <h3>Wedstrijden van vandaag</h3>
              <ChevronDown className="chevron-down" />
            </TitleWrapper>
          </StyledAccordionTitle>
          <AccordionItemBody>
            <Wrapper data-testid="current-matches">
              {getMatches(currentMatches.edges, undefined, setMatchUuid)}
            </Wrapper>
          </AccordionItemBody>
        </StyledAccordionItem>
        <StyledAccordionItem expanded={!hasMatches(currentMatches) && hasMatches(upcomingMatches)}>
          <StyledAccordionTitle data-testid="futre-matches-toggle">
            <TitleWrapper>
              <h3>Toekomstige wedstrijden</h3>
            </TitleWrapper>
            <ChevronDown className="chevron-down" />
          </StyledAccordionTitle>
          <AccordionItemBody>
            <Wrapper data-testid="future-matches">
              {getMatches(
                upcomingMatches.edges,
                'Er zijn geen wedstrijden gevonden (max. 4 dagen vooruit).',
                setMatchUuid
              )}
            </Wrapper>
          </AccordionItemBody>
        </StyledAccordionItem>
      </StyledAccordion>
    </>
  );
};
