import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import TopBar from '../../molecules/TopBar';
import { Layout } from '../../templates/Layout';
import { theme } from '../../templates/ui';

const StyledHeader = styled.h1`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.xxxxlarge};
`;

const StyledTitle = styled.h1`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.xxxlarge};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.sizing.xlarge};
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.primary.base};
`;

export const BadRequest = () => (
  <Layout>
    <TopBar>Er is iets fout gegaan</TopBar>
    <Wrapper>
      <div>
        <StyledHeader>Er is iets fout gegaan</StyledHeader>
        <StyledTitle>
          We konden de gegevens voor deze wedstrijd niet ophalen. Probeer het later nog eens.
        </StyledTitle>
        <p>
          Ga terug naar <StyledLink to="/matches">de homepagina</StyledLink>
        </p>
      </div>
    </Wrapper>
  </Layout>
);
