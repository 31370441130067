import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { styled } from 'styled-components';
import LoginCard from '../../organisms/LoginCard';
import { theme } from '../../templates/ui';

const LoginApp = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.color.primary.base};
`;

export const Home = ({ isLoggedIn }) => {
  const history = useHistory();

  useEffect(() => {
    document.body.style.backgroundColor = theme.color.primary.base;

    return function () {
      document.body.style.backgroundColor = theme.color.gray.white;
    };
  }, []);

  const handleLogin = () => {
    history.push('/matches');
  };

  if (isLoggedIn) {
    return <Redirect to={'/matches'} />;
  }

  return (
    <LoginApp>
      <LoginCard handleLogin={handleLogin} />
    </LoginApp>
  );
};
