import { useContext, useMemo } from 'react';
import { PERMISSIONS } from '../@types/Permissions';
import { RoleContext, hasPermissionsCheck } from '../contexts/RoleContext/RoleContext';
import { RolesContext } from '../contexts/RolesContext/RolesContext';
import { canOnlyConfirmAsCoach, canOnlyConfirmAsReferee } from '../services/permissions';

/**
 * useHasRole
 * uses the current user to check if he has a certain role
 * @example const { isSecretary } = useHasRole();
 * @returns {Object} various booleans to check if the user has a certain role
 */

export const useHasRole = () => {
  const { roles } = useContext(RolesContext);
  const { role } = useContext(RoleContext);

  const hasSingularCoachRole = useMemo(() => {
    return roles?.some((r) => {
      return canOnlyConfirmAsCoach(r);
    });
  }, [roles]);

  const hasSingularRefereeRole = useMemo(() => {
    return roles?.some((r) => {
      return canOnlyConfirmAsReferee(r);
    });
  }, [roles]);

  /*
   * Checks if a user is a coach or a superuser.
   */

  const isCoach = useMemo(() => {
    if (!role) {
      return false;
    }

    const permissions = hasPermissionsCheck(
      role,
      [PERMISSIONS.APPROVE_AS_COACH, PERMISSIONS.APPROVE_AS_SUPERUSER],
      { policy: 'some', teamId: role.team_id ?? undefined }
    );

    return permissions;
  }, [role]);

  /*
   * If the user has the approve as coach or approve as referee permission
   * and does not have the approve as superuser permission, the user is a secretary.
   */

  const isSecretary = useMemo(() => {
    if (!role) {
      return false;
    }

    const hasCoachAndRefereePermissions = hasPermissionsCheck(
      role,
      [PERMISSIONS.APPROVE_AS_COACH, PERMISSIONS.APPROVE_AS_UMPIRE, PERMISSIONS.CANCEL_MATCH],
      { teamId: role.team_id ?? undefined }
    );

    const hasSuperuserPermissions = hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_SUPERUSER]);

    return hasCoachAndRefereePermissions && !hasSuperuserPermissions;
  }, [role]);

  /*
   * Checks if a user is a referee or a superuser.
   */

  const isReferee = useMemo(() => {
    if (!role) {
      return false;
    }

    return hasPermissionsCheck(
      role,
      [PERMISSIONS.APPROVE_AS_UMPIRE, PERMISSIONS.APPROVE_AS_SUPERUSER],
      { policy: 'some' }
    );
  }, [role]);

  /*
   * Checks if a user is a superuser.
   */

  const isSuperUser = useMemo(() => {
    if (!role) {
      return false;
    }

    return hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_SUPERUSER]);
  }, [role]);

  return {
    hasSingularCoachRole,
    hasSingularRefereeRole,
    isCoach,
    isSecretary,
    isReferee,
    isSuperUser,
  };
};
