import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const RadioWrapper = styled.div`
  position: relative;

  input[type='radio'] {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  input[type='radio'] + label {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: ${theme.sizing.large};
    margin-right: ${theme.sizing.large};
    border: 2px solid ${theme.color.gray.light};
    border-radius: 3px;
    color: ${theme.color.base.base};
    transition: 50ms all ease-in-out;
    font-size: ${theme.font.large};

    &:hover {
      cursor: pointer;
    }

    &::before {
      transition: all 50ms ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -3px;
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }
    &::after {
      transition: opacity 50ms ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: ${theme.sizing.xsmall};
      z-index: 2;
    }
  }

  input[type='radio']:checked + label {
    background-color: ${theme.color.primary.base};
    border: 2px solid ${theme.color.primary.base};
    color: ${theme.color.body.base};

    &::before {
      transition: all 50ms ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }

    &::after {
      opacity: 1;
    }

    svg {
      fill: ${theme.color.body.base};
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: ${theme.sizing.large};
`;

export const Radio = ({
  className,
  radioId,
  icon,
  name,
  children,
  value,
  onChange,
  checked,
  required,
}) => (
  <RadioWrapper className={className}>
    <input
      type="radio"
      id={`radio-${radioId}`}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      data-testid={`radio-${value}`}
      required={required}
    />
    <label htmlFor={`radio-${radioId}`} data-testid={`label-${value}`}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </label>
  </RadioWrapper>
);
