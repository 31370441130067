import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledContainer = styled.div`
  display: flex;
  margin-bottom: ${theme.sizing.xlarge};
`;

export const InputContainer = ({ className, children }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);
