import React, { useContext } from 'react';
import { PermissionPolicyType, PermissionType } from '../../../@types/Permissions';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';

type Props = {
  // Array of permissions to check
  has?: null | PermissionType[];
  policy?: PermissionPolicyType;
  teamId?: number;
  // Boolean for additional condition to be used WITH the permissions check
  condition?: boolean;
  // Ignore has/condition, override to true/false
  override?: boolean;
  children: React.ReactNode;
};

export const AuthorizedCan = ({
  has: permissions = undefined,
  condition = undefined,
  override = undefined,
  teamId = undefined,
  policy = 'all',
  children,
}: Props) => {
  const { hasPermissions: hasPermissionsCheck } = useContext(RoleContext);

  // Which options are set
  const hasConditionsOption = condition !== undefined;
  const hasPermissionsOption = permissions !== undefined;
  const hasAccessOption = override !== undefined;

  // Are there any conditions set and are they met?
  const hasConditionsMet = (hasConditionsOption && condition === true) || !hasConditionsOption;

  // Are the permissions met?
  const hasPermissionsMet =
    hasPermissionsOption && permissions && hasPermissionsCheck(permissions, { policy, teamId });

  // Allow access if an access override is set to true
  const hasAccessOverrideMet = hasAccessOption !== undefined && override === true;

  const allowed = (hasPermissionsMet && hasConditionsMet) || hasAccessOverrideMet;

  if (!allowed) {
    return null;
  }

  return <>{children}</>;
};
