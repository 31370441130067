export const collapse = (element) => {
  const scrollHeight = element.scrollHeight;
  const expandedMaxHeight = `${scrollHeight}px`;
  const collapsedMaxHeight = '0px';

  element.style.maxHeight = expandedMaxHeight;

  requestAnimationFrame(() => {
    element.style.maxHeight = collapsedMaxHeight;
  });
};

export const expand = (element) => {
  const scrollHeight = element.scrollHeight;
  const expandedMaxHeight = `${scrollHeight}px`;

  const transitionEndCallback = ({ target }) => {
    target.removeEventListener('transitionend', transitionEndCallback);
    target.style.maxHeight = null;
  };

  element.addEventListener('transitionend', transitionEndCallback);

  element.style.maxHeight = expandedMaxHeight;
};
