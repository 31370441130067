import React, { createContext } from 'react';

const UserContext = createContext({ user: {} });

export const withUser = (Component) => {
  function withUser(props) {
    return (
      <UserContext.Consumer>{(user) => <Component user={user} {...props} />}</UserContext.Consumer>
    );
  }

  withUser.displayName = `withUser(${Component.displayName})`;

  return withUser;
};

export default UserContext;
