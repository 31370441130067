import { dwf_api_endpoint } from '../config/api';
import {
  AwayCoachType,
  AwayYouthCoachType,
  HomeCoachType,
  HomeYouthCoachType,
  RefereeType,
  SuperuserType,
} from '../services/matchConfirmation';
import Client from './Client';

export const index = (pageKey) =>
  Client.get(`${dwf_api_endpoint}/matches`, null, pageKey, 'matches.index');

export const show = (id, ws, role, pageKey) => {
  const qs = [];
  if (role) {
    qs.push(`as_person_role_id=${role.role_id}`);
  }

  if (ws) {
    qs.push(`ws=1`);
  }

  return Client.get(
    `${dwf_api_endpoint}/matches/${id}?${qs.join('&')}`,
    null,
    pageKey,
    'matches.show'
  );
};

export const getMatchDetailsForOfficials = (matchCode, pageKey) => {
  return Client.get(
    `${dwf_api_endpoint}/matches/code/${matchCode}`,
    null,
    pageKey,
    'matches.getMatchDetailsForOfficials'
  );
};

export const updateScore = (
  matchUuid,
  homeScore,
  awayScore,
  homeShootoutScore,
  awayShootoutScore,
  role,
  pageKey
) =>
  Client.put(
    `${dwf_api_endpoint}/matches/${matchUuid}/score`,
    {
      home_score: parseInt(homeScore),
      away_score: parseInt(awayScore),
      home_shootout_score: parseInt(homeShootoutScore),
      away_shootout_score: parseInt(awayShootoutScore),
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.updateScore'
  );

export const assignPlayerToMatch = (matchUuid, teamId, clubMemberId, jerseyNumber, role, pageKey) =>
  Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/players`,
    {
      team_id: teamId,
      club_member_id: clubMemberId,
      jersey_number: jerseyNumber,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.assignPlayerToMatch'
  );

export const unassignPlayerFromMatch = (matchUuid, matchPlayerId, teamId, role, pageKey) =>
  Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/players/${matchPlayerId}`,
    {
      team_id: teamId,
      as_person_role_id: role.role_id,
    },
    pageKey,
    'matches.unassignPlayerFromMatch'
  );

export const searchPlayers = (clubId, queryString, matchUuid, teamId, role, pageKey) =>
  Client.get(
    `${dwf_api_endpoint}/matches/${matchUuid}/substitutes/search?query=${queryString}&club_id=${clubId}&team_id=${teamId}&as_person_role_id=${role.role_id}`,
    null,
    pageKey,
    'matches.searchPlayers'
  );

export const searchPersons = (matchUuid, clubId, queryString, role, pageKey) =>
  Client.get(
    `${dwf_api_endpoint}/matches/${matchUuid}/teamsupport/persons/search?query=${queryString}&club_id=${clubId}&as_person_role_id=${role.role_id}`,
    null,
    pageKey,
    'matches.searchPersons'
  );

export const getRecentSubstitutesForTeam = (matchUuid, teamId, role, pageKey) => {
  return Client.get(
    `${dwf_api_endpoint}/matches/${matchUuid}/substitutes/recent/${teamId}?as_person_role_id=${role.role_id}`,
    null,
    pageKey,
    'substitutes.getRecentSubstitutesForTeam'
  );
};

export const addMatchPlayer = (
  matchUuid,
  clubMemberId,
  teamId,
  isSubstitute,
  jerseyNumber,
  role,
  pageKey
) =>
  Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/players`,
    {
      club_member_id: clubMemberId,
      team_id: teamId,
      jersey_number: jerseyNumber,
      is_substitute: isSubstitute,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.addMatchPlayer'
  );

export const addMatchTeamSupport = (matchUuid, clubMemberId, teamId, role, pageKey) =>
  Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/teamsupport`,
    {
      club_member_id: clubMemberId,
      team_id: teamId,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.addMatchTeamSupport'
  );

export const removeMatchTeamSupport = (matchUuid, clubMemberId, teamId, role, pageKey) =>
  Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/teamsupport`,
    {
      club_member_id: clubMemberId,
      team_id: teamId,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.removeMatchTeamSupport'
  );

export const assignRefereeToMatch = (matchCode, federationReferenceId, pageKey) =>
  Client.post(
    `${dwf_api_endpoint}/matches/referee`,
    {
      match_code: matchCode,
      federation_reference_id: federationReferenceId,
    },
    null,
    pageKey,
    'matches.assignRefereeToMatch'
  );

export const updatePlayerJerseyNumber = (
  matchUuid,
  playerId,
  jerseyNumber,
  role,
  teamId,
  pageKey
) =>
  Client.patch(
    `${dwf_api_endpoint}/matches/${matchUuid}/players/${playerId}/jerseynumber`,
    {
      jersey_number: jerseyNumber,
      as_person_role_id: role.role_id,
      team_id: teamId,
    },
    null,
    pageKey,
    'matches.updatePlayerJerseyNumber'
  );

export const getMatchConfirmationEndpoint = (personType) => {
  switch (personType) {
    case RefereeType:
      return confirmMatchReferee;
    case HomeYouthCoachType:
    case AwayYouthCoachType:
    case AwayCoachType:
    case HomeCoachType:
      return confirmMatchCoach;
    case SuperuserType:
      return confirmMatchSuperUser;
  }
};

export const confirmMatchReferee = ({ matchUuid, role, pageKey }) =>
  Client.put(
    `${dwf_api_endpoint}/matches/${matchUuid}/confirm/referee`,
    {
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.confirmMatchReferee'
  );

export const confirmMatchCoach = ({ matchUuid, role, teamId, pageKey }) =>
  Client.put(
    `${dwf_api_endpoint}/matches/${matchUuid}/confirm/coach`,
    {
      as_person_role_id: role.role_id,
      team_id: teamId,
    },
    null,
    pageKey,
    'matches.confirmMatchCoach'
  );

export const confirmMatchSuperUser = ({ matchUuid, role, pageKey }) =>
  Client.put(
    `${dwf_api_endpoint}/matches/${matchUuid}/confirm/superuser`,
    {
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'matches.confirmMatchSuperUser'
  );

export const deleteMatchConfirmation = ({ matchUuid, role, pageKey }) =>
  Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/confirm`,
    {
      as_person_role_id: role.role_id,
    },
    undefined,
    pageKey,
    'matches.deleteMatchConfirmation'
  );

export const cancelMatch = (
  matchUuid,
  reason,
  comment,
  date,
  minute,
  homeScore,
  awayScore,
  role,
  cancellationType,
  pageKey
) =>
  Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/cancel`,
    {
      reason: reason,
      comment: comment,
      date: date,
      minute: minute,
      score: `${homeScore || 0}-${awayScore || 0}`,
      match_uuid: matchUuid,
      as_person_role_id: role.role_id,
      type: cancellationType,
    },
    null,
    pageKey,
    'matches.cancelMatch'
  );

export const unCancelMatch = (matchUuid, role, pageKey) =>
  Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/cancel`,
    {
      as_person_role_id: role.role_id,
    },
    pageKey,
    'matches.unCancelMatch'
  );

export const getMatchInfo = (matchUuid, pageKey) =>
  Client.get(`${dwf_api_endpoint}/matches/${matchUuid}/info`, null, pageKey, 'matches.info');

export const getMatchExcerpt = (matchUuid, pageKey) =>
  Client.get(`${dwf_api_endpoint}/matches/${matchUuid}/excerpt`, null, pageKey, 'matches.excerpt');
