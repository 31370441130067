import React from 'react';
import { styled } from 'styled-components';
import { Button } from '../atoms/Button';
import { LoadingIcon } from '../atoms/LoadingIcon';
import { theme } from '../templates/ui';

const StyledIconButton = styled(Button)`
  width: 100%;

  span {
    width: auto;
    height: auto;
    z-index: auto;
    font-size: unset;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.75rem;
    width: 21px;
    height: 21px;
    z-index: 1;
    font-size: ${theme.font.xxxxlarge};
  }

  &:disabled {
    background-color: grey;
  }
`;

const Content = styled.span`
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
`;

const IconButton = ({ className, small, children, isLoading, ...rest }) => (
  <StyledIconButton className={className} small={small} {...rest}>
    <LoadingIcon isLoading={isLoading ? 1 : 0} />
    <Content isLoading={isLoading ? 1 : 0}>{children}</Content>
  </StyledIconButton>
);

export default IconButton;
