import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledHeader = styled.a`
  display: flex;
  align-items: center;
  background-color: ${theme.color.secondary.base};
  color: ${theme.color.body.base};
  padding: ${theme.sizing.xlarge} ${theme.sizing.large};
  border-radius: 3px;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
`;

export const PanelHeader = ({ onClick, icon, title }) => (
  <StyledHeader onClick={onClick}>
    {icon}
    <Heading>{title}</Heading>
  </StyledHeader>
);
