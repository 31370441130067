export const theme = {
  color: {
    primary: {
      darkest: '',
      darker: '',
      hover: '#DE700B',
      base: '#EF790C',
      light: '',
      lighter: '',
      lightest: '',
    },
    secondary: {
      darkest: '',
      darker: '#077341',
      hover: '#1B925C',
      base: '#1E9E63',
      light: '',
      lighter: '',
      lightest: '',
    },
    gray: {
      black: '#000000',
      alt: '#D8D8D8',
      darkest: '#4a4a4a',
      darker: '#ADADAD',
      dark: '#F4F4F4',
      base: '#F9F9F9',
      light: '#E8E8E8',
      lighter: '#F1F5F8',
      lightest: '#F8FAFC',
      white: '#FFFFFF',
    },
    body: { base: '#FFFFFF' },
    base: { base: '#2A2A2A' },
    paragraph: { paragraph: '#4E4E4E' },
    warning: { base: '#f1c40f' },
    error: { base: '#CC0000' },
    cards: {
      green: '#1E9E63',
      red: '#e74c3c',
      yellow: '#F1C40F',
    },
  },
  font: {
    family: 'Source Sans Pro, sans-serif',
    base: '16px',
    xsmall: '11px',
    small: '12px',
    medium: '14px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '24px',
    xxxlarge: ' 28px',
    xxxxlarge: ' 32px',
  },
  sizing: {
    xsmall: '2px',
    small: '4px',
    medium: '8px',
    large: '12px',
    xlarge: '16px',
    xxlarge: '20px',
    xxxlarge: '24px',
    xxxxlarge: '32px',
  },
  breakpoints: {
    xsmall: '320px',
    small: '370px',
    medium: '576px',
    large: '768px',
    xlarge: '992px',
  },
};
