import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import { FormItem } from '../FormItem/FormItem';
import ChevronDown from '../Icons/ChevronDown';

const StyledSelect = styled.select`
  width: 100%;
  padding: ${(props) =>
    props.noIcon
      ? `${theme.sizing.xlarge}`
      : `${theme.sizing.xlarge} ${theme.sizing.large} ${theme.sizing.xxlarge} 40px`};
  background-color: ${theme.color.body.base};
  border: 1px solid ${theme.color.gray.light};
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: middle;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const StyledChevron = styled(ChevronDown)`
  fill: ${theme.color.base.base};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.sizing.xlarge};
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${theme.sizing.large};

  svg {
    vertical-align: middle;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
`;

export const Option = styled.option`
  color: ${theme.color.base.base};
`;

export const Select = ({
  icon,
  children,
  onChange,
  name,
  value,
  disabled,
  className,
  noIcon,
  ...props
}) => (
  <FormItem className={className}>
    <SelectWrapper>
      <IconWrapper>{icon}</IconWrapper>
      <StyledSelect
        onChange={onChange}
        name={name}
        value={value}
        disabled={disabled}
        noIcon={noIcon}
        {...props}
      >
        {children}
      </StyledSelect>
      <StyledChevron />
    </SelectWrapper>
  </FormItem>
);
