import { SSOConfiguration as SSOConfigurationInterface } from '../@types/Sso';
import { SSO_CONFIGURATION_KEY, refreshSSOConfiguration } from '../api/sso';

export const getSSOConfiguration = async (): Promise<SSOConfigurationInterface> => {
  let SSOConfiguration = window.sessionStorage.getItem(SSO_CONFIGURATION_KEY);

  if (!SSOConfiguration) {
    await refreshSSOConfiguration();
  }

  SSOConfiguration = window.sessionStorage.getItem(SSO_CONFIGURATION_KEY) as string; // This will always be initialized before we get here.

  return JSON.parse(SSOConfiguration);
};

export const hasSSOConfiguration = (): boolean => {
  return Object.prototype.hasOwnProperty.call(window.sessionStorage, SSO_CONFIGURATION_KEY);
};

const SSO_RETRIES_KEY = 'SSO_RETRIES';

export const incrementSSORetries = (): void =>
  window.localStorage.setItem(SSO_RETRIES_KEY, (getSSORetries() + 1).toString());

export const getSSORetries = (): number => {
  const retries = window.localStorage.getItem(SSO_RETRIES_KEY);

  return retries === null ? 0 : parseInt(retries);
};

export const SSORetriesToHigh = (): boolean => {
  return getSSORetries() > 2;
};

export const clearSSORetries = (): void => window.localStorage.removeItem(SSO_RETRIES_KEY);
