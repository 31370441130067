import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledParagraph = styled.p`
  color: ${theme.color.base.base};
  font-size: ${theme.font.large};
  margin-top: ${theme.sizing.xlarge};
`;

export const Paragraph = ({ className, children }) => (
  <StyledParagraph className={className}>{children}</StyledParagraph>
);
