import React from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { css, styled } from 'styled-components';
import { withErrorHandler } from '../../contexts/ErrorContext';
import { withMatch } from '../../contexts/MatchContext';
import { useHasPermission } from '../../hooks/useHasPermission';
import { AuthorizedCan } from '../atoms/AuthorizedCan';
import Cross from '../atoms/Icons/Cross';
import Plus from '../atoms/Icons/Plus';
import { Wrapper } from '../atoms/Wrapper';
import { theme } from '../templates/ui';
import CommentsForm from './CommentsForm';
import CommentsList from './CommentsList';

const StyledContainer = styled.div`
  position: fixed;
  overflow: hidden;
  top: 56px;
  left: 100%;
  transform: translateX(${(props) => (props.containerOpen ? '-100%' : '0')});
  width: 100%;
  background-color: ${theme.color.gray.white};
  z-index: 5;
  transition: all 0.2s ease-in-out;

  ${(props) =>
    !props.containerOpen &&
    css`
      visibility: hidden;
    `};
`;

const StyledTopBar = styled.div`
  position: relative;
  background-color: ${theme.color.gray.light};
`;

const StyledCloseButton = styled.button`
  background-color: ${theme.color.primary.base};
  display: inline-block;
  border: 0;
  vertical-align: middle;
  padding: ${theme.sizing.xlarge};
  outline: none;
  cursor: pointer;

  svg {
    fill: ${theme.color.body.base};
    height: 18px;
    width: 18px;
  }

  &:focus,
  &:hover {
    background-color: ${theme.color.primary.hover};
  }
`;

const StyledWrapper = styled.div`
  display: inline-block;
  padding: 0 ${theme.sizing.large};
`;

const StyledTitle = styled.h3`
  color: ${theme.color.base.base};
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: ${theme.font.large};
`;

const StyledAddComment = styled.button`
  position: absolute;
  background-color: ${theme.color.gray.darker};
  border-radius: 50%;
  border: 0;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  right: ${theme.sizing.large};
  top: 50%;
  transform: translateY(-50%);
  vertical-align: middle;
  text-align: center;
  outline: none;
  cursor: pointer;
  padding: 0;

  svg {
    fill: ${theme.color.gray.white};
    margin-top: ${theme.sizing.small};
    height: 17px;
    width: 17px;
  }

  &:focus,
  &:hover {
    background-color: ${theme.color.gray.darkest};
  }
`;

const StyledScrollContainer = styled.div`
  height: calc(100vh - 105px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const StyledPlus = styled(Plus)`
  fill: ${theme.color.body.base};
`;

const Comments = (props) => {
  const { canAddComments } = useHasPermission();

  // Get current path
  let matchCommentsRoute = useRouteMatch('/matches/:matchId/comments');

  // Check if we're on the new comments page
  const containerOpen = useRouteMatch(`${matchCommentsRoute?.path}/new`) !== null;

  // Check if we're in edit mode
  const editId = props.location.state?.editCommentId || null;

  return (
    <StyledContainer containerOpen={props.containerOpen}>
      <StyledTopBar>
        <StyledCloseButton onClick={() => props.containerClose()} data-testid="commentsClose">
          <Cross />
        </StyledCloseButton>
        <StyledWrapper>
          <StyledTitle>Opmerkingen</StyledTitle>
        </StyledWrapper>

        {/* Add comment button */}
        <AuthorizedCan override={canAddComments}>
          <StyledAddComment
            onClick={() => {
              props.history.push(`${matchCommentsRoute.url}/new`);
            }}
            data-testid="add-new-comment-button"
          >
            <StyledPlus />
          </StyledAddComment>
        </AuthorizedCan>
      </StyledTopBar>
      <StyledScrollContainer>
        <Wrapper>
          <CommentsList {...props} />
        </Wrapper>
      </StyledScrollContainer>
      <AuthorizedCan override={canAddComments}>
        <CommentsForm
          {...props}
          match={props.match}
          matchGame={props.matchGame}
          containerOpen={containerOpen}
          editId={editId}
        />
      </AuthorizedCan>
    </StyledContainer>
  );
};

export default withErrorHandler(withMatch(withRouter(Comments)));
