import React, { useMemo } from 'react';
import { sortCards } from '../../services/card';
import { Card } from '../atoms/Card';

const CardIcons = ({ cards, cardRole, isTeamSupport = false }) => {
  const sortedCards = useMemo(() => {
    return sortCards(cards);
  }, [cards]);

  return (
    <>
      {sortedCards.map((card) =>
        isTeamSupport === card.is_coach_card ? (
          <Card
            key={card.id}
            color={card.color}
            role={cardRole ?? (card.is_captain_card ? 'A' : undefined)}
            data-testid={`card-${card.id}`}
          />
        ) : null
      )}
    </>
  );
};

export default CardIcons;
