import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledId = styled.div`
  padding: ${theme.sizing.xsmall} ${theme.sizing.small};
  border: 1px solid ${theme.color.gray.alt};
  border-radius: 3px;
  font-weight: 700;
  overflow: hidden;
  text-transform: uppercase;
  min-width: 49px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TeamId = (props) => <StyledId {...props} />;
