import React, { FunctionComponent, useEffect, useState } from 'react';
import { getAuthUrl } from '../../../api/sso';
import { getQueryParams } from '../../../services/queryParams';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';

export const SKIP_LOGIN_PROMPT_QUERY_KEY = 'skipLoginPrompt';

export const Login: FunctionComponent = () => {
  const [authUrl, setAuthUrl] = useState<string | null>(null);

  useEffect(() => {
    const skipLoginPrompt = getQueryParams()?.[SKIP_LOGIN_PROMPT_QUERY_KEY] === 'true';

    getAuthUrl(!skipLoginPrompt).then(setAuthUrl);
  }, []);

  if (typeof authUrl !== 'string') {
    return <LoadingScreen>U wordt doorgestuurd naar de SSO.</LoadingScreen>;
  }

  return (
    <LoadingScreen>
      U wordt doorgestuurd naar &ldquo;{(window.location.href = authUrl)}&rdquo;
    </LoadingScreen>
  );
};
