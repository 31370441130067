import React from "react";
import { styled } from "styled-components";

const VisuallyHiddenDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export const VisuallyHidden = ({ children }) => (
  <VisuallyHiddenDiv>{children}</VisuallyHiddenDiv>
);
