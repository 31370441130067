type SetListener = (_value: string) => void;

const setListeners: {
  [key: string]: Array<SetListener> | undefined;
} = {};

class Storage {
  static addSetListener(key: string, callback: SetListener) {
    if (!(setListeners[key] instanceof Array)) {
      setListeners[key] = [];
    }

    setListeners[key]?.push(callback);
  }

  static setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value);

    const listeners = setListeners[key];

    if (listeners instanceof Array) {
      listeners.forEach((listener) => listener(value));
    }
  }

  static getItem(key: string): string | null {
    return window.localStorage.getItem(key);
  }
}

export default Storage;
