import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Calendar = ({ className }: Props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g>
      <path d="M3.077.577v2.115c0 .327.25.577.577.577.327 0 .577-.25.577-.577V.577A.567.567 0 0 0 3.654 0a.567.567 0 0 0-.577.577zM10.962.577v2.115c0 .327.25.577.576.577.327 0 .577-.25.577-.577V.577A.567.567 0 0 0 11.538 0a.567.567 0 0 0-.576.577z" />
      <path d="M14.404 1.73h-1.712v.962c0 .635-.519 1.154-1.154 1.154-.634 0-1.153-.52-1.153-1.154v-.961H4.808v.961c0 .635-.52 1.154-1.154 1.154-.635 0-1.154-.52-1.154-1.154v-.961H.596A.6.6 0 0 0 0 2.327v11.096a.6.6 0 0 0 .596.596h13.789a.6.6 0 0 0 .596-.596V2.327a.571.571 0 0 0-.577-.596zm-9.98 9.79a.6.6 0 0 1-.597.595h-1.48a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597h1.48a.6.6 0 0 1 .597.596v1.481zm0-4.424a.6.6 0 0 1-.597.596h-1.48a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597h1.48a.6.6 0 0 1 .597.596v1.481zm4.422 4.423a.6.6 0 0 1-.596.596H6.77a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597H8.25a.6.6 0 0 1 .596.596v1.481zm0-4.423a.6.6 0 0 1-.596.596H6.77a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597H8.25a.6.6 0 0 1 .596.596v1.481zm4.423 4.423a.6.6 0 0 1-.596.596h-1.48a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597h1.481a.6.6 0 0 1 .596.596v1.481zm0-4.423a.6.6 0 0 1-.596.596h-1.48a.6.6 0 0 1-.597-.596v-1.48a.6.6 0 0 1 .596-.597h1.481a.6.6 0 0 1 .596.596v1.481z" />
    </g>
  </svg>
);

export default Calendar;
