import React from 'react';
import Tooltip from 'rc-tooltip';
import { styled } from 'styled-components';
import TooltipIcon from '../atoms/Icons/Tooltip';
import { theme } from '../templates/ui';

const TooltipWrapper = styled.div`
  display: flex;
  margin-left: ${theme.sizing.small};

  a:focus {
    outline: none;
  }

  .rc-tooltip {
    opacity: 1 !important;
    padding-left: ${theme.sizing.large} !important;
    padding-right: ${theme.sizing.large} !important;
    display: inline-block;
  }

  .rc-tooltip {
    z-index: 3;
  }

  .rc-tooltip-inner {
    min-height: auto !important;
    color: ${theme.color.gray.darkest} !important;
    font-size: ${theme.font.medium};
    border-radius: 3px !important;

    h3 {
      margin-top: 0;
      font-size: ${theme.font.medium};
      margin-bottom: ${theme.sizing.small};
    }

    span {
      font-weight: 400;
      line-height: 1.2rem;
    }
  }

  .rc-tooltip-hidden {
    display: none;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block !important;
  }

  .rc-tooltip-inner {
    padding: ${theme.sizing.large};
    background-color: white;
    border: 1px solid ${theme.color.gray.light};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  }

  .rc-tooltip-container {
    display: flex;
    justify-content: center;

    > div {
      pointer-events: none;
    }
  }
`;

const TooltipContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const TooltipItem = ({
  tooltipTitle,
  tooltipBody,
  containerId,
  triggerIcon,
  'data-testid': dataTestId,
}) => {
  return (
    <TooltipWrapper>
      <Tooltip
        placement="bottom"
        destroyTooltipOnHide={true}
        trigger={['click']}
        getTooltipContainer={() => document.getElementById(containerId)}
        overlay={
          <>
            {tooltipTitle && <h3>{tooltipTitle}</h3>}
            <span>{tooltipBody}</span>
          </>
        }
      >
        <TooltipContainer
          className="rc-tooltip-container"
          id={containerId}
          data-testid={dataTestId}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {triggerIcon ? triggerIcon : <TooltipIcon />}
        </TooltipContainer>
      </Tooltip>
    </TooltipWrapper>
  );
};

export default TooltipItem;
