import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { styled } from 'styled-components';
import { scrolltoElementWhenMobileKeyboardAppears } from '../../../services/scroll';
import { theme } from '../../templates/ui';

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled(InputMask)`
  position: relative;
  width: 100%;
  padding: 1rem 1rem;
  border-radius: 3px;
  margin-bottom: ${theme.sizing.xsmall};
  border: 1px solid ${theme.color.gray.light};
  color: ${theme.color.base.base};
  font-size: ${theme.font.base};
  box-sizing: border-box;
  appearance: none;

  &::placeholder {
    color: ${theme.color.gray.darker};
  }

  &:focus {
    margin-bottom: 0;
    outline: none;
    border-bottom: 3px solid ${theme.color.primary.base};
  }

  ${(props) => props.icon && `padding-left: 54px;`};
`;

const StyledIconWrapper = styled.div`
  position: absolute;
  left: ${theme.sizing.xlarge};
  top: ${theme.sizing.xlarge};
  z-index: 1;
  color: ${theme.color.gray.darkest};
  font-size: ${theme.font.xxxlarge};
  transition: all 0.1s ease-in-out;
`;

const StyledButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.sizing.xlarge};
  background-color: transparent;
  color: ${theme.color.gray.darker};
  transition: color 0.2s ease-in-out;
  padding: 0;
  border: none;

  &:focus,
  &:hover {
    cursor: pointer;
    color: ${theme.color.primary.base};
  }
`;

export const Input = ({ ...restProps }) => {
  const [focus, setFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputType = showPassword === true ? 'text' : restProps.type;

  return (
    <StyledWrapper focus={focus}>
      <StyledInput
        {...restProps}
        onFocus={({ currentTarget }) => {
          scrolltoElementWhenMobileKeyboardAppears(currentTarget);

          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        type={inputType}
      />

      {inputType === 'password' && <StyledButton onClick={toggleShowPassword}>Tonen</StyledButton>}

      <StyledIconWrapper>{restProps.icon}</StyledIconWrapper>
    </StyledWrapper>
  );
};
