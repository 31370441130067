import { PERMISSIONS } from '../@types/Permissions';
import { hasPermissionsCheck } from '../contexts/RoleContext/RoleContext';
import {
  isCancelled,
  isConfirmedByReferee,
  isLocked,
  isOpen,
  isOpenForSecretary,
  matchHasStarted,
  matchMayNotBeUpdated,
} from './match';

export const canOnlyConfirmAsCoach = (role) => {
  // approve as coach needs a team id, but in this scenario we just
  // want to check if the permission exists and we don't care about which team
  const coach = role.permissions.includes(PERMISSIONS.APPROVE_AS_COACH);
  const other =
    role.permissions.includes(PERMISSIONS.APPROVE_AS_UMPIRE) ||
    role.permissions.includes(PERMISSIONS.APPROVE_AS_SUPERUSER);

  return coach && !other;
};

export const canOnlyConfirmAsReferee = (role) => {
  const referee = role.permissions.includes(PERMISSIONS.APPROVE_AS_UMPIRE);
  const other =
    role.permissions.includes(PERMISSIONS.APPROVE_AS_COACH) ||
    role.permissions.includes(PERMISSIONS.APPROVE_AS_SUPERUSER);

  return referee && !other;
};

export const canMatchScoreBeUpdated = (match, user) => {
  const matchScoreCanBeUpdated =
    !matchMayNotBeUpdated(match, user) &&
    !isConfirmedByReferee(match) &&
    !isCancelled(match) &&
    matchHasStarted(match);

  return matchScoreCanBeUpdated;
};

export const canSeeQuestions = () => false; //Temporary disabled because of the demo

export const canConfirmMatch = (user, match, isSecretary, role) => {
  if (!match.uuid) {
    return false;
  }

  const matchCanBeConfirmed = isOpen(match) && !isCancelled(match);

  const hasCoachPermissions =
    hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_COACH], {
      teamId: match.away_team.id,
    }) ||
    (hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_COACH], {
      teamId: match.home_team.id,
    }) &&
      !!(match.referee_confirmation || user.coach_can_enter_score));

  const hasRefereePermissions = hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_UMPIRE]);

  const hasSecretaryPermissions = isSecretary && isOpenForSecretary(match);

  const hasSuperuserPermissions = hasPermissionsCheck(role, [PERMISSIONS.APPROVE_AS_SUPERUSER]);

  return (
    (matchCanBeConfirmed &&
      (hasCoachPermissions || hasRefereePermissions || hasSecretaryPermissions)) ||
    hasSuperuserPermissions
  );
};

export const canManageTeamSupport = (role) => {
  const hasManageTeamSupportPermission = hasPermissionsCheck(role, [
    PERMISSIONS.MANAGE_ALL_TEAMS_SUPPORT,
  ]);

  const hasCorrectAfterClosingPermissions = hasPermissionsCheck(role, [
    PERMISSIONS.CORRECT_AFTER_CLOSING,
  ]);

  const hasPermissions = hasManageTeamSupportPermission || hasCorrectAfterClosingPermissions;

  return hasPermissions;
};

export const canConfirmPlayers = (match, teamId, role) => {
  const hasManageTeamPermissions = hasPermissionsCheck(role, [PERMISSIONS.MANAGE_TEAM], {
    teamId,
  });

  const hasManageAllTeamsPermissions = hasPermissionsCheck(role, [PERMISSIONS.MANAGE_ALL_TEAMS]);

  const hasManageTeamPermission = hasManageTeamPermissions || hasManageAllTeamsPermissions;

  const hasCorrectAfterClosingPermissions = hasPermissionsCheck(role, [
    PERMISSIONS.CORRECT_AFTER_CLOSING,
  ]);

  const additionalConditionsWithoutCorrectAfterClosingPermission =
    !!match.dwf_options?.players &&
    !isLocked(match) &&
    !isConfirmedByReferee(match) &&
    isOpen(match);

  const hasPermissions =
    (hasManageTeamPermission && additionalConditionsWithoutCorrectAfterClosingPermission) ||
    hasCorrectAfterClosingPermissions;

  return hasPermissions;
};

export const canCancelMatch = (match, isSecretary = false, role) => {
  const hasPermissions = hasPermissionsCheck(role, [PERMISSIONS.CANCEL_MATCH]);

  const conditions = !match.referee_confirmation && !isCancelled(match);

  const hasSecretaryPermissions = isSecretary && isOpenForSecretary(match);

  return !!((conditions || hasSecretaryPermissions) && hasPermissions);
};

export const canUnCancelMatch = (match, isSecretary = false, role) => {
  const hasPermissions = hasPermissionsCheck(role, [PERMISSIONS.CANCEL_MATCH]);

  const hasCorrectAfterClosingPermissions = hasPermissionsCheck(role, [
    PERMISSIONS.CORRECT_AFTER_CLOSING,
  ]);

  const conditions = !match.referee_confirmation && isCancelled(match);

  const hasSecretaryPermissions = isSecretary && isOpenForSecretary(match);

  return !!(
    ((conditions || hasSecretaryPermissions) && hasPermissions) ||
    hasCorrectAfterClosingPermissions
  );
};

export const canAddPlayer = (player) => {
  const active = !!player.active;

  if (active) {
    return true;
  }

  return true;
};
