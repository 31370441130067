import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const IconRain = ({ className }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 100 100"
    className={className}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      transform="matrix(.69 0 0 .69 15.5 15.5)"
    >
      <path d="M25 19H8A7 7 0 1 1 9.59 5.18 7 7 0 0 1 23 7.35 6 6 0 1 1 25 19zM2.71 24.71l2-2a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0zm0 6l8-8a1 1 0 0 0-1.42-1.42l-8 8a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0zM8 31a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L9.64 27a1 1 0 0 1 1.41 1.41l-2.34 2.3A1 1 0 0 1 8 31zm5.17-5.17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l2.83-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83a1 1 0 0 1-.71.29zm1.54 4.88l8-8a1 1 0 0 0-1.42-1.42l-8 8a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0zM20 31a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42L21.64 27a1 1 0 0 1 1.41 1.41l-2.34 2.35A1 1 0 0 1 20 31zm5.17-5.17a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.42l2.83-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83a1 1 0 0 1-.71.29z" />
    </svg>
  </svg>
);

export default IconRain;
