import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledImage = styled.div`
  margin-bottom: ${theme.sizing.xxxxlarge};

  img {
    display: block;
    max-width: 100%;
    max-height: 400px;
    margin: 0 auto;
  }
`;

export const FaqImage = ({ children, className }) => (
  <StyledImage className={className}>{children}</StyledImage>
);
