import React, { FunctionComponent } from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const Scores = styled.div`
  padding: 0 0 ${theme.sizing.small} 0;
  width: 100%;
`;

const Label = styled.label`
  color: ${theme.color.gray.darkest};
  font-weight: 600;
  margin-right: ${theme.sizing.large};

  span {
    color: ${theme.color.gray.darkest};
    font-weight: 400;
  }
`;

export interface CommentScoresProps {
  home: number;
  homeShootout?: number;
  away: number;
  awayShootout?: number;
}

export const CommentScores: FunctionComponent<CommentScoresProps> = ({
  home,
  homeShootout,
  away,
  awayShootout,
}) => {
  return (
    <Scores>
      <Label data-testid="comment-score">
        Score:{' '}
        <span>
          {home}-{away}
        </span>
      </Label>
      {(awayShootout !== null || homeShootout !== null) && (
        <Label data-testid="comment-shootout-score">
          Shootouts:{' '}
          <span>
            {homeShootout ?? '-'}-{awayShootout ?? '-'}
          </span>
        </Label>
      )}
    </Scores>
  );
};
