import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { redirect_after_logout } from '../../../config/sso';
import { fullName } from '../../../services/person';
import { Bar } from '../../atoms/Bar';
import Coach from '../../atoms/Icons/Coach';
import { Role } from '../../atoms/Role';
import { Wrapper } from '../../atoms/Wrapper';
import IconButton from '../../molecules/IconButton';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledRole = styled(Role)`
  width: 75px;
  height: 75px;

  svg {
    width: 30px;
    height: 41px;
  }
`;

const StyledButton = styled(IconButton)`
  width: 100%;
  color: ${theme.color.body.base};
  background-color: ${theme.color.primary.base};
  margin-bottom: ${theme.sizing.large};

  &:hover,
  &:focus {
    background-color: ${theme.color.primary.base};
  }
`;

const StyledParagraph = styled.p`
  color: ${theme.color.gray.darkest};
  font-weight: 400;
`;

const Name = styled.h4`
  width: 100%;
  max-width: 140px;
  word-break: break-word;
  margin: 0;

  @media only screen and (min-width: ${theme.breakpoints.small}) {
    max-width: 180px;
  }

  @media only screen and (min-width: ${theme.breakpoints.large}) {
    max-width: 500px;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Spacer = styled.div`
  width: 75px;
  height: 75px;
`;

export const Account = ({ userProfile, logout: logoutFunc }) => {
  const logout = () => {
    logoutFunc();
    window.location = redirect_after_logout;
  };

  return (
    <div>
      <TopBar>Mijn account</TopBar>
      <Bar>
        <StyledWrapper>
          <StyledRole>
            <Coach />
          </StyledRole>
          <Name>{fullName(userProfile)}</Name>
          <Spacer />
        </StyledWrapper>
      </Bar>
      <Wrapper>
        <StyledParagraph>
          Om gegevens binnen je account te wijzigen dien je kort door te gaan naar het centrale
          account beheer platform waar wij jouw gegevens veilig hebben opgeslagen. Middels de
          onderstaande knop kom je automatisch uit op jouw account.
        </StyledParagraph>
        <Link to="/matches">
          <StyledButton className="button">Wedstrijden</StyledButton>
        </Link>
        <StyledButton className="button" onClick={() => logout()}>
          Uitloggen
        </StyledButton>
      </Wrapper>
    </div>
  );
};
