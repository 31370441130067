import axios, { type AxiosRequestConfig, type AxiosResponse } from 'axios';
import env from '../env.json';
import { notifyError } from '../services/bugsnag';
import { generateTimeout } from '../services/timeoutGenerator';
import { ID_TOKEN_KEY, redirectToSso, refreshJWT, saveIdToken } from './sso';
import { BAD_REQUEST, NOT_FOUND, UNAUTHORIZED } from './status_codes';

const { api_max_retries } = env;

interface ClientRequestConfig extends AxiosRequestConfig {
  idToken?: string;
}

class Client {
  static async get<ResponseType>(
    url: string,
    config?: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    if (!config) {
      config = {};
    }

    return Client.request<ResponseType>(
      {
        ...config,
        url,
      },
      pageKey,
      apiCallKey
    );
  }

  static async post<ResponseType, DataType>(
    url: string,
    data: DataType,
    config?: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    if (!config) {
      config = {};
    }

    return Client.request<ResponseType>(
      {
        ...config,
        url,
        method: 'post',
        data,
      },
      pageKey,
      apiCallKey
    );
  }

  static async put<ResponseType, DataType>(
    url: string,
    data: DataType,
    config?: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    if (!config) {
      config = {};
    }

    return Client.request<ResponseType>(
      {
        ...config,
        url,
        method: 'put',
        data,
      },
      pageKey,
      apiCallKey
    );
  }

  static async patch<ResponseType, DataType>(
    url: string,
    data: DataType,
    config?: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    if (!config) {
      config = {};
    }

    return Client.request<ResponseType>(
      {
        ...config,
        url,
        method: 'patch',
        data,
      },
      pageKey,
      apiCallKey
    );
  }

  static async delete<ResponseType, DataType>(
    url: string,
    data: DataType,
    config?: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    if (!config) {
      config = {};
    }

    return Client.request(
      {
        ...config,
        url,
        method: 'delete',
        data,
      },
      pageKey,
      apiCallKey
    );
  }

  static async request<ResponseType>(
    clientRequestConfig: ClientRequestConfig,
    pageKey?: string,
    apiCallKey?: string
  ): Promise<AxiosResponse<ResponseType>> {
    return (async function _request(tries, lastTimeout?: number) {
      Client.setAuthorizationHeader(clientRequestConfig.idToken);

      try {
        const response = await axios.request(clientRequestConfig);

        return response;
      } catch (error: any) {
        let status = 0;

        if (!!error.response) {
          status = error.response.status || 0;
        }

        if (status === NOT_FOUND || status === BAD_REQUEST) {
          throw error;
        }

        if (status === UNAUTHORIZED) {
          let jwt;

          try {
            jwt = await refreshJWT();
          } catch (e: any) {
            if (e?.response?.status !== UNAUTHORIZED) {
              notifyError(error, pageKey, apiCallKey);
            }

            await redirectToSso(false);

            return;
          }

          if (!jwt.id_token || !jwt.refresh_token) {
            await redirectToSso(false);

            return;
          }

          saveIdToken(jwt.id_token, jwt.refresh_token);

          try {
            Client.setAuthorizationHeader(jwt.id_token);

            return await axios.request(clientRequestConfig);
          } catch (e: any) {
            if (e?.response.status === UNAUTHORIZED) {
              await redirectToSso(false);

              return;
            }

            throw e;
          }
        }

        notifyError(error, pageKey, apiCallKey);

        if (status >= 500 && status < 600) {
          if (tries === api_max_retries) {
            throw error;
          }

          const timeout = generateTimeout(tries, lastTimeout);

          await (async () => new Promise((resolve) => setTimeout(resolve, timeout)))();

          return _request(tries + 1, timeout);
        }

        throw error;
      }
    })(1);
  }

  static setAuthorizationHeader(idToken?: string): void {
    const token = idToken || localStorage.getItem(ID_TOKEN_KEY);
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }
}

export default Client;
