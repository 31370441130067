import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Comment = ({ className }: Props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" className={className}>
    <path d="M10.316 9.947h2.579C13.827 9.947 14 9.76 14 8.85V1.112C14 .224 13.783 0 12.895 0H1.105C.195 0 0 .202 0 1.112v7.726c0 .91.151 1.11 1.105 1.11h4.79s3.522 3.228 3.96 3.618c.156.138.472.067.46-.296V9.947zM2.21 6.632h7.736v1.105H2.211V6.632zm0-2.21H7v1.104H2.21V4.421zm0-2.211h9.578v1.105H2.211V2.21z" />
  </svg>
);

export default Comment;
