import React, { useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import { LoadingScreen } from '../../pages/LoadingScreen';
import { theme } from '../../templates/ui';

const LoginApp = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.color.primary.base};
`;

const Callback = ({ handleAuthenticate }) => {
  const location = useLocation();
  const { code, state } = queryString.parse(location.search);

  useEffect(() => {
    handleAuthenticate(code, state);
  }, []);

  return (
    <LoginApp>
      <LoadingScreen title="Bezig met inloggen" />
    </LoginApp>
  );
};

export default Callback;
