import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Account = ({ className }: Props) => (
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M22.987 21.391c-.045-.669-.178-2.308-.45-3.068-.35-.982-.781-1.424-1.321-1.784-.266-.177-1.251-.55-2.724-1.1-1.338-.5-2.855-1.066-3.4-1.375-.49-.28-.87-1.567-.858-1.97.006-.019.045-.107.332-.42.578-.632 1.344-1.846 1.658-3.022.429-1.603.758-4.851-.728-6.851C14.652.664 13.338.059 11.59.003L11.46 0c-1.794.059-3.11.664-3.954 1.802-1.486 2-1.156 5.247-.727 6.85.314 1.175 1.077 2.388 1.654 3.018.098.107.296.324.339.444.153.432-.403 1.688-.862 1.95-.543.309-2.06.875-3.418 1.382-1.463.546-2.442.917-2.705 1.093-.54.36-.97.8-1.322 1.783-.273.765-.407 2.416-.452 3.09L0 21.6l.136.13c.397.377 2.39 1.61 11.365 1.61 8.902 0 10.944-1.246 11.358-1.627l.141-.13-.013-.192z" />
  </svg>
);

export default Account;
