import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { styled } from 'styled-components';
import Close from '../atoms/Icons/Close';
import PlusIcon from '../atoms/Icons/PlusIcon';
import { PanelHeader } from '../atoms/PanelHeader';
import { PanelMain } from '../atoms/PanelMain';
import { theme } from '../templates/ui';

const StyledPlusIcon = styled(PlusIcon)`
  fill: ${theme.color.body.base};
  margin-right: ${theme.sizing.large};
`;

const StyledCloseIcon = styled(Close)`
  fill: ${theme.color.body.base};
  margin-right: ${theme.sizing.large};
`;

const PanelWrapper = styled.div`
  padding: ${theme.sizing.xlarge} 0;
`;

const Panel = ({ children, open, title, onClosePanel, onOpenPanel, ...props }) => {
  const [height, setHeight] = useState(open ? 'auto' : 0);

  useEffect(() => {
    setHeight(open ? 'auto' : 0);
  }, [open]);

  const toggle = () => {
    const newHeight = height === 0 ? 'auto' : 0;

    if (newHeight === 0) {
      onClosePanel();
    } else {
      onOpenPanel();
    }

    setHeight(newHeight);
  };

  return (
    <PanelWrapper {...props}>
      <PanelHeader
        onClick={toggle}
        icon={height === 'auto' ? <StyledCloseIcon /> : <StyledPlusIcon />}
        title={title}
      >
        {height === 0 ? 'Open' : 'Close'}
      </PanelHeader>
      <AnimateHeight duration={400} height={open ? 'auto' : height}>
        <PanelMain>{children}</PanelMain>
      </AnimateHeight>
    </PanelWrapper>
  );
};

export default Panel;
