import React, { createContext } from 'react';

const AppError = createContext({
  setAppError: null,
});

export const withAppError = (Component) => {
  function withAppError(props) {
    return (
      <AppError.Consumer>
        {({ setAppError }) => <Component {...props} appError={setAppError} />}
      </AppError.Consumer>
    );
  }
  withAppError.displayName = `withAppError(${Component.displayName})`;

  return withAppError;
};

export default AppError;
