import React from 'react';
import { styled } from 'styled-components';
import { Bar } from '../../atoms/Bar';
import { FaqImage } from '../../atoms/FaqImage';
import { Paragraph } from '../../atoms/Paragraph';
import { Wrapper } from '../../atoms/Wrapper';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledBar = styled(Bar)`
  text-align: left;
  padding: ${theme.sizing.xxlarge} 0;
  margin-bottom: 0;

  h3 {
    margin: 0;
  }
`;

const FaqWrapper = styled.div`
  margin-bottom: ${theme.sizing.xxlarge};
`;

export const FaqIntroduction = () => (
  <>
    <TopBar>Hoe werkt het DWF</TopBar>
    <StyledBar>
      <Wrapper>
        <h3>Introductie</h3>
      </Wrapper>
    </StyledBar>
    <Wrapper>
      <FaqWrapper>
        <Paragraph>
          Wanneer je bent ingelogd in het DWF, kom je op de pagina ‘wedstrijden’ terecht. Hier zie
          je een overzicht van wedstrijden:
        </Paragraph>
        <FaqImage>
          <img src="../../1-wedstrijdoverzicht.png" alt="" />
        </FaqImage>
        <Paragraph>
          Wedstrijden van vandaag: hier vind je een overzicht van de wedstrijden die vandaag
          gespeeld worden en waarvoor jij een code hebt ontvangen.
        </Paragraph>
        <Paragraph>
          Toekomstige wedstrijden: Voor deze wedstrijden ontvang je een code waarmee je het DWF kunt
          invullen.
        </Paragraph>
        <Paragraph>
          Gespeelde wedstrijden: Dit zijn de wedstrijden waarvoor jij een DWF hebt ingevuld.
        </Paragraph>
        <Paragraph>
          Op deze pagina kun je ook je wedstrijdverificatie code invullen. Deze code heb je per mail
          ontvangen en machtigt jou om het DWF te gebruiken. Typ de code in, in het veld achter het
          oranje fluitje, en druk op de groene button om de wedstrijd definitief aan jouw profiel te
          koppelen.
        </Paragraph>
        <Paragraph>
          In de overzichten ‘Voor scheidsrechters’ en ‘Voor coaches, managers en aanvoerders’ vind
          je alle informatie die je nodig hebt om het formulier in te vullen. Daarnaast is er een
          legenda met daarin een overzicht van de gebruikte symbolen en hun verklaring.
        </Paragraph>
      </FaqWrapper>
    </Wrapper>
  </>
);

export default FaqIntroduction;
