import React from 'react';
import Lottie from 'react-lottie';

export const HockeyStickAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../hockeystick.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie options={defaultOptions} height={300} width={300} isStopped={false} isPaused={false} />
  );
};
