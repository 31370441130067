// https://glebbahmutov.com/blog/stub-location/
export const Location = {
  assign(url: string) {
    window?.location.assign(url);
  },
  replace(url: string) {
    window?.location.replace(url);
  },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (window.Cypress) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Location = Location;
}
