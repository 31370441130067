// All available card colors
export const CARD_COLORS = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
} as const;

export type CardColor = (typeof CARD_COLORS)[keyof typeof CARD_COLORS];

export const CARD_TYPES = {
  VERBAL: 'verbal',
  PHYSICAL: 'physical',
  OTHER: 'other',
};

export type CardType = (typeof CARD_TYPES)[keyof typeof CARD_TYPES];
