export const showShootoutsAreRequiredNotification = (match) => {
  if (match.home_score !== match.away_score || match.dwf_options?.shootouts === 0) {
    return false;
  }

  if (isEmpty(match.home_shootout_score) || isEmpty(match.away_shootout_score)) {
    return true;
  }

  if (match.home_shootout_score === match.away_shootout_score) {
    return true;
  }

  return false;
};

export const isEmpty = (score) => {
  if (score === undefined) {
    return true;
  }
  if (score === null) {
    return true;
  }
  if (score === '') {
    return true;
  }

  return false;
};
