import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledMain = styled.div`
  padding: ${theme.sizing.xlarge};
  background-color: ${theme.color.gray.base};
`;

export const PanelMain = ({ isHidden, children }) => (
  <StyledMain isHidden={isHidden}>{children}</StyledMain>
);
