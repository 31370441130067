import React, { FunctionComponent } from 'react';
import { styled } from 'styled-components';
import { LoadingAnimation } from '../../atoms/LoadingAnimation';
import { theme } from '../../templates/ui';

const StyledPage = styled.div`
  background-color: ${theme.color.body.base};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 ${theme.sizing.xlarge};
`;

const StyledMessage = styled.p`
  color: ${theme.color.primary.base};
  text-align: center;
  padding-top: ${theme.sizing.xlarge};
  font-size: ${theme.font.medium};
`;

const StyledTitle = styled.h2`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.large};
  text-align: center;
`;

interface Props {
  title?: string;
  children?: any;
}

export const LoadingScreen: FunctionComponent<Props> = ({ children, title }) => {
  return (
    <StyledPage>
      <Container>
        <LoadingAnimation />
        <StyledTitle>{title}</StyledTitle>
        {children && <StyledMessage>{children}</StyledMessage>}
      </Container>
    </StyledPage>
  );
};
