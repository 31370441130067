import React, { useContext } from 'react';
import { PERMISSIONS } from '../../../@types/Permissions';
import { withAppError } from '../../../contexts/AppErrorContext';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { withUser } from '../../../contexts/UserContext';
import { AuthorizedGuard } from '../../atoms/AuthorizedGuard/AuthorizedGuard';
import { LoadingScreen } from '../../pages/LoadingScreen';
import Match from './Match';

const Show = (props) => {
  const { isLoading } = useContext(RoleContext);

  return isLoading ? (
    <LoadingScreen />
  ) : (
    <AuthorizedGuard redirect="/404" has={[PERMISSIONS.VIEW_MATCH]}>
      <Match {...props} />
    </AuthorizedGuard>
  );
};

export default withErrorHandler(withAppError(withMatch(withUser(Show))));
