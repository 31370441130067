import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { css, styled } from 'styled-components';
import Account from '../atoms/Icons/Account';
import ChevronLeft from '../atoms/Icons/ChevronLeft';
import Info from '../atoms/Icons/Info';
import { Title } from '../atoms/Title';
import { theme } from '../templates/ui';

const StyledTopBar = styled.header`
  position: fixed;
  width: 100%;
  background-color: ${theme.color.primary.base};
  z-index: 5;
`;

const TopBarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0.75rem ${theme.sizing.xlarge};
  max-width: 768px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
`;

const TitleWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledTitle = styled(Title)`
  margin: 0;
  line-height: 28px;
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 60px;
`;

const StyledLink = styled(Link)`
  background-color: transparent;
  font-size: ${theme.font.xxxxlarge};
  color: ${theme.color.body.base};
  width: 32px;

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const TransparentButton = styled.button`
  background-color: transparent;
  border-color: transparent;
  fill: ${theme.color.body.base};
  cursor: pointer;
  padding-left: 0;
  outline: none;

  ${(props) =>
    props.hideActions &&
    css`
      opacity: 0;
      pointer-events: none;
    `};

  svg {
    pointer-events: none;
  }

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  font-size: ${theme.font.xxxxlarge};
  color: ${theme.color.body.base};
  width: 32px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;

  ${(props) =>
    props.hideActions &&
    css`
      opacity: 0;
      pointer-events: none;
    `};

  ${(props) => (props.disabled ? 'visibility:hidden;' : '')}

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const StyledChevronLeft = styled(ChevronLeft)`
  fill: ${theme.color.body.base};
`;

const TopBarPush = styled.div`
  position: relative;
  height: 56px;
`;

const StyledAccount = styled(Account)`
  fill: ${theme.color.body.base};
`;

const TopBar = ({ back, children, hideActions }) => {
  const history = useHistory();

  return (
    <>
      <StyledTopBar>
        <TopBarWrapper>
          {document.location.pathname !== '/matches' ? (
            <TransparentButton
              data-testid="back-button"
              hideActions={hideActions}
              onClick={() => {
                back ? back() : history.length > 1 ? history.goBack() : history.push('/matches');
              }}
            >
              <StyledChevronLeft />
            </TransparentButton>
          ) : (
            <StyledButton disabled hideActions={hideActions} />
          )}
          <TitleWrapper>
            <StyledTitle>{children}</StyledTitle>
          </TitleWrapper>
          {hideActions ? null : (
            <LinkWrapper>
              <StyledLink to="/faq">
                <Info />
              </StyledLink>
              <StyledLink to="/account" data-testid="account-link">
                <StyledAccount />
              </StyledLink>
            </LinkWrapper>
          )}
        </TopBarWrapper>
      </StyledTopBar>
      <TopBarPush />
    </>
  );
};
export default TopBar;
