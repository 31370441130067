import React from 'react';
import { styled } from 'styled-components';
import { Bar } from '../../atoms/Bar';
import { FaqImage } from '../../atoms/FaqImage';
import Calendar from '../../atoms/Icons/Calendar';
import Clock from '../../atoms/Icons/Clock';
import Comment from '../../atoms/Icons/Comment';
import Info from '../../atoms/Icons/Info';
import Pencil from '../../atoms/Icons/Pencil';
import RedCard from '../../atoms/Icons/RedCard';
import SubstituteIcon from '../../atoms/Icons/SubstituteIcon';
import Whistle from '../../atoms/Icons/Whistle';
import YellowCard from '../../atoms/Icons/YellowCard';
import { Meta } from '../../atoms/Meta';
import { TitleUnderline } from '../../atoms/TitleUnderline';
import { Wrapper } from '../../atoms/Wrapper';
import TooltipItem from '../../molecules/TooltipItem';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledBar = styled(Bar)`
  text-align: left;
  padding: ${theme.sizing.xxlarge} 0;
  margin-bottom: 0;

  h3 {
    margin: 0;
  }
`;

const FaqWrapper = styled.div`
  margin-bottom: ${theme.sizing.xxlarge};
`;

const StyledFaqImage = styled(FaqImage)`
  img {
    margin: ${theme.sizing.large} 0 0;
    max-width: 350px;
  }
`;

const StyledCalendar = styled(Calendar)`
  fill: ${theme.color.primary.base};
`;

const StyledClock = styled(Clock)`
  fill: ${theme.color.primary.base};
`;

const StyledWhistle = styled(Whistle)`
  fill: ${theme.color.primary.base};
`;

const StyledComment = styled(Comment)`
  fill: ${theme.color.primary.base};
`;

const StyledMetaRefereeCode = styled(Meta)`
  justify-content: normal;
  font-weight: 600;
  margin-top: ${theme.sizing.large};
`;

const StyledMeta = styled(Meta)`
  justify-content: normal;
  margin-top: ${theme.sizing.large};
`;

const Number = styled.div`
  display: inline-block;
  padding: ${theme.sizing.small} 6px ${theme.sizing.small} ${theme.sizing.small};
  margin-top: ${theme.sizing.large};
  margin-right: ${theme.sizing.large};
  background-color: ${theme.color.gray.alt};
  color: ${theme.color.gray.darkest};
  transition: 0.1s all ease-in-out;
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  min-width: 30px;
  min-height: 28px;
  text-align: center;

  ${({ checked }) =>
    checked &&
    `
    background-color: ${theme.color.primary.base};
    color: ${theme.color.body.base};
    cursor: pointer;
  `};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSubstituteIcon = styled(SubstituteIcon)`
  margin-top: ${theme.sizing.large};
`;

const StyledPencil = styled(Pencil)`
  fill: ${theme.color.gray.darkest};
  margin-top: ${theme.sizing.large};
`;

const CardWrapper = styled.div`
  margin: ${theme.sizing.large} ${theme.sizing.large} 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.color.primary.base};
  width: 35px;
  height: 35px;
  border-radius: 3px;
  margin-top: ${theme.sizing.large};
`;

const StyledInfo = styled(Info)`
  fill: ${theme.color.primary.base};
`;

const StyledTitleUnderline = styled(TitleUnderline)`
  margin-top: ${theme.sizing.xxxxlarge};
`;

const StyledNumber = styled(Number)`
  opacity: 0.35;
`;

export const FaqLegend = () => (
  <>
    <TopBar>Hoe werkt het DWF</TopBar>
    <StyledBar>
      <Wrapper>
        <h3>Legenda</h3>
      </Wrapper>
    </StyledBar>
    <Wrapper>
      <FaqWrapper>
        <StyledTitleUnderline>Datum</StyledTitleUnderline>
        <StyledMeta icon={<StyledCalendar />} value="01-01-2020" />
        <StyledTitleUnderline>Tijdstip aanvang wedstrijd</StyledTitleUnderline>
        <StyledMeta icon={<StyledClock />} value="12:34" />
        <StyledTitleUnderline>Wedstrijdverificatie code</StyledTitleUnderline>
        <StyledMetaRefereeCode icon={<StyledWhistle />} value="1234AB">
          <TooltipItem
            containerId="refereecode-tooltip"
            tooltipTitle="Scheidsrechter verificatiecode"
            tooltipBody="Geef deze code aan de scheidsrechter om de wedstrijd te kunnen fluiten."
          />
        </StyledMetaRefereeCode>
        <StyledTitleUnderline>Opmerkingen</StyledTitleUnderline>
        <StyledMeta icon={<StyledComment />} value={1} />
        <StyledTitleUnderline>Stand na shoot-outs</StyledTitleUnderline>
        <StyledFaqImage>
          <img src="../icon-shootouts.png" alt="" />
        </StyledFaqImage>
        <StyledTitleUnderline>Rugnummer (opgestelde speler)</StyledTitleUnderline>
        <Number checked={true}>12</Number>
        <StyledTitleUnderline>Geen rugnummer bekend (opgestelde speler)</StyledTitleUnderline>
        <Number checked={true}>...</Number>
        <StyledTitleUnderline>Rugnummer (niet aanpasbaar)</StyledTitleUnderline>
        <StyledNumber checked={true}>12</StyledNumber>
        <StyledTitleUnderline>Rugnummer (niet opgestelde speler)</StyledTitleUnderline>
        <Number checked={false} />
        <StyledTitleUnderline>Invaller</StyledTitleUnderline>
        <StyledSubstituteIcon />
        <StyledTitleUnderline>Aanpassen informatie (potloodje)</StyledTitleUnderline>
        <StyledPencil />
        <StyledTitleUnderline>Kaart (in de juiste kleur)</StyledTitleUnderline>
        <FlexWrapper>
          <CardWrapper>
            <YellowCard />
          </CardWrapper>
          <CardWrapper>
            <RedCard />
          </CardWrapper>
        </FlexWrapper>
        <StyledTitleUnderline>Handleiding</StyledTitleUnderline>
        <InfoWrapper>
          <StyledInfo />
        </InfoWrapper>
        <StyledTitleUnderline>Bevestigingen scheidsrechters en coaches</StyledTitleUnderline>
        <StyledFaqImage>
          <img src="../icon-afronding.png" alt="" />
        </StyledFaqImage>
      </FaqWrapper>
    </Wrapper>
  </>
);

export default FaqLegend;
