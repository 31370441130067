import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { css, styled } from 'styled-components';
import { theme } from '../templates/ui';

const StyledPopover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.85);
  border: 0;
  z-index: 99;

  ${(props) =>
    !props.showAlert &&
    css`
      display: none;
      opacity: 0;
    `};
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${theme.color.gray.white};
  width: 90%;
  padding: ${theme.sizing.xxxxlarge} ${theme.sizing.large};
  border-radius: 3px;

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    width: 65%;
    height: 50%;
    padding: ${theme.sizing.xxxxlarge};
  }

  @media only screen and (min-width: ${theme.breakpoints.large}) {
    width: 50%;
    height: 50%;
    padding: ${theme.sizing.xxxxlarge};
  }
`;

const Alert = ({ className, onClose, showAlert, children }) => {
  const PopupRef = useRef(null);

  const onClickCapture = () => {
    enableBodyScroll(PopupRef.current);
    if (onClose instanceof Function) {
      onClose();
    }
  };

  useEffect(() => {
    if (showAlert) disableBodyScroll(PopupRef.current);
    else enableBodyScroll(PopupRef.current);
  }, [showAlert]);

  return (
    <StyledPopover
      ref={PopupRef}
      showAlert={showAlert}
      id="popover"
      onClickCapture={onClickCapture}
      className={className}
    >
      <Container className="container">{children}</Container>
    </StyledPopover>
  );
};

export default Alert;
