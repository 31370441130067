import { useQuery } from 'react-query';
import { RolesResponseType } from '../@types/Permissions';
import Client from '../api/Client';
import { dwf_api_endpoint } from '../config/api';

export const useRoles = (params: { matchId: string | null }, options?: { enabled: boolean }) => {
  const { matchId } = params;
  const query = useQuery(
    ['roles', matchId],
    async () => {
      return Client.get(
        `${dwf_api_endpoint}/matches/${matchId}/roles`,
        undefined,
        'match.show',
        'roles'
      ).then((res) => {
        return res?.data;
      }) as Promise<RolesResponseType>;
    },
    {
      ...options,
      select: (data: RolesResponseType) => {
        return data?.result || [];
      },
    }
  );

  return { ...query };
};
