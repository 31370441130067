import React, { Fragment } from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../contexts/MatchContext';
import { MatchTeam } from '../atoms/MatchTeam';
import { theme } from '../templates/ui';

const StyledMatches = styled.div`
  background-color: ${theme.color.gray.base};
`;

const WrapperTeam = styled.div`
  border-top: 1px dashed ${theme.color.gray.light};

  div:first-child {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
`;

const StyledAwayTeamWrapper = styled.div`
  padding-left: ${theme.sizing.xxxxlarge};
  padding-right: ${theme.sizing.medium};
  position: relative;
`;

const MatchTeams = ({
  homeTeam,
  awayTeam,
  homeScore,
  awayScore,
  homeShootoutScore,
  awayShootoutScore,
  matchGame,
  homeCoachApproved,
  awayCoachApproved,
  className,
}) => (
  <Fragment>
    <StyledMatches className={className}>
      <MatchTeam
        clubName={homeTeam.club.abbreviation}
        teamName={homeTeam.name_short}
        current={true}
        score={homeScore}
        higher={homeScore + homeShootoutScore > awayScore + awayShootoutScore}
        coachApproved={!!matchGame.home_team_approval || homeCoachApproved}
        matchGame={matchGame}
      />
      <StyledAwayTeamWrapper>
        <WrapperTeam>
          <MatchTeam
            clubName={awayTeam.club.abbreviation}
            teamName={awayTeam.name_short}
            arrow={true}
            score={awayScore}
            higher={awayScore + awayShootoutScore > homeScore + homeShootoutScore}
            coachApproved={!!matchGame.away_team_approval || awayCoachApproved}
            matchGame={matchGame}
          />
        </WrapperTeam>
      </StyledAwayTeamWrapper>
    </StyledMatches>
  </Fragment>
);

export default withMatch(MatchTeams);
