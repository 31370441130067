import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledFacilityFieldContainer = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: 0 ${theme.sizing.xlarge};
  background-color: ${theme.color.body.base};
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: ${theme.color.gray.lighter};
`;

const StyledFacilityFieldWrapper = styled.div`
  border-top: 1px solid ${theme.color.gray.lighter};
  padding: ${theme.sizing.large} 0;
`;

const StyledFacilityField = styled.div`
  display: inline-block;
`;

const StyledFacilitySubField = styled.div`
  display: inline-block;
  text-transform: uppercase;
`;

const StyledLabel = styled.label`
  font-weight: 700;
  color: ${theme.color.base.base};
`;

export const FacilityField = ({ FacilityField, FacilitySubField }) => (
  <StyledFacilityFieldContainer>
    <StyledFacilityFieldWrapper>
      <StyledFacilityField>
        <StyledLabel>Veld:</StyledLabel> {FacilityField}
      </StyledFacilityField>
      {!!FacilitySubField && <StyledFacilitySubField>, {FacilitySubField}</StyledFacilitySubField>}
    </StyledFacilityFieldWrapper>
  </StyledFacilityFieldContainer>
);
