import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { produce } from 'immer';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { styled } from 'styled-components';
import { COMMENT_TYPES } from '../../@types/CommentTypes';
import { store, update } from '../../api/comments';
import { withErrorHandler } from '../../contexts/ErrorContext';
import { withMatch } from '../../contexts/MatchContext';
import { RoleContext } from '../../contexts/RoleContext/RoleContext';
import { useMaxLength } from '../../hooks/useMaxLength';
import { useComments } from '../../queries/comments';
import { hasShootout } from '../../services/dwfOptions';
import { MatchConfirmationProvider } from '../../services/matchConfirmation';
import { scrolltoElementWhenMobileKeyboardAppears } from '../../services/scroll';
import { Button } from '../atoms/Button';
import { Container } from '../atoms/Container';
import ChevronRight from '../atoms/Icons/ChevronRight';
import { InputContainer } from '../atoms/InputContainer';
import { Label } from '../atoms/Label';
import { ScoreInput } from '../atoms/ScoreInput';
import { Option, Select } from '../atoms/Select';
import { Textarea } from '../atoms/Textarea';
import { Wrapper } from '../atoms/Wrapper';
import Alert from '../molecules/Alert';
import IconButton from '../molecules/IconButton';
import { theme } from '../templates/ui';

const StyledAlert = styled(Alert)`
  left: calc(0px -${theme.sizing.xlarge});

  .container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: ${theme.sizing.large};

    @media only screen and (min-width: ${theme.breakpoints.medium}) {
      padding-bottom: ${theme.sizing.xxxxlarge};
    }

    @media only screen and (min-width: ${theme.breakpoints.large}) {
      max-width: 340px;
    }
  }
`;

const StyledButtonOk = styled(IconButton)`
  margin-bottom: ${theme.sizing.large};
  width: auto;

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    margin-bottom: 0;
    margin-left: ${theme.sizing.large};
  }
`;

const StyledButtonCancel = styled(IconButton)`
  background-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};
  width: auto;

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    margin-right: ${theme.sizing.large};
  }

  &:hover,
  &:focus {
    background-color: ${theme.color.gray.alt};
  }
`;

const StyledButtonContainer = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  background-color: ${theme.color.gray.white};
  border-top: 0;
  padding-bottom: 0;
  border-bottom: none;

  @media only screen and (min-width: ${theme.breakpoints.medium}) {
    flex-direction: row;
  }
`;

const StyledTopBar = styled.div`
  position: relative;
  background-color: ${theme.color.gray.light};
`;

const StyledCloseButton = styled.button`
  background-color: ${theme.color.primary.base};
  display: inline-block;
  border: 0;
  vertical-align: middle;
  padding: ${theme.sizing.xlarge};
  outline: none;
  cursor: pointer;

  svg {
    fill: ${theme.color.body.base};
    height: 18px;
    width: 18px;
  }

  &:focus,
  &:hover {
    background-color: ${theme.color.primary.hover};
  }
`;

const StyledWrapper = styled.div`
  display: inline-block;
  padding: 0 ${theme.sizing.large};
`;

const StyledButtonWrapper = styled.div`
  text-align: right;
`;

const StyledTitle = styled.h3`
  color: ${theme.color.base.base};
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  font-size: ${theme.font.large};
`;

const StyledAddContainer = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - ${theme.sizing.xlarge});
  left: 100%;
  transform: translateX(${(props) => (props.containerOpen ? 'calc(-100%)' : '0')});
  top: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background-color: ${theme.color.gray.white};
  transition: transform 0.2s ease-in-out;
  z-index: 100;
`;

const AltWrapper = styled(Wrapper)`
  padding: ${theme.sizing.xlarge};
`;

const AltChevronRight = styled(ChevronRight)`
  fill: white;
`;

const StyledError = styled.p`
  color: ${theme.color.error.base};
`;

const CommentTextArea = styled(Textarea)`
  height: 50px;

  @media only screen and (min-height: 300px) {
    height: 100px;
  }

  @media only screen and (min-height: 400px) {
    height: 200px;
  }
`;

const MaxLengthIndicator = styled.div`
  color: ${(props) => (props.maxReached === true ? theme.color.error.base : 'inherit')};
  margin-bottom: ${theme.sizing.xlarge};
  text-align: right;
`;

const initialState = {
  comment: '',
  type: '',
  homeScore: null,
  awayScore: null,
  homeShootoutScore: null,
  awayShootoutScore: null,
  loading: false,
  errors: false,
  confirmAlertOpen: false,
};

const MaxLengthTextArea = React.forwardRef(function MaxLengthTextArea(props, ref) {
  const { remaining, maxReached } = useMaxLength({
    input: ref.current,
    value: props.value,
    maxLength: 200,
  });

  return (
    <div>
      <Label htmlFor="comment">Opmerking</Label>
      <p>
        Maximaal 200 karakters. Stuur indien nodig een nadere uitleg naar je wedstrijdsecretaris.
      </p>
      <CommentTextArea id="comment" ref={ref} {...props} maxReached={maxReached} />

      <MaxLengthIndicator maxReached={maxReached} data-testid="max-length-indicator">
        <strong>Resterend aantal karakters:</strong> {remaining}
      </MaxLengthIndicator>
    </div>
  );
});

const CommentsForm = (props) => {
  const [state, setState] = useState(initialState);

  // Get comment types
  const { data: commentTypes } = useComments();

  // Get current path
  const matchCommentsRoute = useRouteMatch('/matches/:matchId/comments');

  const { role } = useContext(RoleContext);
  const {
    comment,
    type,
    homeScore,
    awayScore,
    homeShootoutScore,
    awayShootoutScore,
    loading,
    errors,
    confirmAlertOpen,
  } = state;

  const ref = useRef(null);

  const commentToEdit = useMemo(() => {
    return props.matchGame.match_comments.filter((comment) => comment.id === props.editId)[0];
  }, [props.editId]);

  useEffect(() => {
    setState((prevState) => ({ ...prevState, ...commentToEdit }));
  }, [commentToEdit]);

  const addCommentContainerRef = React.createRef();

  // Empty the form on closing of the modal, as the form remains in the DOM.
  useEffect(() => {
    if (!props.containerOpen) {
      resetState();
    }
  }, [props.containerOpen]);

  const resetState = () => {
    setState(initialState);
  };

  const openConfirmAlert = async (_e) => {
    setState((prevState) => ({ ...prevState, confirmAlertOpen: true }));
  };

  const confirmOk = async (e, addMatchConfirmation) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const { approvalId, match: updatedMatch } = await addMatchConfirmation(props.currentTeamId);
    await handleOnSave(e, approvalId, true, updatedMatch);
    props.containerClose();
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const confirmCancel = async (e) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    await handleOnSave(e, null, true);
    setState((prevState) => ({ ...prevState, confirmAlertOpen: true }));
    props.containerClose();
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const hasError = (field) => {
    if (!errors) {
      return false;
    }

    return !!errors[field];
  };

  const getError = (field) => {
    if (!errors) {
      return false;
    }

    return errors[field][0];
  };

  const handleOnSave = async (e, approvalId, isChangeRequest, matchToUpdate = props.matchGame) => {
    if (props.editId === null) {
      await handleAddComment(approvalId, isChangeRequest, matchToUpdate);
    } else {
      await handleEditComment(matchToUpdate);
    }
  };

  const handleEditComment = async (matchToUpdate = props.matchGame) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      await update(
        matchToUpdate.uuid,
        props.editId,
        {
          comment: comment,
          as_person_role_id: role.role_id,
        },
        'match-comments'
      );

      const match_comments = [...matchToUpdate.match_comments];
      const updatedComment = {
        ...match_comments.filter((comment) => comment.id === props.editId)[0],
      };

      const index = match_comments.map((e) => e.id).indexOf(updatedComment.id);

      updatedComment['comment'] = comment;

      match_comments[index] = updatedComment;

      updateMatchComments(match_comments, matchToUpdate);

      closeAddComments();
    } catch (e) {
      if (e.response) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: e.response.data.errors,
        }));
      }

      props.showGenericError(e);
    }
  };

  const handleAddComment = async (approvalId, isChangeRequest, matchToUpdate = props.matchGame) => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const response = await store(
        matchToUpdate.uuid,
        {
          comment: comment,
          type: type,
          home_score: parseInt(homeScore),
          away_score: parseInt(awayScore),
          home_shootout_score: parseInt(homeShootoutScore),
          away_shootout_score: parseInt(awayShootoutScore),
          team_id: role.team_id ?? null,
          as_person_role_id: role.role_id,
          approval_id: approvalId,
          is_change_request: isChangeRequest,
        },
        'match-comments'
      );

      const match_comments = [...matchToUpdate.match_comments];
      match_comments.unshift(response.data.createMatchComment);

      updateMatchComments(match_comments, matchToUpdate);

      closeAddComments();
    } catch (e) {
      if (e.response) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: e.response.data.errors,
        }));
      }

      props.showGenericError(e);
    }
  };

  const updateMatchComments = (matchComments, matchToUpdate = props.match) => {
    const match = produce(matchToUpdate, (draft) => {
      draft.match_comments = [...matchComments];
    });

    props.updateMatchGame(match, null, 'match-comments');
  };

  const closeAddComments = () => {
    resetState();
    props.history.push(`${matchCommentsRoute.url}`);
  };

  const getSaveButton = () => {
    const buttonText = (() => {
      if (props.history.location.state?.isChangeRequest && !props.canConfirmMatch) {
        return 'Wijziging aanvragen';
      }

      if (props.history.location.state?.isChangeRequest) {
        return 'Opslaan';
      }

      return 'Opslaan';
    })();

    return (
      <Button data-testid="comments-save-button" disabled={loading}>
        {buttonText}
      </Button>
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!props.canConfirmMatch && props.history.location.state?.isChangeRequest) {
      return handleOnSave(e, undefined, true);
    }

    if (!!props.history.location.state?.isChangeRequest) {
      return openConfirmAlert();
    }

    return handleOnSave(e);
  };

  // COMPONENT
  return (
    <StyledAddContainer containerOpen={props.containerOpen} ref={addCommentContainerRef}>
      <StyledTopBar>
        <StyledCloseButton
          addContainerOpen={props.containerOpen}
          onClick={() => props.history.push(props.match?.url)}
          data-testid="close-comment-add-screen"
        >
          <AltChevronRight />
        </StyledCloseButton>
        <StyledWrapper>
          <StyledTitle>
            {!!props.history.location.state?.isChangeRequest
              ? 'Wijziging aanvragen'
              : props.editId !== null
                ? 'Opmerking bewerken'
                : 'Opmerking toevoegen'}
          </StyledTitle>
          <MatchConfirmationProvider>
            {(matchConfirmationProps) => {
              return (
                <StyledAlert showAlert={confirmAlertOpen}>
                  <StyledTitle>Wil je de wedstrijd direct afronden?</StyledTitle>
                  <StyledButtonContainer>
                    <StyledButtonCancel
                      disabled={loading}
                      onClick={confirmCancel}
                      data-testid="no-button"
                    >
                      Nee
                    </StyledButtonCancel>
                    <StyledButtonOk
                      onClick={(e) => {
                        confirmOk(e, matchConfirmationProps.addMatchConfirmation);
                      }}
                      disabled={loading}
                      data-testid="yes-button"
                    >
                      Ja
                    </StyledButtonOk>
                  </StyledButtonContainer>
                </StyledAlert>
              );
            }}
          </MatchConfirmationProvider>
        </StyledWrapper>
      </StyledTopBar>
      <AltWrapper>
        {hasError('comment') && <StyledError>{getError('comment')}</StyledError>}

        <form
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <Label htmlFor="subject">Onderwerp</Label>
          <Select
            id="subject"
            noIcon
            required
            value={type}
            onChange={(e) => {
              const type = e.target.value;
              setState((prevState) => ({ ...prevState, type }));
            }}
            disabled={props.editId !== null}
            data-testid="comment-type-select"
          >
            <Option value="">Selecteer een onderwerp</Option>
            {commentTypes?.map((commentType) => (
              <Option key={commentType.name} value={commentType.name}>
                {commentType.label}
              </Option>
            ))}
          </Select>

          {type === COMMENT_TYPES.SCORE && (
            <>
              <Label>Score</Label>
              <InputContainer>
                <ScoreInput
                  placeholder="Thuis"
                  value={homeScore}
                  onChange={(e) => {
                    const homeScore = e.target.value;
                    setState((prevState) => ({ ...prevState, homeScore }));
                  }}
                  disabled={props.editId !== null}
                  required
                  min="0"
                  max="99"
                  data-testid="commentHomeScoreInput"
                  id="homeScore"
                />
                <ScoreInput
                  placeholder="Uit"
                  value={awayScore}
                  onChange={(e) => {
                    const awayScore = e.target.value;
                    setState((prevState) => ({ ...prevState, awayScore }));
                  }}
                  disabled={props.editId !== null}
                  required
                  min="0"
                  max="99"
                  data-testid="commentAwayScoreInput"
                  id="awayScore"
                />
              </InputContainer>

              {hasShootout(props.matchGame.dwf_options) && (
                <>
                  <Label>Shootouts</Label>
                  <InputContainer>
                    <ScoreInput
                      placeholder="Thuis"
                      value={homeShootoutScore}
                      onChange={(e) => {
                        const homeShootoutScore = e.target.value;
                        setState((prevState) => ({
                          ...prevState,
                          homeShootoutScore,
                        }));
                      }}
                      disabled={props.editId !== null}
                      min="0"
                      max="99"
                      data-testid="commentHomeShootoutScoreInput"
                      id="homeShootoutScore"
                    />
                    <ScoreInput
                      placeholder="Uit"
                      value={awayShootoutScore}
                      onChange={(e) => {
                        const awayShootoutScore = e.target.value;
                        setState((prevState) => ({
                          ...prevState,
                          awayShootoutScore,
                        }));
                      }}
                      disabled={props.editId !== null}
                      min="0"
                      max="99"
                      data-testid="commentAwayShootoutScoreInput"
                      id="awayShootoutScore"
                    />
                  </InputContainer>
                </>
              )}
            </>
          )}

          <MaxLengthTextArea
            ref={ref}
            placeholder="Type een opmerking"
            data-testid="comment-text-area"
            value={comment}
            onChange={(e) => {
              const comment = e.target.value;
              setState((prevState) => ({ ...prevState, comment }));
            }}
            required
            onFocus={({ currentTarget }) => {
              scrolltoElementWhenMobileKeyboardAppears(
                currentTarget,
                addCommentContainerRef.current
              );
            }}
          />
          <StyledButtonWrapper>{getSaveButton()}</StyledButtonWrapper>
        </form>
      </AltWrapper>
    </StyledAddContainer>
  );
};

export default withErrorHandler(withMatch(withRouter(CommentsForm)));
