import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import Checkmark from '../Icons/Checkmark';

const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    position: relative;
    display: flex;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid ${theme.color.gray.light};
    border-radius: 3px;
    text-align: center;
    transition: 50ms all ease-in-out;
    background-color: ${theme.color.body.base};

    &:hover {
      cursor: pointer;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &::before {
      transition: all 50ms ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -${theme.sizing.xxlarge};
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }

    &::after {
      transition: opacity 50ms ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: 2px;
      z-index: 2;
    }

    span {
      margin-bottom: ${theme.sizing.xsmall};
      margin-left: 40px;
      font-size: ${theme.font.large};
    }
  }

  input[type='checkbox']:checked + label {
    background-color: ${theme.color.primary.base};
    border: 1px solid ${theme.color.primary.base};

    svg {
      opacity: 1;
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }

    &::after {
      opacity: 1;
    }
  }
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${theme.color.gray.white};
`;

export const CheckboxInline = ({ checkboxId, children, name, checked, onChange, classname }) => (
  <CheckboxWrapper classname={classname}>
    <input type="checkbox" id={checkboxId} name={name} checked={checked} onChange={onChange} />
    <label htmlFor={checkboxId}>
      <StyledCheckmark />
      <span>{children}</span>
    </label>
  </CheckboxWrapper>
);
