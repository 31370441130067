import React from 'react';
import { theme } from '../../templates/ui';

const AccountSelected = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.0141 19.5313C23.9729 18.9202 23.8512 17.4233 23.6034 16.7295C23.2836 15.8329 22.8898 15.4299 22.397 15.101C22.1549 14.9393 21.2552 14.5983 19.911 14.0966C18.6895 13.6403 17.3053 13.123 16.8087 12.8408C16.3604 12.5855 16.0146 11.4099 16.025 11.0427C16.0298 11.0252 16.0662 10.9447 16.3278 10.6584C16.8554 10.0819 17.5541 8.97304 17.8412 7.89998C18.2324 6.436 18.5325 3.4704 17.177 1.6444C16.4061 0.605906 15.2066 0.053511 13.611 0.00260452L13.4913 0L13.4498 0.00260452C11.8541 0.053511 10.6541 0.606143 9.88292 1.64487C8.52715 3.47182 8.82798 6.43648 9.21947 7.90045C9.5061 8.97257 10.2027 10.08 10.7288 10.6551C10.7322 10.6586 10.7357 10.6622 10.739 10.6657C10.7395 10.6665 10.7402 10.6672 10.7409 10.6679C10.8183 10.7526 10.9996 10.9513 11.0384 11.0607C11.1781 11.4547 10.6706 12.6021 10.2519 12.8408C9.75629 13.1228 8.37141 13.6399 7.13233 14.1028C5.79716 14.6016 4.90365 14.9405 4.66317 15.101C4.17062 15.4294 3.777 15.8319 3.45699 16.7291C3.20729 17.4268 3.08539 18.9349 3.04444 19.5507L3.03308 19.7221L3.15758 19.8405C3.51995 20.185 5.33916 21.3097 13.5301 21.3097C21.6552 21.3097 23.5192 20.1732 23.8969 19.8251L24.0257 19.7063L24.0141 19.5313Z"
      fill={theme.color.primary.base}
    />
    <rect
      x="0.5"
      y="12.5"
      width="12"
      height="12"
      rx="6"
      fill="url(#paint0_linear_121_963)"
      stroke="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57768 19.6799L9.73332 15.5243C9.81812 15.4395 9.95947 15.4395 10.0443 15.5243L10.5531 16.0331C10.6379 16.1179 10.6379 16.2593 10.5531 16.3441L5.72846 21.1688C5.64367 21.2535 5.51172 21.2535 5.42693 21.1688L3.08992 18.8317C3.01454 18.7564 3.01454 18.615 3.08992 18.5302L3.60819 18.0214C3.69298 17.9366 3.83434 17.9366 3.90972 18.0214L5.57757 19.6798L5.57768 19.6799Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_121_963"
        x1="6.5"
        y1="12"
        x2="6.5"
        y2="25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={theme.color.secondary.base} />
        <stop offset="1" stopColor="#68B831" />
      </linearGradient>
    </defs>
  </svg>
);

export default AccountSelected;
