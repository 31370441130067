import React, { useContext } from 'react';
import { produce } from 'immer';
import moment from 'moment-timezone';
import { useRouteMatch, withRouter } from 'react-router-dom';
import { styled } from 'styled-components';
import { destroy } from '../../api/comments';
import { getIdToken } from '../../api/sso';
import { withErrorHandler } from '../../contexts/ErrorContext';
import { withMatch } from '../../contexts/MatchContext';
import { RoleContext } from '../../contexts/RoleContext/RoleContext';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useComments } from '../../queries/comments';
import { fullName } from '../../services/person';
import { Comment } from '../atoms/Comments';
import { theme } from '../templates/ui';

const StyledOrderedList = styled.ol`
  list-style: none;
  padding: 0;

  li {
    &:nth-child(odd) {
      background-color: ${theme.color.gray.base};
    }

    &:nth-child(even) {
      background-color: ${theme.color.gray.white};
    }
  }
`;

const CommentsList = ({ matchGame, history, updateMatchGame }) => {
  const { role } = useContext(RoleContext);

  const { canEditAllComments, canAddComments } = useHasPermission();

  // Get comment types
  const { data: commentTypes } = useComments();

  // Get current path
  let matchCommentsRoute = useRouteMatch('/matches/:matchId/comments');

  const openEditComment = (commentId) => {
    const comments = matchGame.match_comments.filter((comment) => comment.id === commentId);

    if (comments.length === 0) return;
    const comment = comments[0];

    history.push(`${matchCommentsRoute.url}/new`, {
      editCommentId: comment.id,
    });
  };

  const onDeleteComment = async (commentId) => {
    const confirm = window.confirm('Weet je zeker dat je deze opmerking wilt verwijderen?');
    if (!confirm) {
      return;
    }
    try {
      await destroy(
        matchGame.uuid,
        commentId,
        {
          as_person_role_id: role.role_id,
        },
        'match-comments'
      );

      const match_comments = matchGame.match_comments.filter((comment) => comment.id !== commentId);

      updateMatchComments(match_comments);
    } catch (e) {
      alert(
        e.response.data.errors.comment[0] ||
          'Er is iets fout gegaan tijdens het verwijderen van de opmerking, probeer het later nogmaals.'
      );
    }
  };

  const updateMatchComments = async (matchComments) => {
    const match = produce(matchGame, (draft) => {
      draft.match_comments = matchComments;
    });

    updateMatchGame(match, null, 'match-comments');
  };

  const renderComment = (comment) => {
    const commentType = commentTypes?.find((commentType) => commentType.name === comment.type);

    return (
      <li key={comment.id}>
        <Comment
          id={comment.id}
          showActions={shouldShowCommentActions(comment)}
          onEdit={openEditComment}
          onDelete={onDeleteComment}
          CommentTitle={commentTitle(comment)}
          CommentIsChangeRequest={comment.has_match_approval || comment.is_change_request}
          CommentTimeStamp={moment(comment.created_at)
            .tz('Europe/Amsterdam')
            .format('DD-MM-YYYY - HH:mm')}
          CommentBody={comment.comment}
          CommentType={commentType}
          HomeScore={comment.home_score}
          HomeShootoutScore={comment.home_shootout_score}
          AwayScore={comment.away_score}
          AwayShootoutScore={comment.away_shootout_score}
        />
      </li>
    );
  };

  const shouldShowCommentActions = (comment) => {
    const isCommentWriter =
      comment.person.federation_reference_id === getIdToken().federation_reference_id;

    // Check if user has permissions to add comments
    const canEditOwnComment = isCommentWriter && canAddComments;

    return canEditOwnComment || canEditAllComments;
  };

  const commentTitle = (comment) => {
    let clubName = '';
    if (comment.team && comment.team.club) {
      clubName = comment.team.club.name ? `(${comment.team.club.name})` : ``;
      if (
        comment.team.club.id === matchGame.home_team.club.id &&
        comment.team.club.id === matchGame.away_team.club.id
      ) {
        clubName = '';
      }
    }

    let personRole = '';
    if (comment.person_role) {
      personRole = '- ';
      if (comment.person_role.role_category.id === 48) {
        personRole += 'Scheidsrechter';
      } else {
        personRole += comment.person_role.role_category.name;
      }
    }

    return (
      <div>
        <span data-testid={`comment-${comment.id}-name`}>{fullName(comment.person)}</span>
        {personRole != '' && <span data-testid={`comment-${comment.id}-role`}>{personRole}</span>}
        {clubName != '' && <span data-testid={`comment-${comment.id}-club-name`}>{clubName}</span>}
      </div>
    );
  };

  return (
    <>
      <StyledOrderedList>
        {matchGame.match_comments?.map((comment) => renderComment(comment))}
      </StyledOrderedList>
    </>
  );
};

export default withErrorHandler(withMatch(withRouter(CommentsList)));
