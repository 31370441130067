import React from 'react';
import { Redirect } from 'react-router-dom';
import { setAuthRedirectUrl } from '../../services/localStorage';

interface Props {
  children: any;
  loggedIn: boolean;
}

const PrivateRoute = ({ children, loggedIn, ...otherProps }: Props) => {
  if (!loggedIn) {
    setAuthRedirectUrl();

    return <Redirect to="/login" />;
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, otherProps);
    }

    return child;
  });

  return childrenWithProps;
};

export default PrivateRoute;
