import React from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';
import { HockeyStickAnimation } from '../../atoms/HockeyStickAnimation';
import ChevronRight from '../../atoms/Icons/ChevronRight';
import IconButton from '../../molecules/IconButton';
import TopBar from '../../molecules/TopBar';
import { Layout } from '../../templates/Layout';
import { theme } from '../../templates/ui';

const StyledTitle = styled.h2`
  color: ${theme.color.base.base};
  font-size: ${theme.font.xxxlarge};
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.sizing.xlarge};
  text-align: center;
  overflow: hidden;
`;

const ErrorAnimationWrapper = styled.div`
  position: relative;
  min-width: 250px;
  min-height: 250px;
  margin-bottom: 40px;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${theme.color.primary.base};

  &:hover {
    background-color: ${theme.color.primary.hover};
  }

  svg {
    fill: ${theme.color.body.base};
  }
`;

const ButtonWrapper = styled.div`
  margin: ${theme.sizing.xxxxlarge} ${theme.sizing.xlarge} 0;
`;

const StyledLayout = styled(Layout)`
  overflow: hidden;
`;

export const Page404 = (props) => {
  const history = useHistory();

  return (
    <StyledLayout>
      <Helmet>
        <title>Digitaal Wedstrijd Formulier - Pagina niet gevonden</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <TopBar back={props.back} hideActions={false}>
        Error
      </TopBar>
      <Wrapper>
        <div>
          <ErrorAnimationWrapper>
            <HockeyStickAnimation />
          </ErrorAnimationWrapper>
          <StyledTitle>Niet gevonden</StyledTitle>
          <ButtonWrapper>
            <StyledIconButton
              onClick={() => {
                history.push('/matches');
              }}
              data-testid="back-to-overview"
            >
              Wedstrijdoverzicht
              <ChevronRight />
            </StyledIconButton>
          </ButtonWrapper>
        </div>
      </Wrapper>
    </StyledLayout>
  );
};
