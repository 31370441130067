import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import 'react-tabs/style/react-tabs.css';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../../@types/Permissions';
import {
  confirmMatchSuperUser,
  deleteMatchConfirmation,
  getMatchConfirmationEndpoint,
  getMatchInfo,
} from '../../../api/matches';
import { ERROR_CODE_MATCH_ALREADY_CONFIRMED_BY_ROLE } from '../../../config/errorCodes';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { RolesContext } from '../../../contexts/RolesContext/RolesContext';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useHasRole } from '../../../hooks/useHasRole';
import { useHasTeam } from '../../../hooks/useHasTeam';
import { useMatch } from '../../../hooks/useMatch';
import { refereeCanDeleteMatchApproval } from '../../../services/approval';
import { hasCards } from '../../../services/dwfOptions';
import {
  getApprovalColumnForUserByRole,
  hasSecretaryConfirmationActions,
  isConfirmedBySelf,
  matchCanHaveCards,
  matchIsConfirmedByUser,
} from '../../../services/match';
import {
  AwayCoachType,
  AwayYouthCoachType,
  HomeCoachType,
  HomeYouthCoachType,
  RefereeType,
} from '../../../services/matchConfirmation';
import { getActiveTeamPlayersCount } from '../../../services/players';
import { coachCanEnterScore, getPersonType } from '../../../services/user';
import { AuthorizedCan } from '../../atoms/AuthorizedCan';
import { AuthorizedGuard } from '../../atoms/AuthorizedGuard';
import { Button } from '../../atoms/Button';
import { Container } from '../../atoms/Container';
import { FacilityField } from '../../atoms/FacilityField';
import { FormItem } from '../../atoms/FormItem';
import Checkmark from '../../atoms/Icons/Checkmark';
import ChevronRight from '../../atoms/Icons/ChevronRight';
import Loader from '../../atoms/Icons/Loader';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import { Wrapper } from '../../atoms/Wrapper';
import ButtonContainer from '../../molecules/ButtonContainer';
import IconButton from '../../molecules/IconButton';
import MatchCancellation from '../../molecules/MatchCancellation';
import MatchInfoPopup from '../../molecules/MatchInfoPopup';
import MatchMeta from '../../molecules/MatchMeta';
import MatchTeams from '../../molecules/MatchTeams';
import TopBar from '../../molecules/TopBar';
import Announcements from '../../organisms/Announcements';
import Cards from '../../organisms/Cards';
import MatchHeader from '../../organisms/MatchHeader';
import ModalMatchConfirmation from '../../organisms/ModalMatchConfirmation';
import Playerlist from '../../organisms/Playerlist';
import { theme } from '../../templates/ui';
import { Cancellation } from '../Cancellation';
import { Questions } from '../Questions';
import { Substitute } from '../Substitute';
import { TeamSupport } from '../TeamSupport';

const StyledTeams = styled(MatchTeams)`
  position: fixed;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  background-color: ${theme.color.body.base};
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: ${theme.color.gray.lighter};
  padding: 0 7px;
  border-bottom: 1px solid ${theme.color.gray.light};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  z-index: 4;
`;

const StyledCancelMatchContainer = styled(Container)`
  background-color: ${theme.color.gray.white};
  border: 0;
  padding: 0;
  display: flex;
  margin-bottom: 100px; /* magic number for now, should be the height of the button bar + some margin */
`;

const StyledTeamsPush = styled.div`
  position: relative;
`;

const FooterPushBar = styled.div`
  position: relative;
  height: 56px;
`;

const StyledMatchMeta = styled(MatchMeta)`
  padding: ${theme.sizing.large} ${theme.sizing.xlarge};
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

const StyledIconButton = styled(IconButton)`
  background-color: ${theme.color.primary.base};

  &:hover {
    background-color: ${theme.color.primary.hover};
  }

  svg {
    fill: ${theme.color.body.base};
  }
`;

const MatchInfoButton = styled(IconButton)`
  background-color: ${theme.color.secondary.base};

  &:hover {
    background-color: ${theme.color.secondary.hover};
  }

  svg {
    fill: ${theme.color.body.base};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: ${theme.color.gray.darkest};
`;

const StyledRoleSwitchLabel = styled.div`
  font-weight: normal;
`;

const StyledButtonAddPerson = styled(IconButton)`
  background-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};
  width: auto;
  padding-left: 40px;
  margin-left: auto;

  svg {
    left: 0.75rem;
  }

  &:hover {
    background-color: ${theme.color.gray.light};
  }
`;

const StyledButton = styled(Button)`
  padding: ${theme.sizing.large};

  &:first-child {
    margin-left: 0;
  }
`;

const ConfirmationLoader = styled(Loader)`
  height: ${theme.sizing.xlarge};
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${theme.color.gray.white};
`;

const StyledMatchWrapper = styled.div`
  position: relative;
`;

const StyledCancellationOverlay = styled.div`
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
`;

const StyledConfirmationButton = styled(Button)`
  transition: all 0.2s ease-in-out;
  transition-delay: 0.4s;
  @keyframes pulse {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
  }
  ${({ pulsing }) =>
    pulsing &&
    `
    animation-name: pulse;
    animation-duration: 0.4s;
    animation-delay: 0.4s;
    animation-iteration-count: 2;
  `};
`;

const StyledChangeRequestButton = styled(Button)`
  background-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background-color: ${theme.color.gray.alt};
  }
`;

const StyledAnnouncements = styled(Announcements)`
  padding-top: ${theme.sizing.xlarge};
  padding-bottom: ${theme.sizing.xlarge};
`;

export const MatchDetail = ({
  match: matchGame,
  homeTeamPlayers,
  awayTeamPlayers,
  toggleMatchPlayer,
  handleJerseyNumberChange,
  onScoreUpdate,
  onDeleteMatchConfirmation,
  onMatchConfirmation,
  announcements,
  user,
  routerMatch,
  onUncancelMatch,
  pushingData,
}) => {
  const { role, hasPermissions, permissions, team_id } = useContext(RoleContext);

  const [state, setState] = useState({
    confirmationModalVisible: false,
    infoModalVisible: false,
    confirmationLoading: false,
    deleteConfirmationLoading: false,
    confirmationButtonPulsing: false,
    matchInfo: null,
    loading: false,
    currentTeam:
      team_id === matchGame?.away_team.id ? matchGame?.away_team.id : matchGame?.home_team.id,
  });

  const { isSecretary, isCoach, isReferee, isSuperUser } = useHasRole();

  const { isCoachOfTeam, isTeam } = useHasTeam();

  const {
    canCancelMatch,
    canUnCancelMatch,
    canConfirmPlayers,
    canConfirmMatch,
    canSeeQuestions,
    canAddCards,
    canManageTeamSupport,
  } = useHasPermission();

  const {
    hasStarted,
    hasScore,
    isConfirmedByReferee,
    isClosed,
    isCancelled,
    showShootoutsAreRequiredNotification,
  } = useMatch();

  const { setRoleSwitchModalActive, roles: availableRoles } = useContext(RolesContext);

  // ------- Effects -------

  useEffect(() => {
    const confirmationDisabled = isConfirmationDisabled();
    if (confirmationDisabled === state.confirmationButtonPulsing) {
      setState((prevState) => ({
        ...prevState,
        confirmationButtonPulsing: !confirmationDisabled,
      }));
    }
  }, []);

  // ------- Generic utilities -------

  const maxScore = (score) => Math.min(score, 100);

  const getDisplayScoreForTeams = (score) => {
    return !score ? score : maxScore(score);
  };

  const getDisplayScoreForHeader = (score) => {
    return Number.isInteger(score) ? maxScore(score) : '';
  };

  const getApprovalColumnForRole = (role) => {
    return getApprovalColumnForUserByRole(matchGame, user, state.currentTeam, role);
  };

  const isChangeRequestButtonDisabled = () => {
    if (
      !getApprovalColumnForRole({
        role,
        permissions,
        team_id,
      })
    ) {
      return false;
    }

    if (pushingData || !hasStarted || !hasScore) {
      return true;
    }

    return state.confirmationLoading;
  };

  const isConfirmationDisabled = () => {
    const approvalColumnForRole = getApprovalColumnForUserByRole(matchGame, user, team_id, {
      role,
      permissions,
      team_id,
    });

    if (pushingData || !hasStarted || !hasScore) {
      return true;
    }

    if (
      !approvalColumnForRole ||
      (!isConfirmedBySelf(matchGame, user) &&
        !['home_team_approval', 'away_team_approval'].includes(approvalColumnForRole))
    ) {
      return false;
    }

    return state.confirmationLoading || matchGame[approvalColumnForRole];
  };

  // ------- Event handlers -------

  const onChangeTeam = (teamId) => {
    setState((prevState) => ({ ...prevState, currentTeam: teamId }));
  };

  // ------- Match confirmation methods -------
  const openConfirmationModal = () => {
    setState((prevState) => ({
      ...prevState,
      confirmationModalVisible: true,
    }));
  };

  const closeConfirmationModal = () => {
    setState((prevState) => ({
      ...prevState,
      confirmationModalVisible: false,
    }));
  };

  const getMatchConfirmationMessage = () => {
    let have = ' heeft ',
      text = '';

    // Message indicating teams have not yet updated playerlist
    if (matchGame.dwf_options.players) {
      if (!getActiveTeamPlayersCount(homeTeamPlayers)) {
        text += `${matchGame.home_team.name} `;
      }

      if (!getActiveTeamPlayersCount(awayTeamPlayers)) {
        if (text) {
          text += 'en ';
          have = 'hebben';
        }
        text += `${matchGame.away_team.name}`;
      }

      if (text) {
        text = `${text} ${have} de spelerslijst nog niet ingevuld.\n`;
      }
    }

    // Message indicating the currently filled in score
    text += `Is de score correct ingevuld? (${matchGame.home_team.name} - ${
      matchGame.away_team.name
    }: ${maxScore(matchGame.home_score)}  - ${maxScore(matchGame.away_score)})\n`;

    // Message indicating the shootout score
    if (
      matchGame.dwf_options.shootouts === 1 &&
      maxScore(matchGame.home_score) === maxScore(matchGame.away_score)
    ) {
      text += `Is de shootout score correct ingevuld? (${matchGame.home_team.name} - ${
        matchGame.away_team.name
      }: ${maxScore(matchGame.home_shootout_score)}  - ${maxScore(
        matchGame.away_shootout_score
      )})\n`;
    }

    // Message indicating no cards have been entered
    if (matchGame.match_cards.length === 0 && hasCards(matchGame.dwf_options)) {
      text += `Er zijn geen kaarten ingevuld, is dit juist?\n`;
    }

    // Final check
    if (text !== '') {
      text += 'Weet je zeker dat je het formulier wilt afronden?';
    }

    return text;
  };

  // ------- Confirmation methods -------

  const handleDeleteMatchConfirmation = async () => {
    if (
      !refereeCanDeleteMatchApproval(user, matchGame, {
        role,
        permissions,
        team_id,
      })
    ) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      deleteConfirmationLoading: true,
    }));

    try {
      await deleteMatchConfirmation({
        matchUuid: matchGame.uuid,
        role,
        pageKey: 'match-detail',
      });
      onDeleteMatchConfirmation();
    } catch (error) {
      window.alert(
        'Je kunt je afronding niet meer ongedaan maken, je kunt nog wel een wijzigingsverzoek insturen.'
      );

      return window.location.reload();
    } finally {
      setState((prevState) => ({
        ...prevState,
        deleteConfirmationLoading: false,
      }));
    }
  };

  const handleMatchConfirmation = async () => {
    setState((prevState) => ({ ...prevState, confirmationLoading: true }));

    let personType;

    const isHomeCoach =
      state.currentTeam === matchGame.home_team.id && isCoachOfTeam(matchGame.home_team.id);
    const isAwayCoach =
      state.currentTeam === matchGame.away_team.id && isCoachOfTeam(matchGame.away_team.id);
    const isYouthCoach = hasPermissions([PERMISSIONS.MANAGE_TEAM, PERMISSIONS.APPROVE_AS_UMPIRE], {
      policy: 'all',
      teamId: state.currentTeam,
    });

    if (isHomeCoach) {
      personType = isYouthCoach ? HomeYouthCoachType : HomeCoachType;
    } else if (isAwayCoach) {
      personType = isYouthCoach ? AwayYouthCoachType : AwayCoachType;
    } else {
      personType = getPersonType(user, matchGame, undefined, {
        role,
        permissions,
        team_id,
      });
    }

    if (personType === RefereeType || coachCanEnterScore(user, matchGame)) {
      if (showShootoutsAreRequiredNotification) {
        window.alert(
          'Deze wedstrijd is in een gelijkspel geëindigd. Om de wedstrijd te kunnen afronden moet de shoot-out score nog worden ingevuld.'
        );
        setState((prevState) => ({
          ...prevState,
          confirmationLoading: false,
        }));

        return;
      }

      const confirm = window.confirm(getMatchConfirmationMessage());
      if (!confirm) {
        setState((prevState) => ({
          ...prevState,
          confirmationLoading: false,
        }));

        return;
      }
    }

    const confirmationMethod = getMatchConfirmationEndpoint(personType);

    try {
      await confirmationMethod({
        matchUuid: matchGame.uuid,
        personType,
        role,
        teamId: team_id ?? null,
        pageKey: 'match-detail',
      });

      const updatedMatch = onMatchConfirmation(personType);

      updatedMatch(updatedMatch);
    } catch (e) {
      handleMatchConfirmationError(e);
    }
    setState((prevState) => ({ ...prevState, confirmationLoading: false }));
  };

  // A match needs 2 approvals by referees, so we need to call this two times.
  const handleMatchConfirmationAsTwoReferees = async () => {
    const updatedMatchWithOneConfirmation = await handleMatchConfirmationAs(RefereeType);
    const updatedMatchWithTwoConfirmations = await handleMatchConfirmationAs(
      RefereeType,
      updatedMatchWithOneConfirmation
    );

    return updatedMatchWithTwoConfirmations;
  };

  const handleMatchConfirmationAsCoach = async (matchToUpdate) => {
    const isAway = isTeam(matchGame.away_team.id);

    const updatedMatchWithCoachConfirmation = await handleMatchConfirmationAs(
      isAway ? AwayCoachType : HomeCoachType,
      matchToUpdate
    );

    return updatedMatchWithCoachConfirmation;
  };

  const userIsPartOfMatch = () => {
    const away = isTeam(matchGame.away_team.id);
    const home = isTeam(matchGame.home_team.id);

    return home || away;
  };

  const handleMatchConfirmationAsSecretary = async () => {
    // Update match with two confirmations
    const updatedMatchWithTwoConfirmations = await handleMatchConfirmationAsTwoReferees();

    // Update match with home coach confirmation
    if (userIsPartOfMatch(user)) {
      await handleMatchConfirmationAsCoach(updatedMatchWithTwoConfirmations);
      closeConfirmationModal();
    }
  };

  const handleMatchConfirmationAsAll = async () => {
    // Update match with two confirmations
    const updatedMatchWithTwoConfirmations = await handleMatchConfirmationAsTwoReferees();

    //  Update match with home coach approval
    if (!updatedMatchWithTwoConfirmations.home_team_approval) {
      const updatedMatchWithHomeCoachApproval = await handleMatchConfirmationAs(
        HomeCoachType,
        updatedMatchWithTwoConfirmations
      );

      // Update match with away coach
      if (!updatedMatchWithHomeCoachApproval.away_team_approval) {
        await handleMatchConfirmationAs(AwayCoachType, updatedMatchWithHomeCoachApproval);
        closeConfirmationModal();
      }
    }
  };

  const handleMatchConfirmationAs = async (personType, matchToUpdate = matchGame) => {
    const confirmationMethod = getMatchConfirmationEndpoint(personType);
    let teamId;

    switch (personType) {
      case 'away_coach':
        teamId = matchToUpdate.away_team.id;
        break;
      case 'home_coach':
        teamId = matchToUpdate.home_team.id;
        break;
    }

    try {
      await confirmationMethod({
        matchUuid: matchToUpdate.uuid,
        personType,
        role,
        teamId,
        pageKey: 'match-detail',
      });

      const updatedMatch = await onMatchConfirmation(personType, matchToUpdate);

      if (
        isSecretary &&
        !hasSecretaryConfirmationActions(matchToUpdate, {
          role,
          permissions,
          team_id,
        })
      ) {
        closeConfirmationModal();
      }

      return updatedMatch;
    } catch (e) {
      handleMatchConfirmationError(e);
    }
  };

  const handleMatchConfirmSuperuser = async () => {
    try {
      await confirmMatchSuperUser({
        matchUuid: matchGame.uuid,
        role,
        pageKey: 'match-detail',
      });
      onMatchConfirmation('superuser');
      closeConfirmationModal();
    } catch (e) {
      handleMatchConfirmationError(e);
    }
  };

  const handleMatchConfirmationError = (e) => {
    if (e.response?.data.code === ERROR_CODE_MATCH_ALREADY_CONFIRMED_BY_ROLE) {
      alert('De wedstrijd is al afgerond door deze rol.');
      window.location.reload();

      return;
    }

    if (e.response) {
      if (e.response.data && e.response.data.message) {
        alert(e.response.data.message);
      } else {
        alert('De wedstrijd kon niet bevestigd worden, probeer het later nogmaals.');
      }
    }
    closeConfirmationModal();
  };

  // ------- Dynamic components -------
  const getConfirmationButtonText = () => {
    if (isSecretary) {
      return 'Wedstrijdsecretaris acties uitvoeren';
    }

    if (matchIsConfirmedByUser(matchGame, user)) {
      return (
        <>
          Afgerond <StyledCheckmark />
        </>
      );
    }

    const approvalColumnForRole = getApprovalColumnForRole({
      role,
      permissions,
      team_id,
    });

    const matchConditions = !(matchGame.home_team_approval || matchGame.away_team_approval);

    // approve_as_umpire
    const refereeConditions = isReferee && isConfirmedBySelf(matchGame, user);

    // approve as_coach
    const coachConditions = isCoach && matchGame[approvalColumnForRole];

    if (matchConditions && (refereeConditions || coachConditions)) {
      return <>Afgerond</>;
    }

    if (isSuperUser) {
      return 'Superuser acties uitvoeren';
    }

    return 'Afronden';
  };

  const getConfirmationButton = (matchGame, history) => {
    const canDeleteMatchApproval = refereeCanDeleteMatchApproval(user, matchGame, {
      role,
      permissions,
      team_id,
    });

    const canOpenConfirmationModal = hasStarted && (isSuperUser || isSecretary);

    const hasSecretaryConfirmationActionsRights =
      isSecretary &&
      !hasSecretaryConfirmationActions(matchGame, {
        role,
        permissions,
        team_id,
      });

    if (canDeleteMatchApproval || canOpenConfirmationModal) {
      return (
        <>
          {canDeleteMatchApproval && (
            <StyledConfirmationButton
              onClick={() => handleDeleteMatchConfirmation()}
              small
              data-testid="delete-match-confirmation"
            >
              Afronding ongedaan maken
              {state.deleteConfirmationLoading && <ConfirmationLoader />}
            </StyledConfirmationButton>
          )}
          {canOpenConfirmationModal && !hasSecretaryConfirmationActionsRights && (
            <StyledButton
              small={true}
              onClick={() => {
                openConfirmationModal();
              }}
              disabled={pushingData || matchIsConfirmedByUser(matchGame, user)}
              data-testid="confirm_button"
            >
              {getConfirmationButtonText()}
            </StyledButton>
          )}
        </>
      );
    } else {
      return (
        <>
          {matchGame.referee_confirmation && (
            <StyledChangeRequestButton
              onClick={() =>
                history.push(`/matches/${matchGame.uuid}/comments/new`, {
                  isChangeRequest: true,
                })
              }
              name={'confirm_match'}
              disabled={isChangeRequestButtonDisabled()}
              small={true}
              pulsing={state.confirmationButtonPulsing}
              data-testid="change_request_button"
            >
              Wijziging aanvragen
              {state.confirmationLoading && <ConfirmationLoader />}
            </StyledChangeRequestButton>
          )}
          <StyledConfirmationButton
            onClick={() => handleMatchConfirmation()}
            name={'confirm_match'}
            disabled={isConfirmationDisabled()}
            small={true}
            pulsing={state.confirmationButtonPulsing}
            data-testid="confirm_match_button"
          >
            {getConfirmationButtonText()}
            {state.confirmationLoading && <ConfirmationLoader />}
          </StyledConfirmationButton>
        </>
      );
    }
  };

  // ------- The match detail page itself -------
  return (
    <>
      <Switch>
        {/* Cancel match route */}
        <Route
          exact
          path={`${routerMatch.url}/cancellation`}
          render={(props) => (
            <AuthorizedGuard
              has={[PERMISSIONS.CANCEL_MATCH]}
              teamId={state.currentTeam}
              redirect={routerMatch.url}
            >
              <Cancellation {...props} matchGame={matchGame} />
            </AuthorizedGuard>
          )}
        />
        {/* Cards route */}
        <Route
          exact
          path={`${routerMatch.url}/cards`}
          render={(props) => (
            <AuthorizedGuard
              has={[PERMISSIONS.VIEW_MATCH]}
              redirect={routerMatch.url}
              condition={matchCanHaveCards(matchGame)}
            >
              <Cards {...props} user={user} />
            </AuthorizedGuard>
          )}
        />
        {/* Questions route */}
        <Route
          exact
          path={`${routerMatch.url}/questions`}
          render={(props) => (
            <AuthorizedGuard
              has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
              policy="some"
              teamId={state.currentTeam}
              redirect={routerMatch.url}
            >
              <Questions {...props} match={matchGame} />
            </AuthorizedGuard>
          )}
        />
        {/* Substitutes route */}
        <Route
          exact
          path={`${routerMatch.url}/substitute`}
          render={(props) => (
            <AuthorizedGuard
              has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
              condition={!isClosed || hasPermissions([PERMISSIONS.CORRECT_AFTER_CLOSING])}
              policy="some"
              teamId={state.currentTeam}
              redirect={routerMatch.url}
            >
              <Substitute {...props} match={matchGame} user={user} />
            </AuthorizedGuard>
          )}
        />
        {/* Substitutes route */}
        <Route
          exact
          path={`${routerMatch.url}/teamsupport`}
          render={(props) => (
            <AuthorizedGuard
              has={[PERMISSIONS.MANAGE_ALL_TEAMS_SUPPORT]}
              redirect={routerMatch.url}
            >
              <TeamSupport {...props} match={matchGame} user={user} />
            </AuthorizedGuard>
          )}
        />
        {/* Match detail page */}
        <Route
          exact
          path={[
            `${routerMatch.url}`,
            `${routerMatch.url}/comments`,
            `${routerMatch.url}/comments/new`,
          ]}
          render={({ history, props }) => (
            <>
              <TopBar {...props} history={history}>
                Wedstrijd detail
              </TopBar>
              <SizeMe monitorHeight={true}>
                {({ size }) => (
                  <>
                    <StyledTeams
                      homeTeam={matchGame.home_team}
                      awayTeam={matchGame.away_team}
                      homeScore={getDisplayScoreForTeams(matchGame.home_score)}
                      homeShootoutScore={getDisplayScoreForTeams(matchGame.home_shootout_score)}
                      awayShootoutScore={getDisplayScoreForTeams(matchGame.away_shootout_score)}
                      awayScore={getDisplayScoreForTeams(matchGame.away_score)}
                    />
                    <StyledTeamsPush style={{ height: size.height }} />
                  </>
                )}
              </SizeMe>

              {/* Show list of questions */}
              {matchGame.field_name && (
                <FacilityField
                  FacilityField={matchGame.field_name}
                  FacilitySubField={matchGame.subfield}
                />
              )}

              {/* Show match meta */}
              <StyledWrapper>
                <StyledMatchMeta
                  user={user}
                  date={matchGame.date}
                  matchCode={matchGame.match_code}
                  commentsCount={matchGame.match_comments.length}
                  cancellation={matchGame.match_cancellations}
                  approvals={matchGame.match_approvals}
                  currentTeamId={state.currentTeam}
                  canConfirmMatch={!isConfirmationDisabled()}
                />
              </StyledWrapper>

              {/* Show announcement */}
              {announcements.length > 0 && <StyledAnnouncements announcements={announcements} />}

              <StyledMatchWrapper>
                {matchGame.match_cancellations && matchGame.match_cancellations.length > 0 && (
                  <StyledCancellationOverlay />
                )}
                <MatchHeader
                  user={user}
                  homeTeam={matchGame.home_team}
                  awayTeam={matchGame.away_team}
                  homeScore={getDisplayScoreForHeader(matchGame.home_score)}
                  awayScore={getDisplayScoreForHeader(matchGame.away_score)}
                  homeShootoutScore={getDisplayScoreForHeader(matchGame.home_shootout_score)}
                  awayShootoutScore={getDisplayScoreForHeader(matchGame.away_shootout_score)}
                  onScoreUpdate={onScoreUpdate}
                />
                <Wrapper>
                  {/* Show cards actions/button */}
                  <AuthorizedCan
                    has={[PERMISSIONS.VIEW_MATCH]}
                    condition={hasStarted && matchCanHaveCards(matchGame)}
                  >
                    <FormItem>
                      <StyledIconButton
                        onClick={() => {
                          history.push(`/matches/${matchGame.uuid}/cards`);
                        }}
                        data-testid={canAddCards ? 'add_cards_button' : 'view_cards_button'}
                      >
                        {canAddCards && 'Kaarten toevoegen'}
                        {!canAddCards && 'Kaarten bekijken'}
                        <ChevronRight />
                      </StyledIconButton>
                    </FormItem>
                  </AuthorizedCan>

                  {/* Show match info button */}
                  <FormItem>
                    <MatchInfoButton
                      onClick={() => {
                        setState((prevState) => ({
                          ...prevState,
                          infoModalVisible: true,
                        }));
                        if (!!state.matchInfo) {
                          return;
                        }
                        setState((prevState) => ({
                          ...prevState,
                          loading: true,
                        }));
                        getMatchInfo(matchGame.uuid, null).then(({ data }) => {
                          setState((prevState) => ({
                            ...prevState,
                            matchInfo: data,
                            loading: false,
                          }));
                        });
                      }}
                      isLoading={state.loading}
                    >
                      Wedstrijdinformatie <ChevronRight />
                    </MatchInfoButton>
                  </FormItem>

                  {/* Show list of questions */}
                  <AuthorizedCan override={canSeeQuestions}>
                    <FormItem>
                      <StyledIconButton
                        onClick={() => {
                          history.push(`/matches/${matchGame.uuid}/questions`);
                        }}
                      >
                        Jeugd wedstrijd vragenlijst <ChevronRight />
                      </StyledIconButton>
                    </FormItem>
                  </AuthorizedCan>
                </Wrapper>

                {/* List of players in the game */}
                <AuthorizedCan
                  has={[PERMISSIONS.VIEW_TEAMS]}
                  condition={!!matchGame.dwf_options.players}
                >
                  <Playerlist
                    user={user}
                    match={matchGame}
                    homeTeamPlayers={homeTeamPlayers}
                    awayTeamPlayers={awayTeamPlayers}
                    onJerseyNumberChange={handleJerseyNumberChange}
                    toggleMatchPlayer={toggleMatchPlayer}
                    history={history}
                    onChangeTeam={onChangeTeam}
                  />
                </AuthorizedCan>

                {/* Cancel this match */}
                {canCancelMatch && (
                  <Wrapper>
                    <StyledCancelMatchContainer>
                      <MatchCancellation
                        label="Wedstrijd niet (uit)gespeeld?"
                        placeholder="Toelichting"
                        onUnCancelMatch={onUncancelMatch}
                      />
                    </StyledCancelMatchContainer>
                  </Wrapper>
                )}

                {/* Actions */}
                <ButtonContainer>
                  {/* More than 1 role available */}
                  {(availableRoles.length > 1 ||
                    (availableRoles.length === 1 && availableRoles[0].team_id?.length > 1)) && (
                    <Button
                      primary
                      small
                      data-testid="switch-roles-button"
                      onClick={() => {
                        setRoleSwitchModalActive(true);
                      }}
                    >
                      <StyledRoleSwitchLabel>{role.category.name}</StyledRoleSwitchLabel>
                      Wissel van rol
                    </Button>
                  )}

                  {/* Match is cancelled */}
                  {canUnCancelMatch && (
                    <MatchCancellation
                      label="Wedstrijd niet (uit)gespeeld?"
                      placeholder="Toelichting"
                      onUnCancelMatch={onUncancelMatch}
                    />
                  )}
                  {/* Match is not cancelled */}
                  {!isCancelled && (
                    <>
                      {/* Display substitutes button */}
                      <AuthorizedCan
                        has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
                        condition={
                          (!isConfirmedByReferee && !isClosed) ||
                          hasPermissions([PERMISSIONS.CORRECT_AFTER_CLOSING])
                        }
                        policy="some"
                        teamId={state.currentTeam}
                      >
                        <StyledButtonAddPerson
                          small={true}
                          onClick={() => history.push(`/matches/${matchGame.uuid}/substitute`)}
                          data-testid="add_substitute_button"
                        >
                          <StyledPlusIcon />
                          Invaller toevoegen
                        </StyledButtonAddPerson>
                      </AuthorizedCan>

                      {/* Display support button */}
                      <AuthorizedCan override={canManageTeamSupport} teamId={state.currentTeam}>
                        <StyledButtonAddPerson
                          small={true}
                          onClick={() => history.push(`/matches/${matchGame.uuid}/teamsupport`)}
                          data-testid="add_team_support_button"
                        >
                          <StyledPlusIcon />
                          Teamondersteuner toevoegen
                        </StyledButtonAddPerson>
                      </AuthorizedCan>

                      {/* Display player confirmation button */}
                      {canConfirmPlayers && (
                        <StyledButton
                          small={true}
                          onClick={() => history.push(`/matches`)}
                          data-testid="confirm_players_button"
                        >
                          Spelers bevestigen
                        </StyledButton>
                      )}

                      {/* Show match confirmation button */}
                      {canConfirmMatch && <>{getConfirmationButton(matchGame, history)}</>}
                    </>
                  )}
                </ButtonContainer>

                {/* Only render modal when we have a match and the current user is a secretary or superuser. */}
                {matchGame && (isSuperUser || isSecretary) && (
                  <ModalMatchConfirmation
                    matchGame={matchGame}
                    isVisible={state.confirmationModalVisible}
                    onModalClose={() => {
                      closeConfirmationModal();
                    }}
                    handleMatchConfirmationAs={handleMatchConfirmationAs}
                    handleMatchConfirmSuperuser={handleMatchConfirmSuperuser}
                    handleMatchConfirmationAsAll={handleMatchConfirmationAsAll}
                    handleMatchConfirmationAsSecretary={handleMatchConfirmationAsSecretary}
                  />
                )}
              </StyledMatchWrapper>
            </>
          )}
        />
        {/* Catch all other routes and redirect to the match detail page */}
        <Route path={`${routerMatch.url}`} render={() => <Redirect to={`${routerMatch.url}`} />} />
      </Switch>
      <FooterPushBar />
      <MatchInfoPopup
        showPopup={state.infoModalVisible && state.matchInfo !== null}
        onClick={() => setState((prevState) => ({ ...prevState, infoModalVisible: false }))}
        matchInfo={state.matchInfo}
      />
    </>
  );
};
