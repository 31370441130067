import React from 'react';
import { styled } from 'styled-components';
import { CARD_COLORS, CardColor } from '../../../@types/Cards';
import { theme } from '../../templates/ui';

const RadioWrapper = styled.div<Pick<CardRadioProps, 'cardColor'>>`
  margin-bottom: ${theme.sizing.xlarge};
  position: relative;

  input[type='radio'] {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;

    &:disabled {
      + label {
        background-color: ${theme.color.gray.light};
        color: ${theme.color.gray.darker};
        cursor: not-allowed !important;
      }
    }
  }

  input[type='radio'] + label {
    position: relative;
    display: flex;
    align-items: center;
    height: 56px;
    padding: ${theme.sizing.large};
    margin-right: ${theme.sizing.large};
    border: 2px solid ${theme.color.gray.light};
    border-radius: 3px;
    color: ${theme.color.base.base};
    transition: 50ms all ease-in-out;
    font-size: ${theme.font.large};

    &:hover {
      cursor: pointer;
    }

    &::before {
      transition: all 50ms ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -3px;
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }

    &::after {
      transition: opacity 50ms ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: 2px;
      z-index: 2;
    }
  }

  input[type='radio']:checked + label {
    ${(props) =>
      props.cardColor === CARD_COLORS.GREEN
        ? `background-color: #b0f998; border: 2px solid #3ed60c;`
        : ``};

    ${(props) =>
      props.cardColor === CARD_COLORS.YELLOW
        ? `background-color: #fff2be; border: 2px solid ${theme.color.cards.yellow};`
        : ``};

    ${(props) =>
      props.cardColor === CARD_COLORS.RED
        ? `background-color: #fec5bf; border: 2px solid #e32916;`
        : ``};

    &::before {
      transition: all 50ms ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }

    &::after {
      opacity: 1;
    }

    svg {
      fill: ${theme.color.body.base};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: ${theme.sizing.large};
`;

type CardRadioProps = {
  radioId: string;
  icon?: React.ReactNode;
  name: string;
  children: React.ReactNode;
  cardColor: CardColor;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled: boolean;
  required: boolean;
};

export const CardRadio = ({
  radioId,
  icon,
  name,
  children,
  cardColor,
  onChange,
  checked,
  disabled,
  required,
}: CardRadioProps) => (
  <RadioWrapper cardColor={cardColor}>
    <input
      type="radio"
      id={`card-radio-${radioId}`}
      name={name}
      value={cardColor}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      data-testid={`card-radio-${cardColor.toLowerCase()}`}
      required={required}
    />
    <label htmlFor={`card-radio-${radioId}`} data-testid={`card-label-${cardColor.toLowerCase()}`}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </label>
  </RadioWrapper>
);
