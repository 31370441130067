import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledReferee = styled.span`
  font-weight: 700;
  padding: ${theme.sizing.xsmall} ${theme.sizing.small} 1px ${theme.sizing.small};
  margin-top: -1px;
  margin-right: 6px;
  border-radius: 3px;
  height: 19px;
  color: ${theme.color.gray.white};
  font-size: ${theme.font.small};
  background-color: ${theme.color.secondary.darker};
`;

export const RefereeApproved = ({ refereeId, ...props }) => (
  <StyledReferee {...props}>{refereeId}</StyledReferee>
);
