import React, { Fragment, useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import { SizeMe } from 'react-sizeme';
import { css, styled } from 'styled-components';
import { CARD_COLORS, CARD_TYPES } from '../../@types/Cards';
import { destroy, store, update } from '../../api/cards';
import { withErrorHandler } from '../../contexts/ErrorContext';
import { withMatch } from '../../contexts/MatchContext';
import { RoleContext } from '../../contexts/RoleContext/RoleContext';
import { useHasPermission } from '../../hooks/useHasPermission';
import { hasGreenCards, hasRedCards, hasYellowCards } from '../../services/dwfOptions';
import { getTeamById } from '../../services/match';
import { fullName } from '../../services/person';
import { getMatchPlayersByTeamId } from '../../services/players';
import { AuthorizedCan } from '../atoms/AuthorizedCan';
import { Button } from '../atoms/Button';
import { Card } from '../atoms/Card';
import { CardRadio } from '../atoms/CardRadio';
import { CheckboxInline } from '../atoms/CheckboxInline';
import { FacilityField } from '../atoms/FacilityField';
import { FormItem } from '../atoms/FormItem';
import Account from '../atoms/Icons/Account';
import Clock from '../atoms/Icons/Clock';
import Disobedience from '../atoms/Icons/Disobedience';
import Edit from '../atoms/Icons/Edit';
import GreenCard from '../atoms/Icons/GreenCard';
import Physic from '../atoms/Icons/Physic';
import RedCard from '../atoms/Icons/RedCard';
import Shirt from '../atoms/Icons/Shirt';
import Trash from '../atoms/Icons/Trash';
import YellowCard from '../atoms/Icons/YellowCard';
import { Input } from '../atoms/Input';
import { Radio } from '../atoms/Radio/Radio';
import { Option, Select } from '../atoms/Select';
import { Textarea } from '../atoms/Textarea';
import { TitleUnderline } from '../atoms/TitleUnderline';
import { Wrapper } from '../atoms/Wrapper';
import MatchMeta from '../molecules/MatchMeta';
import MatchTeams from '../molecules/MatchTeams';
import Panel from '../molecules/Panel';
import TeamSupporters from '../molecules/TeamSupporters';
import TopBar from '../molecules/TopBar';
import { theme } from '../templates/ui';

const StyledTeams = styled(MatchTeams)`
  position: fixed;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  background-color: ${theme.color.body.base};
  padding: 0 7px;
  border-bottom: 1px solid ${theme.color.gray.light};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  z-index: 2;
`;

const Player = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed ${theme.color.gray.light};
  position: relative;
  overflow: hidden;

  h4 {
    color: ${theme.color.base.base};
    margin-top: 0;
    margin-bottom: ${theme.sizing.small};
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    margin: 0 3px;

    &:nth-child(3) {
      margin: 0 ${theme.sizing.xlarge};
    }
  }
`;

const StyledEdit = styled(Edit)`
  fill: ${theme.color.gray.darker};
  width: 24px;
  height: 24px;
`;

const StyledTrash = styled(Trash)`
  fill: ${theme.color.gray.darker};
  width: 19px;
  height: 24px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledShirt = styled(Shirt)`
  fill: ${theme.color.primary.base};
  width: 24px;
  height: 22px;
`;

const StyledAccount = styled(Account)`
  fill: ${theme.color.primary.base};
  width: 23px;
  height: 24px;
`;

const StyledClock = styled(Clock)`
  fill: ${theme.color.primary.base};
  width: 23px;
  height: 24px;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const cardStyle = (Card) => styled(Card)`
  width: 18px;
  height: 26px;
`;

const StyledYellowCard = cardStyle(YellowCard);
const StyledGreenCard = cardStyle(GreenCard);
const StyledRedCard = cardStyle(RedCard);
const StyledCard = cardStyle(Card);

const StyledDisobedience = styled(Disobedience)`
  fill: ${theme.color.primary.base};
`;

const StyledPhysic = styled(Physic)`
  fill: ${theme.color.primary.base};
`;

const StyledMatchMeta = styled(MatchMeta)`
  padding: ${theme.sizing.large} ${theme.sizing.xlarge};
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

const StyledTeamsPush = styled.div`
  position: relative;
`;

const StyledMessage = styled.div`
  padding: ${theme.sizing.xlarge};
  margin-bottom: ${theme.sizing.xxlarge};
`;

const StyledWarning = styled(StyledMessage)`
  background-color: rgba(204, 187, 20, 0.15);
  border-left: 3px solid ${theme.color.warning.base};
  color: ${theme.color.warning.base};
`;

const StyledError = styled(StyledMessage)`
  background-color: rgba(204, 0, 0, 0.15);
  border-left: 3px solid ${theme.color.error.base};
  color: ${theme.color.error.base};
`;

const TransparentButton = styled(Button)`
  background-color: transparent;
  padding: ${theme.sizing.xlarge} ${theme.sizing.medium};

  &:last-child {
    padding-right: 0;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }
`;

const NameWrapper = styled.div`
  flex: 1 1 auto;
  word-wrap: break-word;
`;

const CardWrapper = styled.div`
  margin-right: ${theme.sizing.small};
`;

const ConfirmationButton = styled(Button)`
  padding: ${theme.sizing.xlarge} 0;
  min-width: 90px;
  border-radius: 3px;
  ${(props) => props.confirm && `background: ${theme.color.secondary.base}`};
  ${(props) => props.confirm && `margin-right: ${theme.sizing.xxlarge};`};
  ${(props) => props.decline && `background: ${theme.color.warning.base}`};
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    ${(props) =>
      props.decline &&
      `
        background: ${theme.color.warning.base};
     `};
  }
`;

const DeleteConfirmation = styled.div`
  transition: max-height 0.2s ease-in-out;
  flex-basis: 100%;
  max-height: ${(props) => (props.show ? '150px' : '0')};
  border-radius: 3px;
`;

const DeleteConfirmationWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${theme.sizing.xlarge};
  background-color: ${theme.color.gray.base};
  padding: ${theme.sizing.xlarge};
`;

const PlayerCard = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  background: ${theme.color.gray.white};
`;

const border = `2px solid ${theme.color.gray.light}`;
const margin = `${theme.sizing.xlarge}`;

const underlineStyling = css`
  padding-bottom: 0;
  margin-bottom: ${margin};
  border-bottom: ${border};
`;

const withUnderlineStyling = (Component) => styled(Component)`
  ${underlineStyling};
`;

const StyledRadiosFormItem = withUnderlineStyling(FormItem);

const CheckBoxWithUnderline = styled(StyledRadiosFormItem)`
  padding-top: ${margin};
  padding-bottom: ${margin};
  border-top: ${border};
`;

const StyledReasonRadio = styled(Radio)`
  margin-bottom: ${theme.sizing.xlarge};
`;

const StyledDeleteSentence = styled.div`
  margin-bottom: ${theme.sizing.xlarge};
  flex-basis: 100%;
`;

const Cards = ({ matchGame, showGenericError, updateMatchGame }) => {
  const [state, setState] = useState({
    selectedTeam: matchGame.home_team,
    addCardForm: {},
    loading: false,
    errors: false,
    deletingCard: null,
    editingCard: null,
    hasSelectedCoach: false,
    openPanel: false,
  });

  const { role } = useContext(RoleContext);

  const { canManageCards } = useHasPermission();

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      originalCards: matchGame.match_cards,
    }));
  }, []);

  const isPersonCoach = (clubMemberId) => {
    return typeof clubMemberId === 'string' && clubMemberId.startsWith('C');
  };

  const parseClubMemberId = (clubMemberId) =>
    isPersonCoach(clubMemberId) ? Number(clubMemberId.substring(1)) : Number(clubMemberId);

  const updateSelectedTeam = (e) => {
    handleAddCardFormChangeEvent(e);

    let selectedTeam = null;
    if (e.target.value !== '') {
      selectedTeam = getTeamById(matchGame, parseInt(e.target.value)).team;
    }

    if (!selectedTeam) {
      return;
    }

    setState((prevState) => ({ ...prevState, selectedTeam }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, loading: true }));

    if (state.editingCard) {
      handleCardUpdate();
    } else {
      handleCardCreation();
    }
  };

  const handleCardUpdate = async () => {
    let cardData = { ...state.addCardForm };

    if (isPersonCoach(cardData.club_member_id)) {
      cardData.club_member_id = Number(cardData.club_member_id.substring(1));
      cardData.coach_card = true;
    } else {
      cardData.coach_card = false;
    }

    try {
      const response = await update(
        matchGame.uuid,
        state.editingCard,
        cardData,
        role,
        'match-cards'
      );

      const currentMatchCards = matchGame.match_cards;
      let editingCard = currentMatchCards.filter((card) => card.id === state.editingCard)[0];
      const indexOfEditingCard = currentMatchCards.indexOf(editingCard);

      const matchCards = [...matchGame.match_cards];
      matchCards[indexOfEditingCard] = { ...response.data };

      updateMatchCards(matchCards);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        addCardForm: {},
        editingCard: null,
        errors: false,
        openPanel: false,
      }));
    } catch (e) {
      if (e.response) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: e.response.data.errors,
        }));
      }

      showGenericError(e);
    }
  };

  const handleCardCreation = async () => {
    let cardData = { ...state.addCardForm };

    if (isPersonCoach(cardData.club_member_id)) {
      cardData.club_member_id = Number(cardData.club_member_id.substring(1));
      cardData.coach_card = true;
    } else {
      cardData.coach_card = false;
    }

    try {
      const response = await store(matchGame.uuid, cardData, role, 'match-cards');
      setState((prevState) => ({
        ...prevState,
        loading: false,
        addCardForm: {},
        editingCard: null,
        errors: false,
        openPanel: false,
      }));
      updateMatchCards([...matchGame.match_cards, response.data]);
    } catch (e) {
      if (e.response) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: e.response.data.errors,
        }));
      }

      showGenericError(e);
    }
  };

  const handleAddCardFormChangeEvent = (e) => {
    const optionDataSet = !!e.currentTarget.options
      ? e.currentTarget.options[e.currentTarget.selectedIndex].dataset
      : {};

    const usePersonId = !!optionDataSet.usePersonId;
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState(
      produce((draft) => {
        if (inputName !== 'club_member_id') {
          draft.addCardForm[inputName] = value;

          return draft;
        }

        delete draft.addCardForm[inputName];
        delete draft.addCardForm.person_id;

        if (usePersonId) {
          draft.addCardForm.person_id = value;

          return draft;
        }

        draft.addCardForm[inputName] = value;
      })
    );
  };

  const handleAddCardFormRadioChangeEvent = (e) =>
    handleAddCardFormChange(e.target.name, e.target.checked);

  const handleAddCardFormChange = (name, value) => {
    setState(
      produce((draft) => {
        draft.addCardForm[name] = value;
      })
    );
  };

  const hasError = (field) => {
    if (!state.errors) {
      return false;
    }

    return !!state.errors[field];
  };

  const getError = (field) => {
    if (!state.errors) {
      return false;
    }

    return state.errors[field][0];
  };

  const handleCardDeletion = (cardId) => {
    destroy(matchGame.uuid, cardId, role, 'match-cards')
      .then((_response) => {
        const cards = state.originalCards;
        const originalCards = cards.filter((card) => {
          return card.id !== cardId;
        });
        setState((prevState) => ({ ...prevState, originalCards }));
      })
      .catch((e) => {
        updateMatchCards(state.originalCards);

        showGenericError(e);
      });

    const match_cards = matchGame.match_cards.filter((card) => {
      return card.id !== cardId;
    });

    const shouldTogglePanel = state.editingCard === cardId;

    updateMatchCards(match_cards);
    setState((prevState) => ({
      ...prevState,
      editingCard: null,
      addCardForm: {},
      errors: null,
      deletingCard: null,
      openPanel: shouldTogglePanel ? !state.openPanel : state.openPanel,
    }));
  };

  const updateMatchCards = (match_cards) => {
    const match = produce(matchGame, (draft) => {
      draft.match_cards = match_cards;
    });

    updateMatchGame(match, null, 'match-comments');
  };

  const editCard = (e, cardId) => {
    const card = matchGame.match_cards.filter((card) => card.id === cardId)[0];

    setState((prevState) => ({
      ...prevState,
      addCardForm: {
        captain_card: card.is_captain_card,
        club_member_id: (card.is_coach_card ? 'C' : '') + card.club_member.id,
        color: card.color,
        minute: card.minute,
        team_id: card.team.id,
        type: card.type,
        comment: card.comment,
      },
    }));

    const selectedTeam = getTeamById(matchGame, card.team.id).team;

    if (!state.openPanel) {
      setState((prevState) => ({ ...prevState, openPanel: true }));
    }

    setState((prevState) => ({
      ...prevState,
      editingCard: cardId,
      selectedTeam,
    }));
  };

  const getPersonRoleByPersonId = (personId) => {
    let homeTeamPersonRole = matchGame.home_team.person_roles.find(
      (homeTeamPersonRole) =>
        !!homeTeamPersonRole.person && homeTeamPersonRole.person.id === personId
    );

    if (homeTeamPersonRole) {
      homeTeamPersonRole = {
        ...homeTeamPersonRole,
        club: matchGame.home_team.club.name,
      };

      return {
        team: matchGame.home_team,
        role: homeTeamPersonRole,
      };
    }

    let awayTeamPersonRole = matchGame.away_team.person_roles.find(
      (awayTeamPersonRole) =>
        !!awayTeamPersonRole.person && awayTeamPersonRole.person.id === personId
    );

    if (awayTeamPersonRole) {
      awayTeamPersonRole = {
        ...awayTeamPersonRole,
        club: matchGame.away_team.club.name,
      };

      return {
        team: matchGame.away_team,
        role: awayTeamPersonRole,
      };
    }

    return {
      team: null,
      role: null,
    };
  };

  const getPersonRole = (clubMemberId) => {
    let homeTeamPersonRole = matchGame.home_team.person_roles.find(
      (homeTeamPersonRole) =>
        !!homeTeamPersonRole.club_member && homeTeamPersonRole.club_member.id === clubMemberId
    );

    if (homeTeamPersonRole) {
      homeTeamPersonRole = {
        ...homeTeamPersonRole,
        club: matchGame.home_team.club.name,
      };

      return {
        team: matchGame.home_team,
        role: homeTeamPersonRole,
      };
    }

    let awayTeamPersonRole = matchGame.away_team.person_roles.find(
      (awayTeamPersonRole) =>
        !!awayTeamPersonRole.club_member && awayTeamPersonRole.club_member.id === clubMemberId
    );

    if (awayTeamPersonRole) {
      awayTeamPersonRole = {
        ...awayTeamPersonRole,
        club: matchGame.away_team.club.name,
      };

      return {
        team: matchGame.away_team,
        role: awayTeamPersonRole,
      };
    }

    return {
      team: null,
      role: null,
    };
  };

  const cardDisabled = (color) => {
    if (!!state.addCardForm && state.addCardForm.captain_card) {
      return false;
    }

    let playerCards = matchGame.match_cards.filter((card) => {
      return (
        isPersonCoach(state.addCardForm.club_member_id) === card.is_coach_card &&
        state.addCardForm.club_member_id &&
        state.editingCard !== card.id &&
        String(card.color) === String(color) &&
        Number(card.club_member.id) === parseClubMemberId(state.addCardForm.club_member_id) &&
        card.is_captain_card === false
      );
    });

    switch (String(color)) {
      case CARD_COLORS.RED:
        return playerCards.length >= 1;
      case CARD_COLORS.YELLOW:
        return playerCards.length >= 2;
      default:
        return false;
    }
  };

  const getTeamSupporterCards = () => {
    return matchGame.match_cards.filter((card) => {
      return !!card.is_coach_card;
    });
  };

  const getPlayerCards = () => {
    return matchGame.match_cards.filter((card) => {
      return !card.is_coach_card && !!getMatchPlayerForCard(card);
    });
  };

  const getPersonRoleForCard = (card) => {
    let { role: personRole, team } = getPersonRole(card.club_member.id);

    if (!personRole && card.club_member.person_id) {
      ({ role: personRole } = getPersonRoleByPersonId(card.club_member.person_id));
    }

    return { personRole, team };
  };

  const getMatchPlayerForCard = (card) => {
    return matchGame.match_players[card.club_member.id];
  };

  const renderPlayerCard = (card) => {
    const matchPlayer = getMatchPlayerForCard(card);
    const { personRole, team } = getPersonRoleForCard(card);

    if (!personRole && !matchPlayer) {
      return null;
    }

    return (
      <Player key={card.id} data-testid={'card-' + card.id}>
        <PlayerCard show={state.deletingCard !== card.id}>
          <NameWrapper>
            <h4>
              {!card.is_coach_card && matchPlayer && matchPlayer.jersey_number
                ? `${matchPlayer.jersey_number} - `
                : ``}
              {fullName(
                !card.is_coach_card && matchPlayer
                  ? matchPlayer.club_member.person
                  : personRole.person
              )}
            </h4>
            <span>{matchPlayer ? matchPlayer.team.name : team.name}</span>
          </NameWrapper>
          <Icons>
            <CardWrapper>
              <StyledCard
                role={(() => {
                  if (card.is_captain_card) return 'A';

                  if (card.is_coach_card) return 'C';

                  return undefined;
                })()}
                color={card.color}
              />
            </CardWrapper>

            <AuthorizedCan override={canManageCards}>
              <TransparentButton
                onClick={(e) => editCard(e, card.id)}
                data-testid={'edit_' + card.id}
              >
                <StyledEdit />
              </TransparentButton>
              <TransparentButton
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    deletingCard: card.id,
                  }))
                }
                data-testid={'delete_' + card.id}
              >
                <StyledTrash />
              </TransparentButton>
            </AuthorizedCan>
          </Icons>

          <AuthorizedCan override={canManageCards}>
            <DeleteConfirmation show={state.deletingCard === card.id} data-testid="delete_confirm">
              <DeleteConfirmationWrapper>
                <StyledDeleteSentence>
                  Weet je zeker dat je deze kaart wilt verwijderen?
                </StyledDeleteSentence>
                <ConfirmationButton
                  confirm={true}
                  onClick={() => handleCardDeletion(card.id)}
                  data-testid="delete_confirm_yes"
                >
                  Ja
                </ConfirmationButton>
                <ConfirmationButton
                  decline={true}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      deletingCard: null,
                    }))
                  }
                  data-testid="delete_confirm_no"
                >
                  Nee
                </ConfirmationButton>
              </DeleteConfirmationWrapper>
            </DeleteConfirmation>
          </AuthorizedCan>
        </PlayerCard>
      </Player>
    );
  };

  const selectedTeam = state.selectedTeam;
  const matchPlayersForTeam = getMatchPlayersByTeamId(matchGame.match_players, selectedTeam.id);

  return (
    <Fragment>
      <TopBar>Kaartenbeheer</TopBar>
      <SizeMe monitorHeight={true}>
        {({ size }) => (
          <>
            <StyledTeams
              homeTeam={matchGame.home_team}
              awayTeam={matchGame.away_team}
              homeScore={matchGame.home_score}
              homeShootoutScore={matchGame.home_shootout_score}
              awayShootoutScore={matchGame.away_shootout_score}
              awayScore={matchGame.away_score}
            />
            <StyledTeamsPush style={{ height: size.height }} />
          </>
        )}
      </SizeMe>
      {matchGame.field_name && (
        <FacilityField FacilityField={matchGame.field_name} FacilitySubField={matchGame.subfield} />
      )}
      <StyledWrapper>
        <StyledMatchMeta
          date={matchGame.date}
          matchCode={matchGame.match_code}
          approvals={matchGame.match_approvals}
          commentsCount={matchGame.match_comments.length}
        />
      </StyledWrapper>
      <Wrapper data-testid="cards">
        <AuthorizedCan override={canManageCards}>
          <Panel
            data-testid="manage-cards-panel"
            title={state.editingCard === null ? 'Kaart toevoegen' : 'Kaart bijwerken'}
            onClosePanel={() => {
              setState((prevState) => ({
                ...prevState,
                editingCard: null,
                addCardForm: {},
                errors: null,
                openPanel: false,
              }));
            }}
            onOpenPanel={() => {
              setState((prevState) => ({ ...prevState, openPanel: true }));
            }}
            open={state.openPanel}
          >
            <form onSubmit={handleFormSubmit}>
              {hasError('team_id') && <StyledError>{getError('team_id')}</StyledError>}
              <Select
                name="team_id"
                icon={<StyledShirt />}
                onChange={(e) => updateSelectedTeam(e)}
                required
                value={state.addCardForm.team_id || ''}
                data-testid="cards-team-select"
              >
                <Option key={0} value={''}>
                  Selecteer een team
                </Option>
                <Option value={matchGame.home_team.id}>{matchGame.home_team.name}</Option>
                <Option value={matchGame.away_team.id}>{matchGame.away_team.name}</Option>
              </Select>
              {hasError('club_member_id') && (
                <StyledError>{getError('club_member_id')}</StyledError>
              )}
              <Select
                name="club_member_id"
                icon={<StyledAccount />}
                required
                value={state.addCardForm.club_member_id || state.addCardForm.person_id || ''}
                onChange={handleAddCardFormChangeEvent}
                disabled={!state.addCardForm.team_id}
                data-testid="cards-player-select"
              >
                <Option key={0} value={null}>
                  Selecteer een persoon
                </Option>
                <optgroup label="Spelers">
                  {Object.keys(matchPlayersForTeam).map((clubMemberId) => (
                    <Option key={clubMemberId} value={clubMemberId}>
                      {matchPlayersForTeam[clubMemberId].jersey_number
                        ? `${matchPlayersForTeam[clubMemberId].jersey_number} - `
                        : ``}
                      {fullName(matchPlayersForTeam[clubMemberId].club_member.person)}
                    </Option>
                  ))}
                </optgroup>
                <optgroup label="Teamondersteuners">
                  {Object.keys(selectedTeam.person_roles).map(
                    (coachId) =>
                      !!selectedTeam.person_roles[coachId].club_member && (
                        <Option
                          key={coachId}
                          value={
                            !!selectedTeam.person_roles[coachId].club_member.id
                              ? 'C' + selectedTeam.person_roles[coachId].club_member.id
                              : 'C' + selectedTeam.person_roles[coachId].person.id
                          }
                          data-use-person-id={
                            selectedTeam.person_roles[coachId].club_member.id ? null : true
                          }
                        >
                          {fullName(selectedTeam.person_roles[coachId].person)}
                        </Option>
                      )
                  )}
                </optgroup>
              </Select>

              <FormItem>
                {hasError('minute') && <StyledError>{getError('minute')}</StyledError>}
                <Input
                  type="number"
                  name="minute"
                  min="0"
                  max="99"
                  required
                  data-testid="input-minute"
                  placeholder="Minuut"
                  icon={<StyledClock />}
                  value={state.addCardForm.minute || ''}
                  onChange={handleAddCardFormChangeEvent}
                />
              </FormItem>
              {!isPersonCoach(state.addCardForm.club_member_id) && (
                <CheckBoxWithUnderline>
                  {hasError('captain_card') && (
                    <StyledError>{getError('captain_card')}</StyledError>
                  )}
                  <CheckboxInline
                    checkboxId="7"
                    name="captain_card"
                    checked={!!state.addCardForm.captain_card}
                    onChange={handleAddCardFormRadioChangeEvent}
                  >
                    Aanvoerderskaart
                  </CheckboxInline>
                </CheckBoxWithUnderline>
              )}
              <StyledRadiosFormItem>
                {hasError('color') && (
                  <StyledError data-testid="cards-error">{getError('color')}</StyledError>
                )}
                {cardDisabled(CARD_COLORS.YELLOW) && !cardDisabled(CARD_COLORS.RED) && (
                  <StyledWarning data-testid="cards-warning">
                    Deze persoon heeft al 2 gele kaarten.
                  </StyledWarning>
                )}
                {cardDisabled(CARD_COLORS.RED) && !cardDisabled(CARD_COLORS.YELLOW) && (
                  <StyledWarning data-testid="cards-warning">
                    Deze persoon heeft al 1 rode kaart.
                  </StyledWarning>
                )}
                {cardDisabled(CARD_COLORS.YELLOW) && cardDisabled(CARD_COLORS.RED) && (
                  <StyledError data-testid="cards-warning">
                    Deze persoon heeft al twee gele kaarten en een rode kaart.
                  </StyledError>
                )}
                <RadioWrapper>
                  {hasGreenCards(matchGame.dwf_options) && (
                    <CardRadio
                      icon={<StyledGreenCard />}
                      radioId={1}
                      name="color"
                      cardColor={CARD_COLORS.GREEN}
                      checked={state.addCardForm.color === CARD_COLORS.GREEN}
                      onChange={handleAddCardFormChangeEvent}
                      data-testid="assign-green-card"
                      required
                    >
                      Groen
                    </CardRadio>
                  )}
                  {hasYellowCards(matchGame.dwf_options) && (
                    <CardRadio
                      icon={<StyledYellowCard />}
                      radioId={2}
                      name="color"
                      cardColor={CARD_COLORS.YELLOW}
                      checked={state.addCardForm.color === CARD_COLORS.YELLOW}
                      onChange={handleAddCardFormChangeEvent}
                      disabled={cardDisabled(CARD_COLORS.YELLOW)}
                      data-testid="assign-yellow-card"
                      required
                    >
                      Geel
                    </CardRadio>
                  )}
                  {hasRedCards(matchGame.dwf_options) && (
                    <CardRadio
                      icon={<StyledRedCard />}
                      radioId={3}
                      name="color"
                      cardColor={CARD_COLORS.RED}
                      checked={state.addCardForm.color === CARD_COLORS.RED}
                      onChange={handleAddCardFormChangeEvent}
                      disabled={cardDisabled(CARD_COLORS.RED)}
                      data-testid="assign-red-card"
                      required
                    >
                      Rood
                    </CardRadio>
                  )}
                </RadioWrapper>
              </StyledRadiosFormItem>
              <StyledRadiosFormItem>
                {hasError('type') && <StyledError>{getError('type')}</StyledError>}
                <RadioWrapper>
                  <StyledReasonRadio
                    icon={<StyledDisobedience />}
                    data-testid="label-verbal"
                    radioId={4}
                    name="type"
                    value={CARD_TYPES.VERBAL}
                    checked={state.addCardForm.type === CARD_TYPES.VERBAL}
                    onChange={handleAddCardFormChangeEvent}
                    required
                  >
                    Verbaal
                  </StyledReasonRadio>
                  <Radio
                    icon={<StyledPhysic />}
                    radioId={5}
                    name="type"
                    value={CARD_TYPES.PHYSICAL}
                    checked={state.addCardForm.type === CARD_TYPES.PHYSICAL}
                    onChange={handleAddCardFormChangeEvent}
                    required
                  >
                    Fysiek
                  </Radio>
                  <StyledReasonRadio
                    radioId={6}
                    name="type"
                    value={CARD_TYPES.OTHER}
                    checked={state.addCardForm.type === CARD_TYPES.OTHER}
                    onChange={handleAddCardFormChangeEvent}
                    required
                  >
                    Overig
                  </StyledReasonRadio>
                </RadioWrapper>
              </StyledRadiosFormItem>
              <FormItem>
                {hasError('comment') && <StyledError>{getError('comment')}</StyledError>}
                <Textarea
                  placeholder="Eventuele opmerkingen"
                  name="comment"
                  value={state.addCardForm.comment || ''}
                  onChange={handleAddCardFormChangeEvent}
                />
              </FormItem>

              <StyledButton disabled={state.loading} data-testid="saveCardButton">
                {state.editingCard ? 'Bijwerken' : 'Opslaan'}
              </StyledButton>
            </form>
          </Panel>
        </AuthorizedCan>
        <TitleUnderline>Spelers / speelsters</TitleUnderline>
        <div data-testid="given-cards">
          {matchGame.match_cards.length === 0 && <p>Geen kaarten</p>}
          {getPlayerCards().map((card) => renderPlayerCard(card))}
        </div>

        <TeamSupporters title={<TeamSupporters.Title />}>
          {getTeamSupporterCards().length === 0 && <p>Geen kaarten</p>}
          {getTeamSupporterCards().map((card) => renderPlayerCard(card))}
        </TeamSupporters>
      </Wrapper>
    </Fragment>
  );
};

export default withErrorHandler(withMatch(Cards));
