import React from 'react';
import { styled } from 'styled-components';
import Knhb from '../../atoms/Icons/Knhb';
import { theme } from '../../templates/ui';

const StyledPage = styled.div`
  background-color: ${theme.color.primary.base};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledLogo = styled(Knhb)`
  display: block;
  margin: 0 auto;
`;

const StyledMessage = styled.p`
  color: white;
  text-align: center;
  padding-top: ${theme.sizing.xlarge};
  font-size: ${theme.font.base};
`;

export const FatalErrorScreen = ({ children }) => (
  <StyledPage>
    <Container>
      <StyledLogo />
      {children && <StyledMessage>{children}</StyledMessage>}
    </Container>
  </StyledPage>
);

export default FatalErrorScreen;
