import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import { FormItem } from '../FormItem/FormItem';

const StyledTextarea = styled.textarea`
  width: 100%;
  border: 1px solid ${theme.color.gray.light};
  padding: ${theme.sizing.large};
  resize: vertical;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    color: ${theme.color.gray.darker};
  }

  &:focus {
    outline: none;
    border-bottom: 3px solid ${theme.color.primary.base};
  }
`;

export const Textarea = React.forwardRef(function Textarea(props, ref) {
  return (
    <FormItem>
      <StyledTextarea {...props} ref={ref} />
    </FormItem>
  );
});
Textarea.displayName = 'Textarea';
