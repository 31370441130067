import React, { useEffect, useRef } from 'react';
import { AccordionItemBody as OriginalAccordionItemBody } from 'react-accessible-accordion';
import { styled } from 'styled-components';
import { collapse, expand } from '../../services/collapsible';

export { Accordion, AccordionItem, AccordionItemTitle } from 'react-accessible-accordion';

const StyledAccordionItemBody = styled(OriginalAccordionItemBody)`
  padding: 0;
  transition: max-height 0.3s ease-out;
  overflow: hidden;

  &[aria-hidden='false'] {
    transition: max-height 0.4s ease-in;
  }
`;

export const AccordionItemBody = (props) => {
  const bodyWrapperRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line
    if (!(bodyWrapperRef.current instanceof HTMLElement)) {
      return;
    }

    const accordionBody = bodyWrapperRef.current.querySelector('[data-accordion-item-body]');

    const observeCallback = ({ target }) => {
      const hidden = target.getAttribute('aria-hidden') === 'true';

      hidden ? collapse(target) : expand(target);
    };

    const accordionItemBodyObserver = new MutationObserver((mutations) => {
      mutations.forEach(observeCallback);
    });

    accordionItemBodyObserver.observe(accordionBody, {
      attributes: true,
      attributeFilter: ['aria-hidden'],
    });

    observeCallback({ target: accordionBody });

    return () => {
      accordionItemBodyObserver.disconnect();
    };
  }, [bodyWrapperRef]);

  return (
    <div ref={bodyWrapperRef}>
      <StyledAccordionItemBody {...props} data-accordion-item-body />
    </div>
  );
};
