import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { produce } from 'immer';
import { styled } from 'styled-components';
import { addMatchTeamSupport, removeMatchTeamSupport, searchPersons } from '../../../api/matches';
import { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import Account from '../../atoms/Icons/Account';
import Loader from '../../atoms/Icons/Loader';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import { Persons } from '../../atoms/Persons';
import { Wrapper } from '../../atoms/Wrapper';
import ButtonContainer from '../../molecules/ButtonContainer';
import IconButton from '../../molecules/IconButton';
import MatchMeta from '../../molecules/MatchMeta';
import Search from '../../molecules/Search';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledMatchMeta = styled(MatchMeta)`
  padding: ${theme.sizing.large} ${theme.sizing.xlarge};
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

const SupportList = styled.div`
  padding: ${theme.sizing.xlarge} 0;
`;

const StyledHeader = styled.div`
  display: flex;
  padding-bottom: ${theme.sizing.small}
  border-bottom: 2px solid ${theme.color.primary.base};

  div:first-child {
    flex: 1;
  }
`;

const HeaderItem = styled.div`
  color: ${theme.color.base.base};
  padding-right: ${theme.sizing.xlarge};

  &:last-child {
    padding-right: 25px;
  }
`;

const StyledButton = styled(IconButton)`
  width: auto;
  padding-left: 40px;

  svg {
    left: 0.75rem;
  }

  &:focus {
    background-color: ${theme.color.secondary.hover};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: ${theme.color.body.base};
`;

const StyledMain = styled.div``;

const StyledLoader = styled(Loader)`
  width: 25px;
  position: absolute;
  top: -8px;
`;

const initialState = {
  personsResult: [],
  buttonDisabled: true,
  loading: false,
  searchQuery: '',
  searchTeam: {
    id: null,
    club: {
      id: null,
    },
  },
};

const stateReducer = (state, action) => ({
  ...state,
  ...(typeof action === 'function' ? action(state) : action),
});

const TeamSupportPage = ({ user, matchGame, updateMatchGame, history }) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const { role } = useContext(RoleContext);

  useEffect(() => {
    const searchTeam =
      matchGame.home_team.id === role.team_id ? matchGame.home_team : matchGame.away_team;
    setState({
      searchTeam: searchTeam,
    });
  }, [role]);

  useEffect(() => {
    throttledSearch();
  }, [state.searchQuery]);

  const handleAddTeamSupport = async (matchUuid, clubMemberId, teamId, personList) => {
    setState({ buttonDisabled: false });

    await addMatchTeamSupport(matchUuid, clubMemberId, teamId, role, 'match-persons');

    // To which team does the person belong?
    const team = teamId === matchGame.home_team.id ? 'home_team' : 'away_team';

    // Find the person that was added to the match in the list of results
    const person = personList.find((person) => person.club_member.id === clubMemberId);

    // Add the person to the match
    const match = produce(matchGame, (draft) => {
      draft[team].person_roles = [...draft[team].person_roles, person];
    });

    updateMatchGame(match, null, 'match-persons');
  };

  const handleRemoveTeamSupport = async (matchUuid, clubMemberId, teamId) => {
    setState({ buttonDisabled: false });

    await removeMatchTeamSupport(matchUuid, clubMemberId, teamId, role, 'match-persons');

    // To which team does the person we want to remove belong?
    const team = teamId === matchGame.home_team.id ? 'home_team' : 'away_team';

    // Filter out the person that was removed from the match
    const match = produce(matchGame, (draft) => {
      draft[team].person_roles = draft[team].person_roles.filter(
        (p) => p.club_member.id !== clubMemberId
      );
    });

    updateMatchGame(match, null, 'match-persons');
  };

  const handleSearch = async (clubId, queryString) => {
    setState({ loading: true });
    const results = await searchPersons(matchGame.uuid, clubId, queryString, role, 'match-support');

    // In which team do we need to search?
    const team = role.team_id === matchGame.home_team.id ? 'home_team' : 'away_team';

    // Filter out persons which are already added to the match
    const persons = Object.values(results.data).filter((s) => {
      return !matchGame[team].person_roles.filter((ms) => {
        return ms.person.id === s.person.id;
      }).length;
    });

    setState({ personsResult: persons, loading: false });
  };

  const validSearchQuery = useMemo(() => {
    return state.searchQuery.length >= 3;
  }, [state.searchQuery]);

  let searchDelay;
  const throttledSearch = () => {
    clearTimeout(searchDelay);

    if (!validSearchQuery) {
      return;
    }

    searchDelay = setTimeout(() => {
      handleSearch(state.searchTeam.club.id, state.searchQuery);
    }, 1000);
  };

  return (
    <>
      <TopBar>Teamondersteuner toevoegen</TopBar>
      <StyledWrapper>
        <StyledMatchMeta
          user={user}
          date={matchGame.date}
          matchCode={matchGame.match_code}
          commentsCount={matchGame.match_comments.length}
          approvals={matchGame.match_approvals}
        />
      </StyledWrapper>
      <Search
        searchClub={state.searchTeam}
        label="Teamondersteuner zoeken"
        icon={state.loading ? <StyledLoader color={theme.color.primary.base} /> : <Account />}
        placeholder="Typ een naam"
        onClubChange={(clubId) => {
          const searchTeam =
            matchGame.home_team.id === clubId ? matchGame.home_team : matchGame.away_team;
          setState({
            searchTeam,
            personsResult: [],
            searchQuery: '',
          });
        }}
        onChange={(e) =>
          setState({
            searchQuery: e.currentTarget.value,
          })
        }
        value={state.searchQuery}
      />

      <Wrapper>
        <SupportList>
          {validSearchQuery && !state.loading && (
            <>
              {state.personsResult.length > 0 && (
                <StyledHeader>
                  <HeaderItem>Naam</HeaderItem>
                  <HeaderItem />
                  <HeaderItem />
                </StyledHeader>
              )}
              <StyledMain>
                <Persons
                  user={user}
                  match={matchGame}
                  persons={state.personsResult}
                  matchUuid={matchGame.uuid}
                  teamId={state.searchTeam.id}
                  personsForTeamId={state.searchTeam.id}
                  onAddSupport={(matchUuid, clubMemberId, teamId) => {
                    handleAddTeamSupport(matchUuid, clubMemberId, teamId, state.personsResult);
                  }}
                  onRemoveSupport={(matchUuid, clubMemberId, teamId, matchPlayerId) => {
                    handleRemoveTeamSupport(matchUuid, clubMemberId, teamId, matchPlayerId);
                  }}
                >
                  <p>
                    Er zijn geen personen gevonden die een geldig lidmaatschap hebben tijdens deze
                    wedstrijd.
                  </p>
                </Persons>
              </StyledMain>
            </>
          )}
        </SupportList>
      </Wrapper>
      <ButtonContainer>
        <StyledButton
          data-testid="add-team-support-confirmation-button"
          disabled={state.buttonDisabled}
          small={true}
          onClick={() => {
            history.goBack();
          }}
        >
          <StyledPlusIcon />
          Teamondersteuning toevoegen
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

export const TeamSupport = withMatch(TeamSupportPage);
