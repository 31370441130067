export const validateMaxPlayers = (maxPlayers, activePlayersCount) => {
  if (!maxPlayers) {
    return true;
  }

  return activePlayersCount <= maxPlayers;
};

export const validateMinPlayers = (minPlayers, activePlayersCount) => {
  if (!minPlayers) {
    return true;
  }

  return activePlayersCount >= minPlayers;
};
