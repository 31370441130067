import React, { useEffect, useState } from 'react';
import { index } from '../../../api/matches';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { Index as IndexPresentational } from '../../pages/Index';

const Index = ({ showGenericError }) => {
  const [state, setState] = useState({
    current: [],
    upcoming: [],
    history: [],
  });

  useEffect(() => {
    const asyncEffect = async function () {
      try {
        const { data } = await index('match-index');
        const { current, upcoming, history } = data;
        setState({ current, upcoming, history });
      } catch (error) {
        showGenericError(error);
      }
    };
    asyncEffect();
  }, []);

  return (
    <IndexPresentational
      currentMatches={state.current}
      upcomingMatches={state.upcoming}
      historyMatches={state.history}
    />
  );
};

export default withErrorHandler(Index);
