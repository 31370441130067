import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { styled } from 'styled-components';
import { ID_TOKEN_KEY } from '../../api/sso';
import ChevronRight from '../atoms/Icons/ChevronRight';
import Knhb from '../atoms/Icons/Knhb';
import { Wrapper } from '../atoms/Wrapper';
import IconButton from '../molecules/IconButton';
import { theme } from '../templates/ui';

const pseudoShape = () => `
    position: absolute;
    display: block;
    content: "";
`;

const StyledTitle = styled.h3`
  font-size: ${theme.font.xxxlarge};
  text-align: center;
  margin-bottom: 60px;
  color: ${theme.color.body.base};

  &::after {
    ${pseudoShape()};
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 3px;
    margin-top: ${theme.sizing.large};
    background-color: ${theme.color.body.base};
  }
`;

const StyledIcon = styled.div`
  svg {
    width: 247px;
    height: 70px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: ${theme.sizing.xxlarge};
  }
`;

const ContainerWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .login-description {
    color: ${theme.color.body.base};
    font-weight: 300;
    line-height: ${theme.sizing.xxlarge};
  }
`;

const StyledChevron = styled(ChevronRight)`
  fill: ${theme.color.body.base};
`;

const LoginCard = ({ handleLogin }) => {
  if (localStorage.getItem(ID_TOKEN_KEY)) {
    return <Redirect to="/matches" />;
  }

  return (
    <ContainerWrapper>
      <Wrapper>
        <StyledIcon>
          <Knhb />
        </StyledIcon>
        <StyledTitle>Digitaal Wedstrijd Formulier</StyledTitle>
        <IconButton onClick={handleLogin} icon="chevron-right">
          Inloggen <StyledChevron />
        </IconButton>
      </Wrapper>
    </ContainerWrapper>
  );
};

LoginCard.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

export default LoginCard;
