import React from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../../contexts/MatchContext';
import { fullName } from '../../../services/person';
import { Person } from '../Person';

const PersonsWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const PersonsComponent = ({
  className,
  teamId,
  persons,
  matchUuid,
  onAddSupport,
  onRemoveSupport,
  children,
  divergentStyling,
}) => {
  return (
    <PersonsWrapper className={className}>
      {!!persons.length
        ? parsePersons(persons, matchUuid, teamId, onAddSupport, onRemoveSupport, divergentStyling)
        : children}
    </PersonsWrapper>
  );
};

function parsePersons(persons, matchUuid, teamId, onAddSupport, onRemoveSupport, divergentStyling) {
  return persons.map((person, i) => {
    return (
      <Person
        key={i}
        name={fullName(person.person)}
        checkboxId={i}
        personId={person.person.id}
        teamId={teamId}
        clubMemberId={person.club_member.id}
        matchUuid={matchUuid}
        onAddSupport={onAddSupport}
        onRemoveSupport={onRemoveSupport}
        divergentStyling={divergentStyling}
      />
    );
  });
}

export const Persons = withMatch(PersonsComponent);
