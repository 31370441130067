import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background-image: url('/img/indicator.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: calc(0px - ${theme.sizing.large});
  margin-left: calc(0px - ${theme.sizing.xlarge});
  opacity: ${(props) => (!!props.isLoading ? '1' : '0')};
  animation: button-indicator-rotations 600ms linear infinite;
  transition:
    all 120ms ease-in-out 0ms,
    visibility 0ms ease-in-out 120ms;

  @keyframes button-indicator-rotations {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingIcon = ({ isLoading }) => <StyledLoader isLoading={isLoading} />;
