import React from 'react';
import { useHistory } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { styled } from 'styled-components';
import { Button } from '../../atoms/Button';
import { FormItem } from '../../atoms/FormItem';
import Checkmark from '../../atoms/Icons/Checkmark';
import { Radio } from '../../atoms/Radio';
import { Textarea } from '../../atoms/Textarea';
import { Title } from '../../atoms/Title';
import { TitleUnderline } from '../../atoms/TitleUnderline';
import { Wrapper } from '../../atoms/Wrapper';
import MatchMeta from '../../molecules/MatchMeta';
import MatchTeams from '../../molecules/MatchTeams';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const RadioWrapper = styled.div`
  display: flex;
`;

const Question = styled.div``;

const StyledRadio = styled(Radio)`
  input[type='radio'] + label {
    height: auto;
    padding: ${theme.sizing.large} ${theme.sizing.xxlarge};
    margin-right: ${theme.sizing.xlarge};
  }

  input[type='radio']:checked + label {
    font-weight: 700;
  }
`;

const StyledTitle = styled(Title)`
  color: ${theme.color.base.base};
  margin-top: ${theme.sizing.xlarge};
  margin-bottom: ${theme.sizing.small};
`;

const StyledTeams = styled(MatchTeams)`
  position: fixed;
  top: 56px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  background-color: ${theme.color.body.base};
  padding: 0 7px;
  border-bottom: 1px solid ${theme.color.gray.light};
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  z-index: 1;
`;

const StyledTeamsPush = styled.div`
  position: relative;
  height: 81px;
`;

const StyledMatchMeta = styled(MatchMeta)`
  padding: ${theme.sizing.large} ${theme.sizing.large} ${theme.sizing.xlarge};
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

const StyledFormItem = styled(FormItem)`
  padding-top: ${theme.sizing.large};
  padding-bottom: 0;
`;

const Checklist = styled.div`
  position: relative;
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 24px;
  transform: translateY(-50%);

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    color: ${theme.color.gray.black};
    text-align: center;
    transition: 0.1s all ease-in-out;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -3px;
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }

    &::after {
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: ${theme.sizing.xsmall};
      z-index: 2;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: ${theme.color.secondary.base};
    border: 1px solid ${theme.color.secondary.base};

    svg {
      opacity: 1;
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }

    &::after {
      opacity: 1;
    }
  }
`;

const StyledItem = styled.div`
  position: relative;
  display: flex;
  padding: ${theme.sizing.xlarge} 0;
  border-bottom: 1px dashed ${theme.color.gray.light};
  font-weight: 700;
  color: ${theme.color.gray.darkest};

  &:last-child {
    border-bottom: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${theme.sizing.xlarge} 0;
  border-top: 2px solid ${theme.color.gray.light};
`;

export const Questions = ({ match }) => {
  const history = useHistory();

  const handleSaveQuestionsForm = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <>
      <TopBar>Jeugd vragenlijst</TopBar>
      <SizeMe monitorHeight={true}>
        {({ size }) => (
          <>
            <StyledTeams
              homeTeam={match.home_team}
              awayTeam={match.away_team}
              homeScore={match.home_score}
              awayScore={match.away_score}
            />
            <StyledTeamsPush style={{ height: size.height }} />
          </>
        )}
      </SizeMe>
      <StyledWrapper>
        <StyledMatchMeta
          date={match.date}
          sportlinkMatchId={match.sportlink_match_id}
          commentsAmount={match.comments_amount}
        />
      </StyledWrapper>
      <Wrapper>
        <Question>
          <TitleUnderline>
            Waren er maatregelen noodzakelijk om een groot verschil in doelpunten te voor komen?
          </TitleUnderline>
          <StyledFormItem>
            <RadioWrapper>
              <StyledRadio radioId={1} name="yesno">
                Ja
              </StyledRadio>
              <StyledRadio radioId={2} name="yesno">
                Nee
              </StyledRadio>
            </RadioWrapper>
            <StyledTitle>Welke?</StyledTitle>
            <Textarea placeholder="Beschrijf hier de maatregelen" rows="4" />
          </StyledFormItem>
        </Question>
        <Question>
          <TitleUnderline>Hoe was de sfeer tijdens de wedstrijd?</TitleUnderline>
          <StyledFormItem>
            <RadioWrapper>
              <StyledRadio radioId={4} name="vibe">
                Goed
              </StyledRadio>
              <StyledRadio radioId={5} name="vibe">
                Matig
              </StyledRadio>
              <StyledRadio radioId={6} name="vibe">
                Slecht
              </StyledRadio>
            </RadioWrapper>
            <StyledTitle>Waarom?</StyledTitle>
            <Textarea placeholder="Beschrijf hier de maatregelen" rows="4" />
          </StyledFormItem>
        </Question>
        <Question>
          <TitleUnderline>Samen overeengekomen aandachtspunten voor de wedstrijd.</TitleUnderline>
          <StyledFormItem>
            <Checklist>
              <StyledItem>
                Gevaarlijk spel
                <CheckboxWrapper>
                  <input type="checkbox" id="7" />
                  <label htmlFor="7">
                    <Checkmark />
                  </label>
                </CheckboxWrapper>
              </StyledItem>
              <StyledItem>
                Plaats houde/opstelling
                <CheckboxWrapper>
                  <input type="checkbox" id="8" />
                  <label htmlFor="8">
                    <Checkmark />
                  </label>
                </CheckboxWrapper>
              </StyledItem>
              <StyledItem>
                Vrije slagen (wie neemt ze?)
                <CheckboxWrapper>
                  <input type="checkbox" id="9" />
                  <label htmlFor="9">
                    <Checkmark />
                  </label>
                </CheckboxWrapper>
              </StyledItem>
              <StyledItem>
                Samen spelen
                <CheckboxWrapper>
                  <input type="checkbox" id="10" />
                  <label htmlFor="10">
                    <Checkmark />
                  </label>
                </CheckboxWrapper>
              </StyledItem>
            </Checklist>
          </StyledFormItem>
        </Question>
        <Container>
          <Button onClick={handleSaveQuestionsForm}>Opslaan</Button>
        </Container>
      </Wrapper>
    </>
  );
};
