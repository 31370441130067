import React from 'react';
import { styled } from 'styled-components';
import { Bar } from '../../atoms/Bar';
import { FaqImage } from '../../atoms/FaqImage';
import { Paragraph } from '../../atoms/Paragraph';
import { TitleUnderline } from '../../atoms/TitleUnderline';
import { Wrapper } from '../../atoms/Wrapper';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledBar = styled(Bar)`
  text-align: left;
  padding: ${theme.sizing.xxlarge} 0;
  margin-bottom: 0;

  h3 {
    margin: 0;
  }
`;

const FaqWrapper = styled.div`
  margin-bottom: ${theme.sizing.xxlarge};
`;

const StyledFaqImage = styled(FaqImage)`
  margin-top: ${theme.sizing.xxlarge};
`;

export const FaqReferee = () => (
  <>
    <TopBar>Hoe werkt het DWF</TopBar>
    <StyledBar>
      <Wrapper>
        <h3>Voor scheidsrechters</h3>
      </Wrapper>
    </StyledBar>
    <Wrapper>
      <FaqWrapper>
        <Paragraph>
          Als scheidsrechter van een wedstrijd kun je na het invullen van de wedstrijdverificatie
          code diverse acties uitvoeren:
        </Paragraph>
        <FaqImage>
          <img src="../3-scheidsoverzicht.png" alt="" />
        </FaqImage>
        <Paragraph>
          Op de bovenste regel zie je de datum van de wedstrijd, het tijdstip en de code waarmee je
          bent ingelogd. Wanneer een wedstrijd is afgelast, wordt dit in deze regel vermeld. Het
          laatste icoontje (tekstwolk) geeft aan hoeveel opmerkingen er op het formulier zijn
          geplaatst.
        </Paragraph>

        <TitleUnderline>Eindstand</TitleUnderline>
        <Paragraph>
          Je ziet de logo’s van de beide teams. Tussen beide teams staat de stand. Het thuisspelende
          team staat altijd als eerste genoemd. Vul de eindstand in de vierkante blokken in.
        </Paragraph>

        <TitleUnderline>Shoot-outs</TitleUnderline>
        <Paragraph>
          Wanneer een wedstrijd beslist kan worden door shoot-outs, verschijnt onder de eindstand
          een tweede regel. Hier heb je de mogelijkheid de eindstand van de shoot-outs in te vullen.
          (achter de icoontjes van de twee doeltjes).
        </Paragraph>

        <TitleUnderline>Kaarten</TitleUnderline>
        <FaqImage>
          <img src="../4-kaartenoverzicht.png" alt="" />
        </FaqImage>
        <Paragraph>
          Je kunt indien nodig aangeven wie een kaart heeft gekregen. Je wijst een speler een kaart
          toe door te klikken op de button ‘Kaarten toevoegen’. Vervolgens zie je in ‘Kaartenbeheer’
          een overzicht van de eventueel al toegekende kaarten van die wedstrijd. Klik op ‘Kaart
          toevoegen’ wanneer je een kaart wilt toewijzen.
        </Paragraph>
        <Paragraph>
          Selecteer vervolgens het team, de speler en geef aan in welke minuut de kaart is gegeven.
          Daarna geef je aan welke kleur kaart er is gegeven, en of deze gegeven is voor een
          verbale, fysieke of overige overtreding. De aangeklikte blokken krijgen een kleur. En als
          het een aanvoerderskaart betreft, kun je dit aanvinken. Eventuele opmerking voeg je
          onderaan toe, waarna je de kaart opslaat.
        </Paragraph>
        <FaqImage>
          <img src="../5-kaartbewerken.png" alt="" />
        </FaqImage>
        <Paragraph>
          De kaart kun je eventueel nogmaals bewerken door op het potlood te klikken. Heb je een
          fout gemaakt en wil je de kaart verwijderen, klik dan de naam van de speler in het
          overzicht aan, klik op het vuilnisbak-icoon en geef toestemming de kaart te verwijderen.
        </Paragraph>

        <TitleUnderline>Opmerkingen</TitleUnderline>
        <Paragraph>
          Wil je zelf nog iets toevoegen aan het formulier, dan doe je dat door een opmerking te
          plaatsen. Klik hiervoor op het icoontje ‘tekstwolkje’ en vul je opmerking in.
        </Paragraph>
        <FaqImage>
          <img src="../6-opmerkingen.png" alt="" />
        </FaqImage>

        <TitleUnderline>Wedstrijd afronden</TitleUnderline>
        <Paragraph>
          De wedstrijd wordt door de scheidsrechters afgerond doordat ze allebei het
          wedstrijdformulier afronden. Dat kan door te klikken op de groene button onder de
          spelerslijst, onderaan het formulier.
        </Paragraph>

        <TitleUnderline>Wedstrijd afgelasten/staken</TitleUnderline>
        <StyledFaqImage>
          <img src="../7-afgelastingen.png" alt="" />
        </StyledFaqImage>
        <Paragraph>
          De wedstrijd afgelasten of staken doe je via de grijze button ‘Wedstrijd
          afgelasten/staken’ die je onder de lijst met spelers vindt. Je krijgt nu een invulveld te
          zien dat je vraagt de reden in te vullen. De reden kies je in het menu door op ‘selecteer
          een reden’ te klikken. Geef eventueel een toelichting, vul de minuut in waarop de
          wedstrijd is afgelast/gestaakt en geef de stand van dat moment aan. Vervolgens klik je op
          de groene button onderaan het formulier. De wedstrijd is nu afgelast. <br />
          Let op: er is geen mogelijkheid deze actie ongedaan te maken!
        </Paragraph>
      </FaqWrapper>
    </Wrapper>
  </>
);

export default FaqReferee;
