import React, { Fragment, useContext, useMemo } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { styled } from 'styled-components';
import { RoleContext } from '../../contexts/RoleContext/RoleContext';
import { useHasTeam } from '../../hooks/useHasTeam';
import { getGivenCardsForPlayer } from '../../services/card';
import { jerseyNumbersActive } from '../../services/dwfOptions';
import { fullName } from '../../services/person';
import { TeamSupporter } from '../atoms/TeamSupporter';
import { Wrapper } from '../atoms/Wrapper';
import Players from '../molecules/Players';
import TeamSupporters from '../molecules/TeamSupporters';
import { theme } from '../templates/ui';

const StyledPlayers = styled(Players)`
  background-color: ${theme.color.gray.base};
  padding-left: ${theme.sizing.xlarge};
  padding-right: ${theme.sizing.xlarge};
  padding-bottom: 0;
  border-top: 1px solid ${theme.color.gray.light};
`;

const StyledTeamSupporters = styled(TeamSupporters)`
  padding-left: ${theme.sizing.xlarge};
  padding-right: ${theme.sizing.xlarge};
  padding-bottom: 0;
  background-color: ${theme.color.gray.base};
`;

const StyledTeamSupportersTitle = styled(TeamSupporters.Title)`
  padding-left: ${theme.sizing.xlarge};
  padding-bottom: ${theme.sizing.large};
`;

const StyledTabList = styled(TabList)`
  margin: 0;
  padding: 0;
  display: flex;
`;

const StyledTab = styled(Tab)`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.color.gray.light};
  border-bottom: none;
  bottom: -1px;
  list-style: none;
  padding: ${theme.sizing.large};
  cursor: pointer;
  font-weight: 700;
  color: ${theme.color.gray.darkest};
  margin-right: 14px;
  width: 100%;

  &:focus {
    outline: none;
  }

  &[aria-selected='true'] {
    background-color: ${theme.color.gray.base};
    border-color: ${theme.color.gray.light};
    border-radius: 0;
    color: ${theme.color.primary.base};
  }

  &[aria-selected='false'] {
    background-color: transparent;
    border: none;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  margin-left: calc(0px - ${theme.sizing.xlarge});
  margin-right: calc(0px - ${theme.sizing.xlarge});
`;

const StyledTabTitle = styled.div`
  text-align: center;
  font-size: ${theme.font.medium};
  word-break: break-word;
`;

const getTeamSupporters = (match, teamSupporters) =>
  Object.values(teamSupporters)
    .sort((a, b) => {
      const result =
        a.person.family_name.localeCompare(b.person.family_name) ||
        a.person.given_name.localeCompare(b.person.given_name);

      return result;
    })
    .map((teamSupporter, index) => {
      return (
        <TeamSupporter
          personId={teamSupporter.person.id}
          fullName={fullName(teamSupporter.person)}
          cards={getGivenCardsForPlayer(teamSupporter.club_member.id, match)}
          key={`${teamSupporter.club_member.id}-${index}`}
        />
      );
    });

const Playerlist = ({
  user,
  match,
  awayTeamPlayers,
  homeTeamPlayers,
  onJerseyNumberChange,
  toggleMatchPlayer,
  onChangeTeam,
}) => {
  const homeTeamTeamSupporters = useMemo(
    () => getTeamSupporters(match, match.home_team.person_roles),
    [match]
  );
  const awayTeamTeamSupporters = useMemo(
    () => getTeamSupporters(match, match.away_team.person_roles),
    [match]
  );

  const { team_id } = useContext(RoleContext);

  const { isCoachOfTeam } = useHasTeam();

  return (
    <Fragment>
      <Wrapper>
        <Tabs
          defaultIndex={team_id === match.away_team.id ? 1 : 0}
          onSelect={(index) => {
            onChangeTeam && onChangeTeam(index ? match.away_team.id : match.home_team.id);
          }}
        >
          <StyledTabList>
            <StyledTab data-testid={'tab-team-' + match.home_team.id}>
              <StyledTabTitle>{match.home_team.name}</StyledTabTitle>
            </StyledTab>
            <StyledTab data-testid={'tab-team-' + match.away_team.id}>
              <StyledTabTitle>{match.away_team.name}</StyledTabTitle>
            </StyledTab>
          </StyledTabList>
          <StyledTabPanel>
            <StyledPlayers
              user={user}
              club={match.home_team.club.id}
              teamId={match.home_team.id}
              players={homeTeamPlayers}
              onJerseyNumberChange={(id, jerseyNumber) => onJerseyNumberChange(id, jerseyNumber)}
              toggleMatchPlayer={(id) => toggleMatchPlayer(id, match.home_team.id)}
              matchRefereeConfirmed={match.referee_confirmation}
              jerseyNumbersActive={jerseyNumbersActive(match)}
              isCoachOfTeam={isCoachOfTeam(match.home_team.id)}
            />
            <StyledTeamSupporters title={<StyledTeamSupportersTitle />}>
              {homeTeamTeamSupporters}
            </StyledTeamSupporters>
          </StyledTabPanel>
          <StyledTabPanel>
            <StyledPlayers
              user={user}
              club={match.away_team.club.id}
              players={awayTeamPlayers}
              onJerseyNumberChange={(id, jerseyNumber) => onJerseyNumberChange(id, jerseyNumber)}
              toggleMatchPlayer={(id) => toggleMatchPlayer(id, match.away_team.id)}
              matchRefereeConfirmed={match.referee_confirmation}
              jerseyNumbersActive={jerseyNumbersActive(match)}
              teamId={match.away_team.id}
              isCoachOfTeam={isCoachOfTeam(match.away_team.id)}
            />
            <StyledTeamSupporters title={<StyledTeamSupportersTitle />}>
              {awayTeamTeamSupporters}
            </StyledTeamSupporters>
          </StyledTabPanel>
        </Tabs>
      </Wrapper>
    </Fragment>
  );
};

export default Playerlist;
