import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledScore = styled.div`
  padding: ${theme.sizing.xsmall} ${theme.sizing.medium} 4px ${theme.sizing.medium};
  background-color: ${theme.color.gray.alt};
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: ${theme.sizing.large};
  min-width: 24px;
  text-align: center;

  ${(props) => (props.higher ? `background-color: ${theme.color.gray.alt};` : ``)};
`;

export const Score = (props) => <StyledScore {...props} />;
