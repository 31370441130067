import React from 'react';
import { theme } from '../../templates/ui';

type Props = React.SVGAttributes<SVGElement>;

const PenaltyCardsMatchHeader = ({ className }: Props) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M 5.222656 0 L 16 0 L 16 9.996094 L 5.222656 9.996094 Z M 5.222656 0 "
      fill={theme.color.cards.yellow}
    />
    <path
      d="M 4.523438 10.664062 L 4.523438 2.652344 C 1.894531 3.492188 0 5.867188 0 8.664062 C 0 12.164062 2.960938 15 6.613281 15 C 9.535156 15 12.015625 13.183594 12.890625 10.664062 Z M 4.523438 10.664062 "
      fill={theme.color.cards.red}
    />
  </svg>
);

export default PenaltyCardsMatchHeader;
