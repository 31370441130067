import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const ChevronDown = ({ ...props }: Props) => (
  <svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.642.174a.229.229 0 0 1 .321 0l6.512 6.53a.228.228 0 0 0 .322 0l6.53-6.53a.226.226 0 0 1 .319 0l1.295 1.308a.229.229 0 0 1 0 .321l-5.069 5.06L8.79 9.94a.227.227 0 0 1-.321 0L.339 1.797a.229.229 0 0 1 0-.322L1.642.174z" />
  </svg>
);

export default ChevronDown;
