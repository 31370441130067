import React, { useContext, useEffect, useState } from 'react';
import { produce } from 'immer';
import { useHistory } from 'react-router-dom';
import { styled } from 'styled-components';
import { cancelMatch } from '../../../api/matches';
import ErrorContext, { withErrorHandler } from '../../../contexts/ErrorContext';
import MatchContext, { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import ChevronRight from '../../atoms/Icons/ChevronRight';
import Clock from '../../atoms/Icons/Clock';
import IconRain from '../../atoms/Icons/Rain';
import { Input } from '../../atoms/Input';
import { Radio } from '../../atoms/Radio';
import { Select } from '../../atoms/Select';
import { Textarea } from '../../atoms/Textarea';
import { Wrapper } from '../../atoms/Wrapper';
import IconButton from '../../molecules/IconButton';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledInput = styled(Input)`
  margin-bottom: ${theme.sizing.xlarge};

  &:focus {
    margin-bottom: ${theme.sizing.xlarge};
  }
`;

const StyledClock = styled(Clock)`
  fill: ${theme.color.primary.base};
  width: 23px;
  height: 23px;
`;

const StyledChevron = styled(ChevronRight)`
  fill: ${theme.color.body.base};
`;

const StyledSelect = styled(Select)`
  margin-top: ${theme.sizing.xlarge};
`;

const StyledParagraph = styled.p`
  color: ${theme.color.error.base};
`;

const StyledRadio = styled(Radio)`
  input[type='radio'] + label {
    width: 110px;
    height: auto;
    justify-content: center;
    user-select: none;
  }

  input[type='radio']:checked + label {
    font-weight: 700;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const StyledLabel = styled.h3`
  color: ${theme.color.gray.darkest};
  font-size: ${theme.font.large};
  font-weight: 400;
  padding-bottom: ${theme.sizing.small};
  margin-top: ${theme.sizing.xlarge};
  margin-bottom: ${theme.sizing.small};
`;

const StyledIconRain = styled(IconRain)`
  fill: ${theme.color.primary.base};
  width: 23px;
  height: 23px;
`;

const SyledScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${theme.sizing.xlarge};
`;

const StyledScoreInput = styled(Input)`
  min-width: 125px;
`;

const StyledScoreSeperator = styled.div`
  &::before {
    content: '-';
  }

  display: flex;
  align-items: center;
  padding: 0 ${theme.sizing.xlarge};
  font-size: ${theme.font.xxxxlarge};
`;

const STOPPED_CONST = 'stopped';

export const CANCELLATION_CONST = 'cancellation';

const initialState = {
  reason: '',
  comment: '',
  date: '',
  minute: null,
  homeScore: null,
  awayScore: null,
  errors: false,
};

const CancellationPage = ({ matchGame }) => {
  const [state, setState] = useState({
    ...initialState,
    loading: false,
    cancellationType: null,
  });

  const { match, updateMatch } = useContext(MatchContext);
  const { role } = useContext(RoleContext);
  const history = useHistory();
  const { showGenericError } = useContext(ErrorContext);

  // Reset state after cancellation
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      ...initialState,
    }));
  }, [state.cancellationType]);

  const cancelMatchCallback = async (e) => {
    e.preventDefault();
    setState((prevState) => ({ ...prevState, loading: true }));
    try {
      const id = await cancelMatch(
        matchGame.uuid,
        state.reason,
        state.comment,
        state.date,
        state.minute,
        state.homeScore,
        state.awayScore,
        role,
        state.cancellationType,
        'match-cancellation'
      );

      const matchCancelled = produce(match, (draft) => {
        const newCancellations = draft.match_cancellations || [];

        newCancellations.push({
          id,
          reason: state.reason,
          minute: state.minute,
        });

        draft.match_cancellations = newCancellations;
      });
      updateMatch(matchCancelled, null, 'match-cancelled');

      history.push(`/matches/${matchGame.uuid}`);
    } catch (e) {
      setState((prevState) => ({
        ...prevState,
        errors: e.response.data.errors,
      }));

      if (e.response.status === 400) {
        history.push(`/matches`);

        return;
      }

      showGenericError(e);
    }
    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const isCancellation = () => {
    return state.cancellationType === CANCELLATION_CONST;
  };

  return (
    <>
      <TopBar>Wedstrijd status</TopBar>
      <Wrapper>
        {state.errors && (
          <>
            {Object.keys(state.errors).map((key) => {
              return <StyledParagraph key={key}>{state.errors[key][0]}</StyledParagraph>;
            })}
          </>
        )}
        <form onSubmit={cancelMatchCallback}>
          <StyledLabel>Wil je de wedstrijd afgelasten of staken?</StyledLabel>
          <RadioWrapper>
            <StyledRadio
              radioId={1}
              name="type"
              value={CANCELLATION_CONST}
              selected={isCancellation()}
              onChange={(e) => {
                const cancellationType = e.target.value;
                setState((prevState) => ({ ...prevState, cancellationType }));
              }}
            >
              Afgelasten
            </StyledRadio>
            <StyledRadio
              radioId={2}
              value={STOPPED_CONST}
              name="type"
              selected={!isCancellation()}
              onChange={(e) => {
                const cancellationType = e.target.value;
                setState((prevState) => ({ ...prevState, cancellationType }));
              }}
            >
              Staken
            </StyledRadio>
          </RadioWrapper>
          {state.cancellationType && (
            <>
              <StyledSelect
                onChange={(e) => {
                  const reason = e.target.value;
                  setState((prevState) => ({
                    ...prevState,
                    reason,
                  }));
                }}
                icon={<StyledIconRain />}
                value={state.reason}
                data-testid="match-cancellation-reason"
                required
              >
                <option value="">Selecteer een reden</option>
                {isCancellation() && (
                  <>
                    <option>Er zijn geen twee scheidsrechters</option>
                    <option>Eerst genoemde team is niet aanwezig</option>
                    <option>Tweede genoemde team is niet aanwezig</option>
                    <option>Beide teams zijn niet aanwezig</option>
                    <option>Onvoldoende spelers aanwezig van eerst genoemde team</option>
                    <option>Onvoldoende spelers aanwezig van tweede genoemde team</option>
                    <option>Beide teams hebben te weinig spelers</option>
                  </>
                )}
                <option>Veld onbespeelbaar door regen</option>
                <option>Veld onbespeelbaar door sneeuw/hagel</option>
                <option>Veld onbespeelbaar door vorst</option>
                <option>Veld onbespeelbaar door andere omstandigheden</option>
                {!isCancellation() && (
                  <>
                    <option>Wangedrag eerst genoemde team</option>
                    <option>Wangedrag tweede genoemde team</option>
                    <option>Andere omstandigheden</option>
                  </>
                )}
              </StyledSelect>
              <Textarea
                placeholder="Opmerkingen"
                rows="4"
                onChange={(e) => {
                  const comment = e.target.value;
                  setState((prevState) => ({ ...prevState, comment }));
                }}
                name="comment"
                value={state.comment}
                data-testid="match-cancellation-comment"
              />
              {state.cancellationType === STOPPED_CONST && (
                <>
                  <StyledInput
                    placeholder={'Minuut van staking'}
                    type="number"
                    icon={<StyledClock />}
                    onChange={(e) => {
                      const minute = e.target.value;
                      setState((prevState) => ({ ...prevState, minute }));
                    }}
                    name="minute"
                    data-testid="input-minute"
                    min="1"
                    max="120"
                    value={state.minute}
                  />
                  <SyledScoreWrapper>
                    <StyledScoreInput
                      placeholder={'Thuis score'}
                      type="number"
                      min="0"
                      max="99"
                      onChange={(e) => {
                        const homeScore = e.target.value;
                        setState((prevState) => ({
                          ...prevState,
                          homeScore,
                        }));
                      }}
                      name="homeScore"
                      value={state.homeScore}
                      data-testid="home-score"
                    />
                    <StyledScoreSeperator />
                    <StyledScoreInput
                      placeholder={'Uit score'}
                      type="number"
                      min="0"
                      max="99"
                      onChange={(e) => {
                        const awayScore = e.target.value;
                        setState((prevState) => ({ ...prevState, awayScore }));
                      }}
                      name="awayScore"
                      value={state.awayScore}
                      data-testid="away-score"
                    />
                  </SyledScoreWrapper>
                </>
              )}
            </>
          )}
          <IconButton
            icon="chevron-right"
            disabled={state.loading || !state.cancellationType}
            data-testid="match-cancellation-button"
            type="submit"
          >
            Wedstrijd {isCancellation() ? 'afgelasten' : 'staken'}
            <StyledChevron />
          </IconButton>
        </form>
      </Wrapper>
    </>
  );
};

export const Cancellation = withErrorHandler(withMatch(CancellationPage));
