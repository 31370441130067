import { dwf_api_endpoint } from '../config/api';
import Client from './Client';

export const store = (matchUuid, data, pageKey) => {
  return Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/comments`,
    data,
    null,
    pageKey,
    'comments.store'
  );
};

export const update = (matchUuid, commentId, data, pageKey) => {
  return Client.put(
    `${dwf_api_endpoint}/matches/${matchUuid}/comments/${commentId}`,
    data,
    null,
    pageKey,
    'comments.update'
  );
};

export const destroy = (matchUuid, commentId, data, pageKey) => {
  return Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/comments/${commentId}`,
    data,
    null,
    pageKey,
    'comments.destroy'
  );
};
