import React from 'react';
import { styled } from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const Layout = ({ children, className }) => (
  <Wrapper className={className}>{children}</Wrapper>
);
