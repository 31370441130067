import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import ChevronRight from '../Icons/ChevronRight';

const StyledIcon = styled.div`
  svg {
    fill: ${theme.color.primary.base};
    width: 12px;
    height: 21px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: ${theme.sizing.small};
  padding: 0;
`;

const StyledListItem = styled.li`
  padding: ${theme.sizing.xlarge} 0;
  border-bottom: 1px solid ${theme.color.gray.light};
  display: flex;
  justify-content: space-between;
  color: ${theme.color.gray.darkest};
  font-weight: 700;
  font-size: ${theme.font.large};
`;

const StyledLink = styled(Link)`
  color: ${theme.color.gray.darkest};
  text-decoration: none;
`;

export const NumberList = () => {
  return (
    <StyledList>
      <StyledLink to="/faq/introduction">
        <StyledListItem>
          1. Introductie
          <StyledIcon>
            <ChevronRight />
          </StyledIcon>
        </StyledListItem>
      </StyledLink>
      <StyledLink to="/faq/referee">
        <StyledListItem>
          2. Voor scheidsrechters
          <StyledIcon>
            <ChevronRight />
          </StyledIcon>
        </StyledListItem>
      </StyledLink>
      <StyledLink to="/faq/coach">
        <StyledListItem>
          3. Voor coaches, managers en aanvoerders
          <StyledIcon>
            <ChevronRight />
          </StyledIcon>
        </StyledListItem>
      </StyledLink>
      <StyledLink to="/faq/legend">
        <StyledListItem>
          4. Legenda
          <StyledIcon>
            <ChevronRight />
          </StyledIcon>
        </StyledListItem>
      </StyledLink>
    </StyledList>
  );
};
