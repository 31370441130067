import moment from 'moment';
import { getPersonType, isUserTeam } from './user';

export const getTeamById = (match, id) => {
  if (match.home_team.id === id) {
    return {
      team: match.home_team,
      type: 'home_team',
    };
  }

  if (match.away_team.id === id) {
    return {
      team: match.away_team,
      type: 'away_team',
    };
  }

  return null;
};

export const matchHasStarted = (match) => {
  return moment(match.date).toDate() <= moment().toDate();
};

export const matchIsDraw = (match) => {
  const result = match && !!(match.home_score === match.away_score) && matchHasScore(match);

  return result;
};

export const matchHasScore = (match) => {
  const result =
    match &&
    !!(
      (match.home_score || match.home_score === 0) &&
      (match.away_score || match.away_score === 0)
    );

  return result;
};

export const getApprovalColumnForUserByRole = (match, user, team_id = null, role) => {
  const personType = getPersonType(user, match, team_id, role);

  if (personType === 'home_coach') {
    return 'home_team_approval';
  }

  if (personType === 'away_coach') {
    return 'away_team_approval';
  }

  if (personType === 'referee') {
    return 'referee_confirmation';
  }
};

export const matchMayNotBeUpdated = (match, user) => {
  let refereeCount = 0;
  let confirmedBySelf = false;

  match.match_approvals.forEach((matchApproval) => {
    if (matchApproval.person.id === user.id) {
      confirmedBySelf = true;
    }

    if (matchApproval.by_referee) {
      refereeCount++;
    }
  });

  // If there are at least 2 referee approvals, no one may update the match
  if (refereeCount > 1) {
    return true;
  }

  // If the current user approved the match, he may still update the match
  if (confirmedBySelf) {
    return false;
  }

  // If the match is approved the normal way, no one may update the match
  if (match.referee_confirmation) {
    return true;
  }

  return false;
};

export const hasSecretaryConfirmationActions = (match, user) => {
  return (
    numberOfRefereesConfirmed(match) < 2 ||
    (!match.home_team_approval && isUserTeam(user.team_id, match.home_team.id)) ||
    (!match.away_team_approval && isUserTeam(user.team_id, match.away_team.id))
  );
};

export const matchIsConfirmedByUser = (match, user) => {
  return match.home_team_approval && match.away_team_approval && isConfirmedBySelf(match, user);
};

export const matchCanHaveCards = (match) => {
  return match.dwf_options.coach_can_enter_score !== 1;
};

export const isConfirmedBySelf = (match, user) => {
  let confirmedBySelf = false;
  match.match_approvals.forEach((matchApproval) => {
    if (matchApproval.person.id === user.id) {
      confirmedBySelf = true;
    }
  });

  return confirmedBySelf;
};

export const isLocked = (match) => match.locked;

export const isExpired = (match) => match.expired;

export const isConfirmedByReferee = (match) => !!match.referee_confirmation;

export const isCancelled = (match) =>
  match.match_cancellations && match.match_cancellations.length > 0;

export const isClosed = (match) => moment().toDate() > moment(match.closes_at).toDate();

export const isOpen = (match) => moment().toDate() < moment(match.closes_at).toDate();

export const isOpenForSecretary = (match) => {
  const amountOfDaysToSubtractFromMatchDateForSecretaryFunctions = 1;
  const secretaryAllowedDate = moment(match.date)
    .subtract(amountOfDaysToSubtractFromMatchDateForSecretaryFunctions, 'days')
    .hour(0)
    .minute(0);

  return (
    moment().toDate() > secretaryAllowedDate.toDate() &&
    moment().toDate() < moment(match.closes_at).toDate()
  );
};

export const numberOfRefereesConfirmed = (match) => {
  if (!match.match_approvals) {
    return 0;
  }

  return match.match_approvals.filter((approval) => approval.by_referee).length;
};

export const toggleMatchPlayer = (match, clubMemberId, teamId, matchPlayerId) => {
  if (getMatchPlayer(match, clubMemberId)) {
    delete match.match_players[clubMemberId.toString()];

    return;
  }

  const foundTeam = getTeamById(match, teamId);
  if (!foundTeam) {
    return;
  }

  match.match_players[clubMemberId.toString()] = {
    ...foundTeam.team.players[clubMemberId.toString()],
    id: matchPlayerId,
  };
};

export const getMatchPlayer = (match, clubMemberId) => {
  const matchPlayer = match.match_players[clubMemberId.toString()];
  if (matchPlayer) {
    return matchPlayer.id;
  }

  return 0;
};
