import { Person } from '../@types/Person';
import { getObject, insertObject } from './localStorage';

export const savePerson = (person: Person): boolean => {
  if (!person) {
    return false;
  }

  insertObject('person', person);

  return true;
};

export const getPerson = (): Person => getObject('person');

export const mergeUserAndPerson = (user, person) => ({
  ...user,
  ...person,
});
