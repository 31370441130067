import React from 'react';
import Modal from 'react-awesome-modal';
import { styled } from 'styled-components';
import { withUser } from '../../contexts/UserContext';
import { useHasRole } from '../../hooks/useHasRole';
import { useHasTeam } from '../../hooks/useHasTeam';
import { useMatch } from '../../hooks/useMatch';
import { Button } from '../atoms/Button';
import Cross from '../atoms/Icons/Cross';
import { theme } from '../templates/ui';

const StyledModalHeader = styled.div`
  position: relative;
  background-color: ${theme.color.primary.base};
  border-radius: 4px 4px 0 0;
  width: 100%;
  padding-left: ${theme.sizing.large};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledModalCloseButton = styled(Button)`
  background-color: transparent;
  &:hover,
  &:focus {
    background-color: transparent;
  }

  svg {
    fill: ${theme.color.gray.white};
  }
`;

const StyledModalContent = styled.div`
  padding: ${theme.sizing.large};
`;

const StyledModalTitle = styled.h3`
  margin: 0;
  color: ${theme.color.gray.white};
`;

const StyledApprovalOption = styled(Button)`
  font-size: ${theme.font.medium};
  display: block;
  width: 100%;
  margin-bottom: ${theme.sizing.large};

  &:last-child {
    margin: 0;
  }
`;

const StyledSuperUserComfirmationButton = styled(StyledApprovalOption)`
  background-color: ${theme.color.primary.base};

  &:hover,
  &:focus {
    background-color: ${theme.color.primary.hover};
  }
`;

const ModalMatchConfirmation = ({
  matchGame,
  isVisible,
  onModalClose,
  handleMatchConfirmationAs,
  handleMatchConfirmSuperuser,
  handleMatchConfirmationAsAll,
  handleMatchConfirmationAsSecretary,
}) => {
  const { isSecretary, isSuperUser } = useHasRole();
  const { numberOfRefereesConfirmed } = useMatch();
  const { isTeam } = useHasTeam();

  return (
    <Modal
      visible={isVisible}
      width="320"
      effect="fadeInUp"
      onClickAway={() => onModalClose()}
      data-testid="modal-superuser"
    >
      <StyledModalHeader>
        <StyledModalTitle>Wat wil je doen?</StyledModalTitle>
        <StyledModalCloseButton onClick={() => onModalClose()} data-testid="modal_superuser_close">
          <Cross />
        </StyledModalCloseButton>
      </StyledModalHeader>
      <StyledModalContent>
        {numberOfRefereesConfirmed < 1 && (
          <StyledApprovalOption
            onClick={() => handleMatchConfirmationAs('referee')}
            data-testid="superuser_confirm_s1_button"
          >
            Wedstrijd afronden als scheidsrechter 1
          </StyledApprovalOption>
        )}
        {numberOfRefereesConfirmed < 2 && (
          <StyledApprovalOption
            onClick={() => handleMatchConfirmationAs('referee')}
            data-testid="superuser_confirm_s2_button"
          >
            Wedstrijd afronden als scheidsrechter 2
          </StyledApprovalOption>
        )}
        {!matchGame.home_team_approval &&
          (isSuperUser || (isSecretary && isTeam(matchGame.home_team.id))) && (
            <StyledApprovalOption
              onClick={() => handleMatchConfirmationAs('home_coach')}
              data-testid="superuser_confirm_hometeam_button"
            >
              Formulier goedkeuren namens thuisteam
            </StyledApprovalOption>
          )}
        {!matchGame.away_team_approval &&
          (isSuperUser || (isSecretary && isTeam(matchGame.away_team.id))) && (
            <StyledApprovalOption
              onClick={() => handleMatchConfirmationAs('away_coach')}
              data-testid="superuser_confirm_awayteam_button"
            >
              Formulier goedkeuren namens uitteam
            </StyledApprovalOption>
          )}
        {(!matchGame.home_team_approval ||
          !matchGame.away_team_approval ||
          numberOfRefereesConfirmed !== 2) && (
          <StyledApprovalOption
            onClick={() => {
              if (isSuperUser) {
                handleMatchConfirmationAsAll();
              } else {
                handleMatchConfirmationAsSecretary();
              }
            }}
            data-testid="superuser_confirm_all_button"
          >
            Alle bovenstaande stappen afronden
          </StyledApprovalOption>
        )}
        {isSuperUser && (
          <StyledSuperUserComfirmationButton
            onClick={() => {
              handleMatchConfirmSuperuser();
            }}
            data-testid="superuser_confirm_button"
          >
            Wedstrijd afronden als superuser
          </StyledSuperUserComfirmationButton>
        )}
      </StyledModalContent>
    </Modal>
  );
};

export default withUser(ModalMatchConfirmation);
