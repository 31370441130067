import React from 'react';
import { styled } from 'styled-components';
import { Popup } from '../atoms/Popup';
import { theme } from '../templates/ui';

const List = styled.dl`
  display: flex;
  margin-bottom: ${theme.sizing.large};
`;

const Label = styled.dt`
  font-weight: 700;
  color: ${theme.color.base.base};
  font-size: ${theme.font.medium};
  min-width: 100px;
`;

const Value = styled.dd`
  font-weight: 400;
  color: ${theme.color.gray.darkest};
  font-size: ${theme.font.medium};
`;

const Title = styled.h3`
  color: ${theme.color.primary.base};
  font-weight: 700;
  font-size: ${theme.font.base};
  margin: 0;
`;

const MatchInfoPopup = ({ showPopup, onClick, matchInfo = null, isLoading = !matchInfo }) => (
  <Popup showPopup={showPopup} onClick={onClick} isLoading={isLoading}>
    <Title>Wedstrijdinformatie</Title>
    {matchInfo && matchInfo.facility_official ? (
      <List>
        <Label>Zaalleiding</Label>
        <Value>{matchInfo.facility_official}</Value>
      </List>
    ) : null}
    {matchInfo && matchInfo.address ? (
      <List>
        <Label>Adresgegevens</Label>
        <Value>{matchInfo.address}</Value>
      </List>
    ) : null}
    {matchInfo && matchInfo.type ? (
      <List>
        <Label>Type</Label>
        <Value>{matchInfo.type}</Value>
      </List>
    ) : null}
    {matchInfo && matchInfo.field ? (
      <List>
        <Label>Veld</Label>
        <Value>
          {matchInfo.field}
          {matchInfo.subfield && `, ${matchInfo.subfield}`}
        </Value>
      </List>
    ) : null}
    {matchInfo && matchInfo.referee ? (
      <List>
        <Label>Scheidsrechters</Label>
        <Value>{matchInfo.referee}</Value>
      </List>
    ) : null}
  </Popup>
);

export default MatchInfoPopup;
