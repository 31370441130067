import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledAnnouncementContent = styled.div`
  font-family: ${theme.font.family};
  font-size: ${theme.font.medium};
  color: ${theme.color.gray.darkest};
  padding-top: ${theme.sizing.large};

  &:not(:last-child) {
    border-bottom: solid rgba(255, 125, 34, 0.6) 1px;
    padding-bottom: ${theme.sizing.large};
  }
`;

const AnnouncementTitle = styled.span`
  display: block;
  color: ${theme.color.primary.base};
  font-weight: 400;
  font-size: ${theme.font.medium};
`;

export const AnnouncementContent = ({ children, federationName, ...props }) => (
  <StyledAnnouncementContent {...props}>
    {federationName ? (
      <AnnouncementTitle>Mededeling {federationName}</AnnouncementTitle>
    ) : (
      <AnnouncementTitle>Mededeling club</AnnouncementTitle>
    )}
    {children}
  </StyledAnnouncementContent>
);
