import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  PERMISSIONS,
  PERMISSIONS_EXPIRE,
  PERMISSIONS_GLOBAL,
  PermissionType,
  PermissionsOptionsDefault,
  PermissionsOptionsType,
  RoleType,
} from '../../@types/Permissions';
import { useMatch } from '../../hooks/useMatch';
import { usePrevious } from '../../hooks/usePrevious';
import MatchContext from '../MatchContext';
import { RolesContext } from '../RolesContext/RolesContext';

export type RoleContextType = {
  isLoading: boolean;
  role: RoleType | null;
  permissions?: PermissionType[];
  team_id: number | null;
  hasPermissions: (_permissions: PermissionType[], _options?: PermissionsOptionsType) => boolean;
  teams?: number[];
  setRole: Dispatch<SetStateAction<RoleType | null>>;
  roleManuallySelected: boolean;
  setRoleManuallySelected: Dispatch<SetStateAction<boolean>>;
};

const defaultState = {
  isLoading: true,
  role: null,
  hasPermissions: () => {
    return false;
  },
  permissions: [],
  team_id: null,
  setRole: () => undefined,
  roleManuallySelected: false,
  setRoleManuallySelected: () => undefined,
};

export const RoleContext = createContext<RoleContextType>(defaultState);

export function useRole() {
  const context = useContext(RoleContext);

  if (!context) {
    throw new Error('useRole must be used within a RoleContextProvider');
  }

  return context;
}

/* Check if the requiredPermission exists in the permissions array */
export const hasPermissionCheck = (
  permissions: PermissionType[],
  requiredPermission: PermissionType
) => {
  return permissions.indexOf(requiredPermission) >= 0;
};

/* Check if the requiredTeamId is the currently selected teamId  */
export const isTeam = (teamId, requiredTeamId) => {
  return teamId === requiredTeamId;
};

/* Check if the permission is a global permission */
export const isGlobalPermission = (permission: PermissionType) => {
  return PERMISSIONS_GLOBAL.includes(permission);
};

export const hasPermission = (
  role: RoleType,
  permission: PermissionType,
  options: PermissionsOptionsType
) => {
  // No roles returned from endpoint
  if (!role) {
    return false;
  }

  const permissionGranted = hasPermissionCheck(role.permissions, permission);

  // Permission is global and does not need a team id
  if (isGlobalPermission(permission)) {
    return permissionGranted;
  }

  // Permission cannot be determined
  if (!options.teamId && permissionGranted && process.env.NODE_ENV === 'development') {
    console.warn(`The permission "${permission}" requires a team id, but it was not set.`);

    return false;
  }

  return permissionGranted && !!role.team_id && isTeam(role.team_id, options.teamId);
};

export const hasPermissionsCheck = (
  role: RoleType,
  permissions: PermissionType[],
  options?: PermissionsOptionsType
) => {
  let mergedOptions = PermissionsOptionsDefault;

  // Set custom options
  const customOptions = options ?? {};

  // Merge options with defaults
  mergedOptions = { ...PermissionsOptionsDefault, ...customOptions };

  // Check every permission to see if it has been denied or permitted
  const results = permissions.map((permission) => hasPermission(role, permission, mergedOptions));

  // At least one permission granted
  if (mergedOptions.policy === 'some') {
    return results.includes(true);
  }

  // No permissions denied
  return !results.includes(false);
};

export const RoleContextProvider = (props: { children: ReactNode }) => {
  const [role, setRole] = useState<RoleType | null>(null);
  const [roleManuallySelected, setRoleManuallySelected] = useState<boolean>(false);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const {
    isLoading: isLoadingRoles,
    roles: matchRoles,
    matchUuid,
    setMatchUuid,
    setRoleSwitchModalActive,
  } = useContext(RolesContext);
  const prevMatchRoles = usePrevious(matchRoles);

  const { match } = useContext(MatchContext);

  const { isOpen } = useMatch();

  useEffect(() => {
    setIsLoading(isLoadingRoles);
  }, [isLoadingRoles]);

  /*
   * If we don't have a match, we're on the match page and
   * we did get there directly via the URL, so we need to fetch the roles.
   * If we came via the overview page, we would already have the roles and a match.
   */

  const routeMatch: any = useRouteMatch('/matches/:id');

  const setMatchUuidFromRoute = useCallback(() => {
    if (!matchUuid) {
      const uuid = routeMatch?.params?.id;
      setMatchUuid(uuid);
    }
  }, [routeMatch]);

  // Set match uuid from route on mount of component
  useEffect(() => {
    setMatchUuidFromRoute();
  }, []);

  // Set match uuid from route on route change (via verify code flow)
  useEffect(() => {
    setMatchUuidFromRoute();
  }, [routeMatch]);

  /*
   * Determine the role to use for the match or to show the modal.
   */

  useEffect(() => {
    if (matchRoles && !isEqual(prevMatchRoles, matchRoles)) {
      // Duplicate roles object to avoid mutation
      const matchRolesClone = [...matchRoles];

      // Reset flag
      setRoleManuallySelected(false);

      // No roles available
      if (matchRolesClone.length === 0) {
        setRole(null);
        setIsLoading(false);
        history.push(`/404`);

        return;
      }

      // Set the default role (first role)
      const role = matchRolesClone.shift();
      setRole(role);

      // No role remaining (^^^^^ as the array shifted)
      if (matchRolesClone.length == 0) {
        setRoleSwitchModalActive(false);

        // Redirect to the match if we came from the index page
        if (!routeMatch) {
          history.push(`/matches/${matchUuid}`);
        }
      }

      // Show modal to allow the user to select a role or team
      if (matchRolesClone.length >= 1) {
        setRoleSwitchModalActive(true);
      }

      setIsLoading(false);
    }
  }, [matchRoles]);

  /*
   * Select the permissions for the currently active role and listen
   * for changes in the role.
   */

  const permissions = useMemo(() => {
    if (role) {
      return role.permissions;
    }

    return [];
  }, [role]);

  /*
   * Select the teams for the currently active role and listen
   * for changes in the role.
   */

  const team_id = useMemo(() => {
    if (role) {
      return role.team_id;
    }

    return null;
  }, [role]);

  const permissionsAreActive = useCallback(
    (permissionsToCheck) => {
      // No match available or no closes_at defined, assume global permissions
      if (!match || !match.closes_at) {
        return true;
      }

      // Check if there are permissions which are time-sensitive
      const hasPermissionsThatExpire =
        permissionsToCheck.filter((p) => PERMISSIONS_EXPIRE.indexOf(p) >= 0).length > 0;

      // If the match is still open, we don't need to check if the permissions are expired
      const permissionsAreActive =
        isOpen ||
        // However, if the match is NOT open we need to check if the user has the CORRECT_AFTER_CLOSING permission
        // which allows them to still modify the match
        (hasPermissionsThatExpire && permissions.indexOf(PERMISSIONS.CORRECT_AFTER_CLOSING) >= 0);

      return !hasPermissionsThatExpire || permissionsAreActive;
    },
    [match]
  );

  const hasPermissions = useCallback(
    (permissions: PermissionType[], options?: PermissionsOptionsType) => {
      if (!role) {
        return false;
      }

      const result =
        permissionsAreActive(permissions) && hasPermissionsCheck(role, permissions, options);

      return result;
    },
    [role, match]
  );

  return (
    <RoleContext.Provider
      {...props}
      value={{
        role,
        permissions,
        team_id,
        hasPermissions,
        setRole,
        isLoading,
        roleManuallySelected,
        setRoleManuallySelected,
      }}
    />
  );
};
