import { CARD_COLORS } from '../@types/Cards';

export const getGivenCardsForPlayer = (clubMemberId, match, color) => {
  return match.match_cards.filter((card) => {
    return card.club_member.id === clubMemberId && (color ? card.color === color : true);
  });
};

export const getCardsForTeamId = (teamId, cards) => {
  return cards.filter((card) => card.team.id === teamId);
};

export const sortCards = (cards) =>
  cards.sort(({ color: colorA }, { color: colorB }) => {
    if (colorA === colorB) return 0;

    if ([CARD_COLORS.YELLOW, CARD_COLORS.RED].includes(colorA) && colorB === CARD_COLORS.GREEN)
      return 1;

    if (colorA === CARD_COLORS.GREEN && colorB === CARD_COLORS.YELLOW) return -1;
    if (colorA === CARD_COLORS.RED && colorB === CARD_COLORS.YELLOW) return 1;

    return -1;
  });
