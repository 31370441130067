import React from 'react';
import { NumberList } from '../../atoms/NumberList';
import { Wrapper } from '../../atoms/Wrapper';
import TopBar from '../../molecules/TopBar';

export const Faq = () => (
  <>
    <TopBar>Hoe werkt het DWF</TopBar>
    <Wrapper>
      <NumberList />
    </Wrapper>
  </>
);
