import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'styled-components';
import ChevronRight from '../atoms/Icons/ChevronRight';
import { theme } from '../templates/ui';

const StyledIconlink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: ${theme.color.primary.base};
  fill: ${theme.color.primary.base};
  font-size: ${theme.font.small};
  font-weight: 700;
  margin-left: auto;
  padding-right: 14px;

  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
  }
`;

const IconLink = ({ className, children, to, ...props }) => (
  <StyledIconlink to={to} className={className} {...props}>
    {children}
    <ChevronRight />
  </StyledIconlink>
);

export default IconLink;
