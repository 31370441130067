import { dwf_api_endpoint } from '../config/api';
import Client from './Client';

export const store = (matchUuid, data, role, pageKey) => {
  return Client.post(
    `${dwf_api_endpoint}/matches/${matchUuid}/cards`,
    {
      ...data,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'cards.store'
  );
};

export const destroy = (matchUuid, cardId, role, pageKey) => {
  return Client.delete(
    `${dwf_api_endpoint}/matches/${matchUuid}/cards/${cardId}`,
    {
      as_person_role_id: role.role_id,
    },
    pageKey,
    'cards.destroy'
  );
};

export const update = (matchUuid, cardId, data, role, pageKey) => {
  return Client.patch(
    `${dwf_api_endpoint}/matches/${matchUuid}/cards/${cardId}`,
    {
      ...data,
      as_person_role_id: role.role_id,
    },
    null,
    pageKey,
    'cards.update'
  );
};
