import React from 'react';
import { styled } from 'styled-components';
import MatchMeta from '../molecules/MatchMeta';
import MatchTeams from '../molecules/MatchTeams';
import { theme } from '../templates/ui';

const StyledMatch = styled.div`
  padding-top: ${theme.sizing.xlarge};
`;

const MatchDetails = ({
  homeTeam,
  awayTeam,
  date,
  matchId,
  matchCode,
  homeScore,
  awayScore,
  homeShootoutScore,
  awayShootoutScore,
  homeCoachApproved,
  awayCoachApproved,
  commentsCount,
  cancellation,
  approvals,
  matchUuid,
  matchGame,
}) => (
  <StyledMatch data-testid={`match-${matchId}`}>
    <MatchTeams
      homeTeam={homeTeam}
      awayTeam={awayTeam}
      homeScore={homeScore}
      awayScore={awayScore}
      homeShootoutScore={homeShootoutScore}
      awayShootoutScore={awayShootoutScore}
      homeCoachApproved={homeCoachApproved}
      awayCoachApproved={awayCoachApproved}
      matchGame={matchGame}
    />
    <MatchMeta
      matchId={matchId}
      date={date}
      matchCode={matchCode}
      commentsCount={commentsCount}
      cancellation={cancellation}
      approvals={approvals}
      matchUuid={matchUuid}
      matchGame={matchGame}
      compact={true}
    />
  </StyledMatch>
);

export default MatchDetails;
