import React, { Fragment, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { styled } from 'styled-components';
import { AnnouncementContent } from '../atoms/AnnouncementContent';
import Link from '../atoms/Icons/Link';
import { Wrapper } from '../atoms/Wrapper';
import AnnouncementToggle from '../molecules/AnnouncementToggle';
import { theme } from '../templates/ui';

const AnnouncementWrapper = styled.div`
  background-color: rgba(239, 121, 12, 0.1);
  border: 1px solid rgba(255, 125, 34, 0.6);
  border-radius: 3px;
  padding: ${theme.sizing.large};
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: ${theme.sizing.large} 0;
`;

const StyledLink = styled.a`
  margin-left: ${theme.sizing.small};
  padding-bottom: ${theme.sizing.xsmall};
  text-decoration: none;
  border-bottom: 1px dashed ${theme.color.gray.darker};
  color: ${theme.color.gray.darkest};
`;

const StyledIconLink = styled(Link)`
  width: 11px;
  height: 11px;
  margin-top: ${theme.sizing.xsmall};
`;

const Announcements = ({ className, announcements }) => {
  const [height, setHeight] = useState(0);

  const toggle = () => {
    setHeight(height === 0 ? 'auto' : 0);
  };

  if (!announcements || announcements.length < 1) {
    return null;
  }

  return (
    <Wrapper className={className}>
      <AnnouncementWrapper>
        <AnnouncementToggle
          hasMultipleAnnouncements={announcements.length > 1}
          onClick={toggle}
          isOpen={height !== 0}
        />
        <AnimateHeight duration={250} height={height}>
          {announcements.map((announcement) => {
            return (
              <AnnouncementContent
                key={announcement.id}
                data-testid={`announcement-${announcement.id}`}
                federationName={announcement.federation && announcement.federation.name}
              >
                {announcement.announcement.split('\n').map((text, index) => (
                  <Fragment key={`announcement.id.${index}`}>
                    {text}
                    {announcement?.links?.map((link) => (
                      <LinkWrapper key={link}>
                        <StyledIconLink />
                        <StyledLink href={link.url} target="_blank">
                          {link.title}
                        </StyledLink>
                      </LinkWrapper>
                    ))}
                    <br />
                  </Fragment>
                ))}
              </AnnouncementContent>
            );
          })}
        </AnimateHeight>
      </AnnouncementWrapper>
    </Wrapper>
  );
};

export default Announcements;
