import React, { createContext } from 'react';

const MatchContext = createContext({
  match: {},
  updateMatch: null,
});

export const withMatch =
  (Component) =>
  // eslint-disable-next-line
  ({ matchGame: matchInProps, ...props }) => (
    <MatchContext.Consumer>
      {({ match, updateMatch }) => (
        <Component {...props} matchGame={matchInProps || match} updateMatchGame={updateMatch} />
      )}
    </MatchContext.Consumer>
  );

export default MatchContext;
