import { PERMISSIONS } from '../@types/Permissions';
import { hasPermissionsCheck } from '../contexts/RoleContext/RoleContext';
import { isCancelled } from './match';
import { isReferee } from './user';

export const refereeApprovals = (approvals) => {
  return approvals.filter((approval) => approval.by_referee === true);
};

export const coachApprovals = (approvals) => {
  return approvals.filter((approval) => approval.by_coach === true);
};

export const superUserApprovals = (approvals) => {
  return approvals.filter((approval) => approval.by_superuser === true);
};

export const refereeCanDeleteMatchApproval = (user, match, role) => {
  const hasApproveAsSuperUserPermission = hasPermissionsCheck(role, [
    PERMISSIONS.APPROVE_AS_SUPERUSER,
  ]);

  if (!isReferee(role)) {
    return false;
  }

  if (isCancelled(match)) {
    return false;
  }

  const matchApprovals = match.match_approvals;
  const refereeApprovalsResult = refereeApprovals(matchApprovals);
  const refereeOwnApproval = refereeApprovalsResult.find(
    (approval) => approval.person.id === user.id
  );

  if (refereeApprovalsResult.length >= 2) {
    return false;
  }

  if (coachApprovals(matchApprovals).length >= 1) {
    return false;
  }

  if (superUserApprovals(matchApprovals).length >= 1) {
    return false;
  }

  if (refereeOwnApproval === undefined) {
    return false;
  }

  if (hasApproveAsSuperUserPermission) {
    return false;
  }

  return true;
};
