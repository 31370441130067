import { PERMISSIONS } from '../@types/Permissions';
import { hasPermissionsCheck } from '../contexts/RoleContext/RoleContext';

export const isCoach = (role) => {
  const permissions = hasPermissionsCheck(
    role,
    [PERMISSIONS.APPROVE_AS_COACH, PERMISSIONS.APPROVE_AS_SUPERUSER],
    { policy: 'some', teamId: role.team_id ?? null }
  );

  return permissions;
};

export const isCoachOfTeam = (_teamId, role) => {
  return hasPermissionsCheck(
    role,
    [PERMISSIONS.APPROVE_AS_COACH, PERMISSIONS.APPROVE_AS_SUPERUSER],
    { policy: 'some', teamId: role.team_id ?? null }
  );
};

export const isReferee = (role) => {
  return hasPermissionsCheck(
    role,
    [PERMISSIONS.APPROVE_AS_UMPIRE, PERMISSIONS.APPROVE_AS_SUPERUSER],
    { policy: 'some' }
  );
};

export const coachCanEnterScore = (user, match) =>
  user.coach_can_enter_score && !(match.home_team_approval || match.away_team_approval);

export const isUserTeam = (userTeamId, teamId) => {
  return teamId === userTeamId;
};

export const getPersonType = (user, match, team_id = null, role) => {
  if (isCoach(role)) {
    if (
      (team_id && isCoachOfTeam(team_id, role) && team_id === match.home_team.id) ||
      (!team_id && user.team_id === match.home_team.id)
    ) {
      return 'home_coach';
    }

    if (
      (team_id && isCoachOfTeam(team_id, role) && team_id === match.away_team.id) ||
      (!team_id && user.team_id === match.away_team.id)
    ) {
      return 'away_coach';
    }
  }

  if (isReferee(role)) {
    return 'referee';
  }
};

export const hasHomeTeamRoles = (user, match) => {
  const home_team_roles = match.home_team.person_roles.concat(
    match.home_team.club.person_roles || []
  );

  return !!home_team_roles.filter((personRole) => personRole.person.id === user.id)[0];
};

export const hasAwayTeamRoles = (user, match) => {
  const awayTeamRoles = match.away_team.person_roles.concat(
    match.away_team.club.person_roles || []
  );

  return !!awayTeamRoles.filter((personRole) => personRole.person.id === user.id)[0];
};

export const updateUserTeamIdAndClubId = (user, match) => {
  if (hasHomeTeamRoles(user, match)) {
    user.team_id = match.home_team.id;
    user.club_id = match.home_team.club.id;

    return user;
  }

  if (hasAwayTeamRoles(user, match)) {
    user.team_id = match.away_team.id;
    user.club_id = match.away_team.club.id;

    return user;
  }

  user.team_id = 0;
  user.club_id = 0;

  return user;
};
