import React, { useState } from 'react';
import { styled } from 'styled-components';
import { PERMISSIONS } from '../../../@types/Permissions';
import { withMatch } from '../../../contexts/MatchContext';
import { theme } from '../../templates/ui';
import { AuthorizedCan } from '../AuthorizedCan';
import Checkmark from '../Icons/Checkmark';
import Plus from '../Icons/Plus';
import { TeamId } from '../TeamId';

export const StyledPlayer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => {
    if (props.disabled) {
      return `${theme.sizing.large} ${theme.sizing.xlarge}`;
    }
    if (props.divergentStyling) {
      return `${theme.sizing.large}`;
    }

    return `${theme.sizing.large} 0`;
  }};
  border-bottom: 1px dashed ${theme.color.gray.light};
  margin: ${({ disabled }) => (disabled ? `0 calc(0px - ${theme.sizing.xlarge})` : '0')};
  background: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, .025);' : 'transparent')};
  background-color: ${(props) =>
    props.divergentStyling ? theme.color.gray.lighter : 'transparent'};

  &:last-of-type {
    border-bottom: none;
  }
`;

const Name = styled.h4`
  margin: 0;
  padding: 3px 0;
  flex: 1;

  ${({ checked }) =>
    checked &&
    `
    color: ${theme.color.base.base};
  `};
`;

const CheckboxWrapper = styled.div`
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    color: ${theme.color.gray.black};
    text-align: center;
    transition: 0.1s all ease-in-out;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      &:first-child {
        opacity: 1;
      }
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border-radius: 2px;
      left: 0;
      top: -3px;
      width: ${theme.sizing.xxlarge};
      height: ${theme.sizing.xxlarge};
      border: 2px solid lightgrey;
      background-color: transparent;
    }
    &::after {
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      display: block;
      content: '';
      height: 14px;
      width: 14px;
      opacity: 0;
      left: 3px;
      top: 2px;
      z-index: 2;
    }
  }

  input[type='checkbox']:checked + label {
    background-color: ${theme.color.secondary.base};
    border: 1px solid ${theme.color.secondary.base};

    svg {
      opacity: 1;

      &:first-child {
        opacity: 0;
      }
    }

    &::before {
      transition: all 0.2s ease-in-out;
      border: 2px solid ${theme.color.gray.black};
    }

    &::after {
      opacity: 1;
    }
  }
`;

const StyledPlus = styled(Plus)`
  fill: ${theme.color.gray.darkest};
  width: 15px;
  height: 15px;
`;

const StyledTeamId = styled(TeamId)`
  margin-right: ${theme.sizing.xlarge};
  background-color: ${theme.color.gray.light};
  border-color: ${theme.color.gray.light};
  color: ${theme.color.gray.darkest};

  ${({ checked }) =>
    checked &&
    `
    background-color: ${theme.color.primary.base}; color: ${theme.color.body.base}; border-color: ${theme.color.primary.base};
  `};
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${theme.color.gray.white};
`;

const SubstitutePlayerComponent = ({
  teamId,
  teamName,
  name,
  className,
  checkboxId,
  clubMemberId,
  matchPlayerId,
  matchUuid,
  disabled,
  divergentStyling,
  onAddSubstitute,
  onRemoveSubstitute,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChangePlayerState = async (matchUuid, teamId, clubMemberId, matchPlayerId) => {
    let res;

    if (!checked) {
      // Optimistic UI update
      setChecked(true);
      res = await onAddSubstitute(matchUuid, clubMemberId, teamId);
    } else {
      // Optimistic UI update
      setChecked(false);
      res = await onRemoveSubstitute(matchUuid, clubMemberId, teamId, matchPlayerId);
    }

    // No valid response, revert to previous state
    if (!res) {
      setChecked(checked);
    }
  };

  return (
    <StyledPlayer
      className={className}
      disabled={disabled}
      divergentStyling={divergentStyling}
      data-testid={`substitute-player-${clubMemberId}`}
    >
      <Name checked={checked}>{name}</Name>
      <StyledTeamId checked={checked}>{teamName}</StyledTeamId>
      <AuthorizedCan
        has={[PERMISSIONS.MANAGE_TEAM, PERMISSIONS.MANAGE_ALL_TEAMS]}
        policy="some"
        teamId={teamId}
      >
        <CheckboxWrapper>
          <input
            checked={checked}
            data-testid={`toggleSubstitute-${clubMemberId}`}
            type="checkbox"
            id={checkboxId}
            onChange={() => {
              handleChangePlayerState(matchUuid, teamId, clubMemberId, matchPlayerId);
            }}
          />
          <label htmlFor={checkboxId} data-testid={`toggleSubstitute-${clubMemberId}-label`}>
            <StyledPlus />
            <StyledCheckmark />
          </label>
        </CheckboxWrapper>
      </AuthorizedCan>
    </StyledPlayer>
  );
};

export const SubstitutePlayer = withMatch(SubstitutePlayerComponent);
