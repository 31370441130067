import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledContainer = styled.div`
  padding: ${theme.sizing.xlarge} 0;
  background-color: ${theme.color.gray.base};
  border-top: 1px solid ${theme.color.gray.alt};
  border-bottom: 1px solid ${theme.color.gray.alt};
`;

export const Container = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);
