import React from 'react';
import { styled } from 'styled-components';
import { Wrapper } from '../atoms/Wrapper';
import { theme } from '../templates/ui';

const StyledButtonContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 2px solid ${theme.color.gray.light};
  z-index: 2;
  background-color: ${theme.color.body.base};
`;

const StyledButtonContainerInner = styled.div`
  overflow: auto;
`;

const Buttons = styled.div`
  display: flex;
  gap: ${theme.sizing.xlarge};
  justify-content: space-between;
  padding-top: ${theme.sizing.xlarge};
  padding-bottom: ${theme.sizing.xlarge};
  &:empty {
    padding: 0;
  }
`;

const ButtonContainer = ({ className, children }) => (
  <StyledButtonContainer className={className}>
    <Wrapper>
      <StyledButtonContainerInner>
        <Buttons>{children}</Buttons>
      </StyledButtonContainerInner>
    </Wrapper>
  </StyledButtonContainer>
);

export default ButtonContainer;
