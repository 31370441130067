import React from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../../contexts/MatchContext';
import { getMatchPlayerByClubMemberId } from '../../../services/matchPlayers';
import { fullName } from '../../../services/person';
import { SubstitutePlayer } from '../SubstitutePlayer';

const PlayerWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SubstitutePlayersComponent = ({
  className,
  players,
  match,
  matchUuid,
  onAddSubstitute,
  onRemoveSubstitute,
  playersForTeamId,
  children,
  divergentStyling,
}) => (
  <PlayerWrapper className={className}>
    {!!players.length
      ? parsePlayers(
          players,
          match,
          matchUuid,
          playersForTeamId,
          onAddSubstitute,
          onRemoveSubstitute,
          divergentStyling
        )
      : children}
  </PlayerWrapper>
);

function parsePlayers(
  players,
  match,
  matchUuid,
  teamId,
  onAddSubstitute,
  onRemoveSubstitute,
  divergentStyling
) {
  return players.map((player, i) => {
    const matchPlayer = getMatchPlayerByClubMemberId(match.match_players, player.id);

    return (
      <SubstitutePlayer
        key={i}
        name={fullName(player.person)}
        teamId={teamId}
        teamName={
          player.team_players[0] !== undefined ? player.team_players[0].team.name_short : '-'
        }
        checkboxId={i}
        playerId={player.person.id}
        clubMemberId={player.id}
        matchPlayerId={matchPlayer?.id}
        matchUuid={matchUuid}
        onAddSubstitute={onAddSubstitute}
        onRemoveSubstitute={onRemoveSubstitute}
        divergentStyling={divergentStyling}
      />
    );
  });
}

export const SubstitutePlayers = withMatch(SubstitutePlayersComponent);
