// All available comment types
export const COMMENT_TYPES = {
  UMPIRES: 'umpires',
  SCORE: 'score',
  SCORE_COMMENT: 'score-comment',
  CARDS: 'cards',
  CARDS_COMMENT: 'cards-comment',
  FORMATION: 'formation',
  OTHER: 'other',
} as const;

export type CommentType = {
  name: keyof typeof COMMENT_TYPES;
  label: string;
};

export type CommentTypes = CommentType[];
