import { useQuery } from 'react-query';
import Client from '../api/Client';
import { dwf_api_endpoint } from '../config/api';

export const useComments = () => {
  const query = useQuery(
    ['commentTypes'],
    () => {
      return Client.get(
        `${dwf_api_endpoint}/comment-type-options`,
        undefined,
        'match.comments',
        'comment-type-options'
      ).then((res) => {
        return res.data;
      });
    },
    { refetchOnWindowFocus: false }
  );

  return { ...query };
};
