import React from 'react';
import { styled } from 'styled-components';
import { CARD_COLORS, CardColor } from '../../../@types/Cards';
import { theme } from '../../templates/ui';
import { CardWrapper } from '../CardWrapper';
import GreenCard from '../Icons/GreenCard';
import RedCard from '../Icons/RedCard';
import YellowCard from '../Icons/YellowCard';
import { TeamRoleIndicator } from '../TeamRoleIndicator';

const StyledGreenCard = styled(GreenCard)`
  width: 26px;
  height: 26px;
`;

const StyledRedCard = styled(RedCard)`
  fill: ${theme.color.cards.red};
  width: 26px;
  height: 26px;
`;

const StyledYellowCard = styled(YellowCard)`
  width: 26px;
  height: 26px;
`;

type CardProps = {
  role?: string;
  color: CardColor;
  'data-testid'?: string;
};

export const Card = ({ role, color, 'data-testid': dataTestId }: CardProps) => {
  if (!color) {
    throw new Error(`Color is required.`);
  }

  if (![CARD_COLORS.GREEN, CARD_COLORS.RED, CARD_COLORS.YELLOW].includes(color)) {
    throw new Error(`${color} is not a valid card color`);
  }

  return (
    <CardWrapper data-testid={dataTestId}>
      {role && <TeamRoleIndicator>{role}</TeamRoleIndicator>}
      {color === CARD_COLORS.GREEN && <StyledGreenCard />}
      {color === CARD_COLORS.RED && <StyledRedCard />}
      {color === CARD_COLORS.YELLOW && <StyledYellowCard />}
    </CardWrapper>
  );
};
