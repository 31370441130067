import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const StyledFormItem = styled.div`
  position: relative;
  padding-bottom: ${theme.sizing.xlarge};
`;

export const FormItem = ({ className, children }) => (
  <StyledFormItem className={className}>{children}</StyledFormItem>
);
