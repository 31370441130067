import { dwf_api_endpoint } from '../config/api';
import Client from './Client';

export const getAnnouncements = (matchId, pageKey) =>
  Client.get(
    `${dwf_api_endpoint}/matches/${matchId}/announcements`,
    null,
    pageKey,
    'matches.getAnnouncements'
  );
