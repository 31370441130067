import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const ChevronRight = ({ className }: Props) => (
  <svg
    width="5"
    height="8"
    viewBox="0 0 5 8"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M.926 6.871a.092.092 0 0 1 0-.13L3.564 4.11a.092.092 0 0 0 0-.13L.926 1.34a.091.091 0 0 1 0-.129L1.454.69a.092.092 0 0 1 .13 0l3.288 3.294a.092.092 0 0 1 0 .13l-3.29 3.284a.092.092 0 0 1-.13 0l-.526-.526z" />
  </svg>
);

export default ChevronRight;
