import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { css, styled } from 'styled-components';
import { theme } from '../../templates/ui';
import Cross from '../Icons/Cross';

const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100;

  @media only screen and (min-width: ${theme.breakpoints.xlarge}) {
    align-items: center;
  }
`;

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  opacity: ${(props) => (props.showPopup ? '1' : '0')};
  pointer-events: ${(props) => (props.showPopup ? 'auto' : 'none')};
  transition: all 250ms ease-in-out;
`;

const PopupContainer = styled.div`
  position: relative;
  background-color: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  z-index: 101;
  border-radius: 10px;
  width: calc(100% - 30px);
  margin: ${theme.sizing.xlarge};
  height: auto;
  pointer-events: auto;
  transition: transform 250ms cubic-bezier(0.86, 0, 0.07, 1);

  @media only screen and (min-width: ${theme.breakpoints.xlarge}) {
    max-width: 350px;
  }

  @media only screen and (max-width: 991px) {
    transform: translate(0, 200%) !important;
    transition: all 250ms ease-in-out;
    ${(props) =>
      props.showPopup &&
      css`
        transform: translate(0, 0) !important;
      `};
  }

  @media only screen and (min-width: ${theme.breakpoints.xlarge}) {
    transform: scale(0);
    transition: all 250ms ease-in-out;
    ${(props) =>
      props.showPopup &&
      css`
        transform: scale(1) !important;
      `};
  }
`;

const PopupCloseButton = styled.button`
  background-color: ${theme.color.gray.darker};
  color: ${theme.color.gray.white};
  border: none;
  border-radius: 10px;
  outline: none;
  margin-top: ${theme.sizing.xlarge};
  padding: ${theme.sizing.xlarge} ${theme.sizing.large};
  cursor: pointer;
  width: 100%;

  &:focus,
  &:hover {
    background-color: ${theme.color.gray.darkest};
  }
`;

const PopupCloseButtonTop = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  right: ${theme.sizing.xxlarge};
  top: ${theme.sizing.xxlarge};
  height: ${theme.sizing.xxlarge};
  width: ${theme.sizing.xxlarge};

  &:focus,
  &:hover {
    opacity: 0.8;
  }
`;

const PopupContent = styled.div`
  padding: ${theme.sizing.xxlarge};
`;

const StyledCross = styled(Cross)`
  fill: ${theme.color.gray.darkest};
`;

export const Popup = ({
  children,
  onClick,
  showPopup = false,
  showAdditionalCloseButton = true,
  ...props
}) => {
  const PopupRef = useRef(null);

  useEffect(() => {
    if (showPopup) disableBodyScroll(PopupRef.current);
    else enableBodyScroll(PopupRef.current);
  }, [showPopup]);

  return (
    <PopupWrapper ref={PopupRef} {...props}>
      <PopupContainer showPopup={showPopup}>
        <PopupContent>
          {children}
          {showAdditionalCloseButton && (
            <PopupCloseButton onClick={onClick}>Sluiten</PopupCloseButton>
          )}
        </PopupContent>
        <PopupCloseButtonTop onClick={onClick} aria-label="Sluiten">
          <StyledCross />
        </PopupCloseButtonTop>
      </PopupContainer>
      <Backdrop showPopup={showPopup} onClick={onClick} />
    </PopupWrapper>
  );
};
