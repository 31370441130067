import { getTeamById } from './match';

export const getMatchPlayerByClubMemberId = (matchPlayers, clubMemberId) =>
  Object.values(matchPlayers).find((matchPlayer) => matchPlayer.club_member.id === clubMemberId);

export const getActivePlayersByTeamId = (match, teamId) => {
  const team = getTeamById(match, teamId);

  if (!team) {
    return {};
  }

  const players = team.team.players;

  const playersInTeam = Object.keys(players).reduce((playersInTeam, key) => {
    const teamPlayer = players[key];

    const activeTeamPlayer = {
      ...teamPlayer,
      active: !!match.match_players[teamPlayer.club_member.id.toString()],
    };

    playersInTeam[activeTeamPlayer.club_member.id.toString()] = activeTeamPlayer;

    return playersInTeam;
  }, {});

  // Add substitute players
  const matchPlayers = match.match_players;

  return Object.keys(matchPlayers).reduce((teamPlayersAndSubstitutes, key) => {
    const matchPlayer = matchPlayers[key];

    if (!teamPlayersAndSubstitutes[key]) {
      if (matchPlayer.team?.id === teamId) {
        teamPlayersAndSubstitutes[matchPlayer.club_member.id.toString()] = {
          ...matchPlayer,
          active: true,
        };
      }
    } else {
      teamPlayersAndSubstitutes[key].jersey_number = matchPlayer.jersey_number;
    }

    return teamPlayersAndSubstitutes;
  }, playersInTeam);
};

export const keyPlayersByClubMemberId = (match) => {
  const { home_team: homeTeam, away_team: awayTeam, match_players: matchPlayers } = match;
  // $FlowFixMe
  match.match_players = matchPlayers.reduce((carrier, player) => {
    if (player.club_member === null) {
      return carrier;
    }

    const carrierClone = { ...carrier };

    carrierClone[player.club_member.id.toString()] = player;

    return carrierClone;
  }, {});

  const reducePlayers = (players) => {
    return players.reduce((carrier, player) => {
      if (player.club_member === null) {
        return carrier;
      }

      const carrierClone = { ...carrier };
      const teamAndMatchPlayer = {
        ...player,
        ...match.match_players[player.club_member.id.toString()],
      };
      carrierClone[player.club_member.id.toString()] = teamAndMatchPlayer;

      return carrierClone;
    }, {});
  };

  homeTeam.players = reducePlayers(homeTeam.team_players);
  awayTeam.players = reducePlayers(awayTeam.team_players);

  match.home_team = homeTeam;
  match.away_team = awayTeam;
};
