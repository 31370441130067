import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Caution = ({ className }: Props) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M14.632 10.845L9.066 1.203c-.861-1.492-2.27-1.492-3.131 0L.367 10.845c-.86 1.491-.156 2.712 1.566 2.712h11.134c1.722 0 2.427-1.22 1.565-2.712zM6.667 4.237a.833.833 0 1 1 1.666 0v3.73a.833.833 0 1 1-1.666 0v-3.73zM7.5 11.82a1.01 1.01 0 0 1-1.008-1.008A1.01 1.01 0 1 1 7.5 11.82z"
      fillRule="nonzero"
    />
  </svg>
);

export default Caution;
