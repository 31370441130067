import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Author = ({ className }: Props) => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g fillRule="evenodd">
      <path
        d="M14.874.666a3.057 3.057 0 0 1 .465 4.269l-1.112 1.403-.677.853-7.448 9.396-3.674 1.712L0 19.429l.562-2.641.85-3.995 7.45-9.398.678-.852 1.111-1.405a2.983 2.983 0 0 1 4.223-.472zM2.169 18.286l3.545-1.603-.113-.322-3.342-2.627-.292-.02-.824 3.764 1.026.808zM14.08 6.857l.778-1.107-3.793-3.464-.778 1.106 3.792 3.465z"
        fillRule="nonzero"
      />
      <path d="M0 20.571h8v1.143H0zM13.714 20.571H16v1.143h-2.286zM9.143 20.571h3.429v1.143H9.143z" />
    </g>
  </svg>
);

export default Author;
