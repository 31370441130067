import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';
import { Input } from '../Input';
import { Label } from '../Label';
import { VisuallyHidden } from '../VisuallyHidden';

const StyledInput = styled(Input)`
  margin-right: ${theme.sizing.large};
  width: 100px;

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const ScoreInput = (props) => (
  <>
    <VisuallyHidden>
      <Label htmlFor={props.id}>{props.placeholder}</Label>
    </VisuallyHidden>
    <StyledInput type="number" {...props} />
  </>
);
