import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { styled } from 'styled-components';
import { getMatchExcerpt } from '../../api/matches';
import { RoleContext } from '../../contexts/RoleContext/RoleContext';
import { RolesContext } from '../../contexts/RolesContext/RolesContext';
import { useHasRole } from '../../hooks/useHasRole';
import { canOnlyConfirmAsCoach } from '../../services/permissions';
import Account from '../atoms/Icons/Account';
import AccountSelected from '../atoms/Icons/AccountSelected';
import ChevronRight from '../atoms/Icons/ChevronRight';
import { LoadingIcon } from '../atoms/LoadingIcon';
import { Popup } from '../atoms/Popup';
import { theme } from '../templates/ui';
import MatchDetails from './MatchDetails';

const Roles = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  margin-top: ${theme.sizing.large};
  gap: ${theme.sizing.large};
  border-top: 1px solid ${theme.color.gray.light};
  padding-top: ${theme.sizing.large};
`;

const ModalTitle = styled.h2`
  color: ${theme.color.primary.base};
  margin: 0;
`;

const ModalContent = styled.div`
  margin: ${theme.sizing.large} 0 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.sizing.large};

  > * {
    margin: 0;
  }
`;

const CurrentRole = styled.div`
  display: flex;
  gap: ${theme.sizing.large};
`;

const Role = styled.button`
  background-color: ${theme.color.secondary.base};
  color: ${theme.color.gray.white};
  border-radius: 3px;
  border-width: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: ${theme.sizing.large};
  gap: ${theme.sizing.large};
  width: 100%;
  text-align: left;

  &:disabled {
    background-color: ${theme.color.gray.darker};
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled)  {
    background-color: ${theme.color.secondary.hover};
`;

const RoleContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${theme.sizing.small};
`;

const RoleName = styled.div`
  font-weight: bold;
  font-size: ${theme.font.large};
`;

const RoleTeam = styled.div`
  font-size: ${theme.font.base};
  color: ${theme.color.gray.white};
`;

const CurrentRoleTeam = styled.div`
  font-size: ${theme.font.base};
  color: ${theme.color.gray.darker};
`;

const RoleAccountIcon = styled.div`
  svg {
    height: 21px;
    width: 21px;
  }
`;

const RoleChevronRightIcon = styled.div`
  svg {
    height: 13px;
    width: 13px;
  }
`;

const StyledChevronRight = styled(ChevronRight)`
  fill: ${theme.color.gray.white};
  opacity: 0.75;
`;

const StyledAccount = styled(Account)`
  fill: ${theme.color.gray.white};
  opacity: 0.75;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${theme.color.gray.white};
  background: black;
  opacity: 0.25;
  z-index: 1;
`;

const ModalRoleSwitch = ({
  showPopup,
  showAdditionalCloseButton = true,
  onClick,
  matchInfo = null,
  isLoading = !matchInfo,
}) => {
  const { roles: availableRoles, setRoleSwitchModalActive, matchUuid } = useContext(RolesContext);

  const {
    role: currentRole,
    team_id,
    setRole,
    setRoleManuallySelected,
    roleManuallySelected,
  } = useContext(RoleContext);

  const { hasSingularCoachRole, hasSingularRefereeRole } = useHasRole();

  const history = useHistory();
  const [matchData, setMatchData] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(isLoading);

  const routeMatch = useRouteMatch('/matches/:id');

  // Fetch match data excerpt when matchUuid changes
  useEffect(() => {
    if (!matchUuid) {
      return;
    }

    setIsLoadingData(true);

    getMatchExcerpt(matchUuid, null).then((res) => {
      if (res?.data) {
        setMatchData(res.data);
      }
      setIsLoadingData(false);
    });
  }, [matchUuid]);

  const getTeamById = useCallback(
    (teamId) => {
      if (!matchData || !teamId) {
        return null;
      }

      let team;

      switch (teamId) {
        case matchData.home_team.id:
          team = matchData.home_team;
          break;
        case matchData.away_team.id:
          team = matchData.away_team;
          break;
      }

      return team;
    },
    [matchData]
  );

  const getTeamName = useCallback(
    (teamId) => {
      const team = getTeamById(teamId);

      if (team) {
        return `${team?.club?.name} ${team.name_short ? team.name_short : ''}`;
      } else {
        return null;
      }
    },
    [matchData]
  );

  const isRoleDisabled = useCallback(
    (role) => {
      const roleIsSingularCoach = canOnlyConfirmAsCoach(role);
      const hasBothSingularRoles = hasSingularCoachRole && hasSingularRefereeRole;

      return roleIsSingularCoach && hasBothSingularRoles;
    },
    [availableRoles]
  );

  const getButton = useCallback(
    (role) => {
      // Role is currently active, do not show button
      if (
        roleManuallySelected &&
        currentRole?.role_id === role.role_id &&
        currentRole?.team_id === role.team_id
      ) {
        return;
      }

      // Check if button is disabled
      const disabled = isRoleDisabled(role);

      return (
        <div key={`${role.role_id}-${role.team_id}`}>
          <Role
            disabled={disabled}
            data-testid="available-role"
            onClick={() => {
              setRole(role);
              setRoleManuallySelected(true);
              setRoleSwitchModalActive(false);

              // If we're not on the match page, go there
              if (!routeMatch?.params?.id) {
                history.push(`/matches/${matchUuid}`);
              }
            }}
          >
            <RoleAccountIcon>
              <StyledAccount />
            </RoleAccountIcon>
            <RoleContent>
              <RoleName>{role.category.name}</RoleName>
              {role.team_id && (
                <RoleTeam data-testid="available-team">{getTeamName(role.team_id)}</RoleTeam>
              )}
            </RoleContent>
            <RoleChevronRightIcon>
              <StyledChevronRight />
            </RoleChevronRightIcon>
          </Role>
        </div>
      );
    },
    [currentRole, availableRoles, roleManuallySelected, getTeamName]
  );

  const loading = isLoading || isLoadingData;

  return (
    <Popup
      showPopup={showPopup}
      onClick={onClick}
      isLoading={isLoading}
      showAdditionalCloseButton={showAdditionalCloseButton}
      data-testid="role-switch-modal"
    >
      {loading && (
        <LoadingOverlay>
          <LoadingIcon isLoading={1} />
        </LoadingOverlay>
      )}

      {/* Show indicator of currently active role. */}
      {roleManuallySelected && currentRole && (
        <div>
          <ModalTitle>Wissel van rol</ModalTitle>
          <ModalContent>
            <p>Huidige rol:</p>
            <CurrentRole data-testid="current-role">
              <AccountSelected />
              <RoleContent>
                <RoleName>{currentRole.category.name}</RoleName>
                {team_id && <CurrentRoleTeam>{getTeamName(team_id)}</CurrentRoleTeam>}
              </RoleContent>
            </CurrentRole>
          </ModalContent>
        </div>
      )}

      {/* When no role is selected, show match details and intro text. */}
      {!roleManuallySelected && (
        <div>
          <ModalTitle>Selecteer een rol</ModalTitle>
          <ModalContent>
            <p>
              Je hebt meer dan één rol waarmee je deze wedstrijd kan bekijken. Selecteer een rol om
              verder te gaan.
            </p>
          </ModalContent>
          {matchData && (
            <MatchDetails
              key={matchData.id}
              matchId={matchData.id}
              homeTeam={matchData.home_team}
              awayTeam={matchData.away_team}
              date={matchData.date}
              matchCode={matchData.match_code}
              homeScore={matchData.home_score}
              awayScore={matchData.away_score}
              homeShootoutScore={matchData.home_shootout_score}
              awayShootoutScore={matchData.away_shootout_score}
              commentsCount={matchData.match_comments.length}
              cancellation={matchData.match_cancellations}
              approvals={matchData.match_approvals}
              homeCoachApproved={matchData.home_team_approval}
              awayCoachApproved={matchData.away_team_approval}
              matchUuid={matchData.uuid}
              matchGame={matchData}
            />
          )}
        </div>
      )}

      {/* List of currently available permissions/roles. */}
      {availableRoles && (
        <Roles>
          {availableRoles.map((role) => {
            // No team id's are available for this role, show a button without team.
            return getButton(role);
          })}
        </Roles>
      )}
    </Popup>
  );
};

export default ModalRoleSwitch;
