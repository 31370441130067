import React from 'react';
import { styled } from 'styled-components';
import { Bar } from '../../atoms/Bar';
import { FaqImage } from '../../atoms/FaqImage';
import { Paragraph } from '../../atoms/Paragraph';
import { TitleUnderline } from '../../atoms/TitleUnderline';
import { Wrapper } from '../../atoms/Wrapper';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledBar = styled(Bar)`
  text-align: left;
  padding: ${theme.sizing.xxlarge} 0;
  margin-bottom: 0;

  h3 {
    margin: 0;
  }
`;

const FaqWrapper = styled.div`
  margin-bottom: ${theme.sizing.xxlarge};
`;

export const FaqCoach = () => (
  <>
    <TopBar>Hoe werkt het DWF</TopBar>
    <StyledBar>
      <Wrapper>
        <h3>Voor coaches, managers en aanvoerders</h3>
      </Wrapper>
    </StyledBar>
    <Wrapper>
      <FaqWrapper>
        <Paragraph>
          Als coach, manager of aanvoerder van een team kun je na het invullen van de
          wedstrijdverificatie code diverse acties uitvoeren:
        </Paragraph>
        <FaqImage>
          <img src="../9-coachoverzicht.png" alt="" />
        </FaqImage>

        <Paragraph>
          Op de bovenste regel zie je de datum van de wedstrijd, het tijdstip en de code waarmee je
          bent ingelogd. Wanneer een wedstrijd is afgelast, wordt dit in deze regel vermeld. Het
          laatste icoontje (tekstwolk) geeft aan hoeveel opmerkingen er op het formulier zijn
          geplaatst.
        </Paragraph>

        <TitleUnderline>Eindstand</TitleUnderline>
        <Paragraph>
          Je ziet de logo’s van de beide teams. Tussen beide teams staat de stand. Het thuisspelende
          team staat altijd als eerste genoemd. De scheidsrechters zorgen ervoor dat de juiste stand
          ingevuld wordt.
        </Paragraph>
        <FaqImage>
          <img src="../13-eindstand.png" alt="" />
        </FaqImage>

        <TitleUnderline>Shoot-outs</TitleUnderline>
        <Paragraph>
          Wanneer een wedstrijd beslist kan worden door shoot-outs, verschijnt onder de eindstand
          een tweede regel. Hier zie je na de wedstrijd de eindstand van de shoot-outs. (achter de
          icoontjes van de twee doeltjes).
        </Paragraph>
        <FaqImage>
          <img src="../10-shootouts-en-score.png" alt="" />
        </FaqImage>

        <TitleUnderline>Spelerslijst</TitleUnderline>
        <Paragraph>
          De aanvoerder, coach of manager geeft voor de wedstrijd aan welke spelers opgesteld
          worden. Je ziet voor elke wedstrijd een overzicht van de spelers die gekoppeld zijn aan
          het betreffende team. Door achter de naam van de spelers in het rondje te klikken, stel je
          een speler op. Het vierkant voor de naam wordt dan oranje, en je ziet een groen vinkje
          achter de naam. Voor alle spelers geldt dat je het rugnummer kunt invullen. Heeft een
          speler geen rugnummer, dan kun je dit aangeven met drie puntjes.
        </Paragraph>

        <FaqImage>
          <img src="../11-toevoegen.png" alt="" />
        </FaqImage>

        <TitleUnderline>Invallers</TitleUnderline>
        <Paragraph>
          Wanneer je spelers wilt laten invallen in je team, gebruik je de button onder de
          spelerslijst ‘Invaller toevoegen’. Vervolgens vul je de naam van de invaller in. Je voegt
          de speler toe aan de spelerslijst door te klikken op de button ‘Speler toevoegen’. De
          speler is nu in de spelerslijst opgenomen. Het icoon van het shirt achter de naam geeft
          aan dat deze speler een invaller is. Deze speler kun je vervolgens ook een rugnummer
          geven, of weer verwijderen uit de lijst.
        </Paragraph>

        <FaqImage>
          <img src="../12-toevoegen.png" alt="" />
        </FaqImage>

        <TitleUnderline>Kaarten</TitleUnderline>
        <Paragraph>
          Hebben spelers kaarten ontvangen, dan zie je het aantal kaarten staan per team onder het
          scoreblok. De kaarten verschijnen ook achter de naam van de betreffende speler in de
          spelerslijst.
        </Paragraph>

        <TitleUnderline>Opmerkingen</TitleUnderline>
        <Paragraph>
          Wil je zelf iets toevoegen aan het formulier, dan doe je dat door een opmerking te
          plaatsen. Klik hiervoor op het icoontje en vul je opmerking in.
        </Paragraph>

        <FaqImage>
          <img src="../6-opmerkingen.png" alt="" />
        </FaqImage>

        <TitleUnderline>Wedstrijd afgelast/gestaakt</TitleUnderline>
        <Paragraph>
          Is een wedstrijd gestaakt, dan zie je dit in de bovenste regel van het DWF terug.
        </Paragraph>

        <TitleUnderline>Afronding</TitleUnderline>
        <Paragraph>
          In het wedstrijdoverzicht is de afrondingsstatus van het DWF te zien. Als de iconen S1 en
          S2 verschijnen, is de score bevestigd door de eerste en tweede scheidsrechter. De vinkjes
          achter de teamnaam geeft aan dat deze score vervolgens is goedgekeurd door de
          teamondersteuner of aanvoerder van dat team.
        </Paragraph>

        <FaqImage>
          <img src="../icon-afronding.png" alt="" />
        </FaqImage>
      </FaqWrapper>
    </Wrapper>
  </>
);
