import React from 'react';
import { styled } from 'styled-components';
import { TitleUnderline } from '../atoms/TitleUnderline';
import { theme } from '../templates/ui';

const TeamSupportersWrapper = styled.div`
  margin: 0;
  padding: 0 0 ${theme.sizing.xxxxlarge} 0;
`;

const StyledOuterWrapper = styled.div`
  border-bottom: 1px solid ${theme.color.gray.light};
  margin-bottom: 25px;

  @media only screen and (max-width: ${theme.breakpoints.medium}) {
    margin-bottom: 45px;
  }
`;

const TeamSupporters = ({ className, children, title }) => {
  return (
    <StyledOuterWrapper data-testid="team-supporters">
      {title}
      <TeamSupportersWrapper className={className} id="team_supporters_wrapper">
        {children}
      </TeamSupportersWrapper>
    </StyledOuterWrapper>
  );
};

TeamSupporters.Title = function TeamSupportersTitle(props) {
  return <TitleUnderline {...props}>Teamondersteuners</TitleUnderline>;
};

export default TeamSupporters;
