import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Disobedience = ({ className }: Props) => (
  <svg
    width="23"
    height="31"
    viewBox="0 0 23 31"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M11.994 7.952a2.494 2.494 0 0 0 1.956-2.939 2.512 2.512 0 0 0-2.946-1.965A2.488 2.488 0 0 0 9.05 5.986a2.51 2.51 0 0 0 2.944 1.966M13.028 2.588l-.414-.376 2.064-2.274.414.376zM16.837 2.72l-3.084 1.062-.182-.53 3.084-1.061z" />
    <path d="M16.86 5.346l-2.898.03-.006-.56 2.898-.029zM.484 14.132a1.229 1.229 0 0 1-.462-.742c-.13-.668.328-1.232.93-1.54L8.682 7c.637 1.324 2.072 2.104 3.541 1.803 1.016-.207 1.823-.889 2.255-1.776h7.267c.693 0 1.255.575 1.255 1.283s-.562 1.283-1.255 1.283h-9.472l-5.727 8.479-.024 11.234C6.51 29.416 6.438 31 4.74 31c-1.14 0-1.9-1.052-1.82-1.758 0 0-.028-13.444-.002-13.44.08-1.028.36-1.555.747-2.193l.933-1.106c-1.207.737-2.095 1.28-2.664 1.63-.57.348-1.053.348-1.45 0z" />
  </svg>
);

export default Disobedience;
