import React from 'react';
import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

const hfa = (content) => `
  &:hover,
  &:focus,
  &:active,
  &.is-active {
      ${content};
  }
`;

const StyledButton = styled.button`
  position: relative;
  background-color: ${(props) => {
    if (props.clear) {
      return 'transparent';
    }

    if (props.primary) {
      return theme.color.primary.base;
    }

    return theme.color.secondary.base;
  }};
  transition: background-color 0.2s ease-in-out;
  text-align: left;
  border: 0;
  border-radius: 0.2rem;
  padding: ${(props) =>
    props.clear ? ` ${theme.sizing.small} ${theme.sizing.large};` : theme.sizing.xlarge};
  color: ${theme.color.gray.white};
  font-weight: 600;
  cursor: pointer;
  font-size: ${(props) => (props.small ? theme.font.medium : theme.font.large)};
  outline: none;

  &:focus,
  &:hover {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  span {
    width: 26px;
    height: 26px;
    z-index: 1;
    font-size: ${theme.font.xxxxlarge};
  }

  ${(props) => {
    hfa(`outline: none;`);

    if (props.clear) {
      return hfa(`background-color: transparent`);
    }

    if (props.primary) {
      return hfa(`background-color: ${theme.color.primary.hover};`);
    }

    return hfa(`background-color: ${theme.color.secondary.hover}`);
  }}
`;

export const Button = ({ className, small, clear, children, ...rest }) => (
  <StyledButton small={small} clear={clear} className={className} {...rest}>
    {children}
  </StyledButton>
);
