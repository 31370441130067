import React from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../../contexts/MatchContext';
import { hasResults } from '../../../services/dwfOptions';
import { theme } from '../../templates/ui';
import Arrow from '../Icons/Arrow';
import Checkmark from '../Icons/Checkmark';
import { Score } from '../Score';
import { TeamId } from '../TeamId';

const StyledTeam = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.sizing.medium};

  h3 {
    margin: 0;
    font-weight: 400;
    font-size: ${theme.font.base};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: ${theme.sizing.large};
  }

  div:nth-child(3) {
    margin-left: auto;
  }

  ${(props) => (props.current ? `h3{color: ${theme.color.base.base}; font-weight: 700;}` : ``)};
`;

const ArrowWrapper = styled.div`
  display: none;

  svg {
    position: absolute;
    left: calc(0px - ${theme.sizing.xxlarge});
    top: -14px;
  }

  ${(props) => (props.arrow ? `display: block;` : ``)};
`;

const StyledApproved = styled.span`
  margin-left: ${theme.sizing.medium};
  min-width: 18px;
`;

const StyledApprovedIcon = styled(Checkmark)`
  fill: ${theme.color.secondary.darker};
`;

const MatchTeamComponent = ({
  clubName,
  teamName,
  current,
  arrow,
  score,
  higher,
  matchGame,
  coachApproved,
}) => (
  <StyledTeam current={current}>
    <ArrowWrapper arrow={arrow}>
      <Arrow />
    </ArrowWrapper>
    <h3>{clubName}</h3>
    {teamName && <TeamId>{teamName}</TeamId>}
    {((matchGame.dwf_options && hasResults(matchGame.dwf_options)) || !matchGame.dwf_options) && (
      <Score data-testid={`score-${teamName}`} higher={higher}>
        {score || score === 0 ? score : '-'}
      </Score>
    )}
    {coachApproved && (
      <StyledApproved data-testid={`approved-${teamName}`}>
        <StyledApprovedIcon />
      </StyledApproved>
    )}
  </StyledTeam>
);

export const MatchTeam = withMatch(MatchTeamComponent);
