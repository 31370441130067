import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

export const Role = styled.div`
  display: inline-block;
  width: 50%;
  padding: ${theme.sizing.xlarge};
  background-color: ${theme.color.secondary.base};
  border-radius: 5px;
  margin-right: 7.5px;

  &:last-child {
    margin-right: 0;
  }
`;
