import React from 'react';
import { styled } from 'styled-components';
import { withMatch } from '../../contexts/MatchContext';
import { useMatch } from '../../hooks/useMatch';
import { getCardsForTeamId } from '../../services/card';
import { hasCards, hasResults, hasShootout } from '../../services/dwfOptions';
import { ScoreContainer } from '../atoms/ScoreContainer';
import { Wrapper } from '../atoms/Wrapper';
import { theme } from '../templates/ui';

const StyledWrapper = styled(Wrapper)`
  margin-bottom: ${theme.sizing.xlarge};
  position: relative;
  padding: 0 ${theme.sizing.small};
  background-image: url('/hockey_veld.jpg');
  background-size: cover;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.85) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const StyledHeader = styled.div`
  position: relative;
  padding: ${theme.sizing.xlarge} 0;
  display: flex;
  justify-content: space-around;
  gap: ${theme.sizing.xlarge};
`;

const Divider = styled.div`
  height: 4px;
  width: 2rem;
  background-color: ${theme.color.primary.base};
  position: relative;
  align-self: center;
`;

const Team = styled.div`
  display: flex;
  gap: ${theme.sizing.xlarge};
  width: 46%;
`;

const StyledTeamInfo = styled.div`
  width: 100%;
  text-align: center;
  @media only screen and (min-width: ${theme.breakpoints.small}) {
    width: 50%;
  }
`;

const StyledScoreContainer = styled(ScoreContainer)`
  position: relative;
  margin: 0 ${theme.sizing.large};
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  @media only screen and (min-width: ${theme.breakpoints.small}) {
    margin: 0;
  }
`;

const StyledLogo = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${theme.sizing.small};
  padding: ${theme.sizing.large};
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: ${theme.color.body.base};
`;

const StyledImage = styled.img`
  position: absolute;
  width: 46px;
  height: 46px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  max-width: 100px;
  font-size: ${theme.font.medium};
  display: inline-block;
  margin-bottom: ${theme.sizing.large};
  word-break: break-word;
  @media only screen and (min-width: ${theme.breakpoints.small}) {
    display: inline-block;
    margin: 0;
  }
`;

const Name = styled.span`
  font-weight: 700;
`;

const ShootoutText = styled.div`
  color: ${theme.color.primary.base};
  font-size: ${theme.font.small};
`;

const IndicatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const DividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
`;

const ShootoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: center;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 20px;
  justify-content: center;
`;

const isHigher = (isHigher, than) => {
  return isHigher > than;
};

const MatchHeader = ({
  user,
  homeTeam,
  awayTeam,
  homeShootoutScore,
  awayShootoutScore,
  homeScore,
  awayScore,
  onScoreUpdate,
  matchGame,
}) => {
  const gameHasResults = hasResults(matchGame.dwf_options);
  const { isDraw } = useMatch();

  return (
    <StyledWrapper>
      <StyledHeader>
        <Team>
          <StyledTeamInfo>
            <StyledLogo>
              <StyledImage src={homeTeam.club.logo_picture_url} />
            </StyledLogo>
            <Title>
              <Name>{homeTeam.name}</Name>
            </Title>
          </StyledTeamInfo>
          {gameHasResults && (
            <StyledScoreContainer
              id="home_score"
              data-scoreInputTestId="homeScore"
              data-shootoutInputTestId="homeShootout"
              user={user}
              goalScore={homeScore}
              shootoutScore={homeShootoutScore}
              cardsAmount={getCardsForTeamId(homeTeam.id, matchGame.match_cards).length}
              updateScore={(e) => {
                onScoreUpdate('home', e.target.value);
              }}
              updateShootout={(e) => {
                onScoreUpdate('home_shootout', e.target.value);
              }}
              higher={isHigher(homeScore + homeShootoutScore, awayScore + awayShootoutScore)}
            />
          )}
        </Team>
        <IndicatorWrapper>
          <DividerWrapper>
            <Divider />
          </DividerWrapper>
          {hasShootout(matchGame.dwf_options) && isDraw && (
            <ShootoutWrapper>
              <ShootoutText>Shootouts</ShootoutText>
            </ShootoutWrapper>
          )}
          {hasCards(matchGame.dwf_options) && <CardsWrapper />}
        </IndicatorWrapper>
        <Team>
          {gameHasResults && (
            <StyledScoreContainer
              id="away_score"
              data-scoreInputTestId="awayScore"
              data-shootoutInputTestId="awayShootout"
              user={user}
              goalScore={awayScore}
              shootoutScore={awayShootoutScore}
              cardsAmount={getCardsForTeamId(awayTeam.id, matchGame.match_cards).length}
              updateScore={(e) => {
                onScoreUpdate('away', e.target.value);
              }}
              updateShootout={(e) => {
                onScoreUpdate('away_shootout', e.target.value);
              }}
              higher={isHigher(awayScore + awayShootoutScore, homeScore + homeShootoutScore)}
            />
          )}
          <StyledTeamInfo>
            <StyledLogo>
              <StyledImage src={awayTeam.club.logo_picture_url} />
            </StyledLogo>
            <Title>
              <Name>{awayTeam.name}</Name>
            </Title>
          </StyledTeamInfo>
        </Team>
      </StyledHeader>
    </StyledWrapper>
  );
};

export default withMatch(MatchHeader);
