// All available permissions
export const PERMISSIONS = {
  VIEW_MATCH: 'dwf_view_match',
  MANAGE_TEAM: 'dwf_manage_team',
  VIEW_TEAMS: 'dwf_view_teams',
  MANAGE_SCORE: 'dwf_manage_score',
  MANAGE_CARDS: 'dwf_manage_cards',
  ENTER_COMMENT: 'dwf_enter_comment',
  APPROVE_AS_COACH: 'dwf_approve_as_coach',
  APPROVE_AS_UMPIRE: 'dwf_approve_as_umpire',
  APPROVE_AS_SUPERUSER: 'dwf_approve_as_superuser',
  CANCEL_MATCH: 'dwf_cancel_match',
  MANAGE_ALL_TEAMS: 'dwf_manage_all_teams',
  MANAGE_ALL_TEAMS_SUPPORT: 'dwf_manage_all_teams_support',
  CORRECT_AFTER_CLOSING: 'dwf_correct_after_closing',
  MANAGE_COMMENTS: 'dwf_manage_comments',
} as const;

// These permissions do not need a team id
export const PERMISSIONS_GLOBAL: PermissionType[] = [
  PERMISSIONS.VIEW_MATCH,
  PERMISSIONS.MANAGE_COMMENTS,
  PERMISSIONS.MANAGE_ALL_TEAMS,
  PERMISSIONS.MANAGE_ALL_TEAMS_SUPPORT,
  PERMISSIONS.CORRECT_AFTER_CLOSING,
  PERMISSIONS.ENTER_COMMENT,
  PERMISSIONS.MANAGE_SCORE,
  PERMISSIONS.MANAGE_CARDS,
  PERMISSIONS.APPROVE_AS_UMPIRE,
  PERMISSIONS.APPROVE_AS_SUPERUSER,
  PERMISSIONS.CANCEL_MATCH,
  PERMISSIONS.VIEW_TEAMS,
];

// These permissions expire after a match is closed
export const PERMISSIONS_EXPIRE: PermissionType[] = [
  PERMISSIONS.MANAGE_COMMENTS,
  PERMISSIONS.MANAGE_SCORE,
  PERMISSIONS.MANAGE_CARDS,
  PERMISSIONS.ENTER_COMMENT,
  PERMISSIONS.APPROVE_AS_UMPIRE,
  PERMISSIONS.APPROVE_AS_COACH,
  PERMISSIONS.CANCEL_MATCH,
];

export type PermissionPolicyType = 'all' | 'some';

export type PermissionType = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

export type PermissionsOptionsType = {
  teamId?: number | undefined;
  policy?: PermissionPolicyType;
};

export const PermissionsOptionsDefault: PermissionsOptionsType = {
  teamId: undefined,
  policy: 'all',
};

export type RoleCategory = {
  id: number;
  short_name: string;
  name: string;
};

export type RoleType = {
  role_id: number;
  category: RoleCategory;
  permissions: PermissionType[];
  teamId?: number | null;
  team_id: number;
};

export type RolesResponseType = {
  result: RoleType[];
};
