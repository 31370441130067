import { dwf_api_endpoint } from '../config/api';
import Client from './Client';

export const getPersonByFederationReferenceId = (pageKey) =>
  Client.get(
    `${dwf_api_endpoint}/person/by-federation-reference-id`,
    undefined,
    pageKey,
    'auth.getPersonByFederationReferenceId'
  );
