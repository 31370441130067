export const jerseyNumbersActive = (match) =>
  !!match.dwf_options && match.dwf_options.jersey_numbers === 1;

export const coachCanEnterScore = (match) =>
  !!match.dwf_options && match.dwf_options.coach_can_enter_score === 1;

export const hasGreenCards = (options) => !!options && options.cards_green === 1;

export const hasRedCards = (options) => !!options && options.cards_red === 1;

export const hasYellowCards = (options) => !!options && options.cards_yellow === 1;

export const hasCards = (options) =>
  !!options &&
  options.disciplines === 1 &&
  (hasYellowCards(options) || hasRedCards(options) || hasGreenCards(options));

export const hasShootout = (options) => !!options && options.shootouts === 1;

export const hasComments = (options) => !!options && options.comments === 1;

export const hasResults = (options) => !!options && options.results === 1;

export const hasPlayers = (options) => !!options && options.players === 1;

export const playerIsOldEnough = (age, minimumAge) => age >= minimumAge;
