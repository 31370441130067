import React from 'react';
import { styled } from 'styled-components';
import { COMMENT_TYPES } from '../../../@types/CommentTypes';
import { theme } from '../../templates/ui';
import { Button } from '../Button';
import { CommentScores } from '../CommentScores';
import Pencil from '../Icons/Pencil';
import Trash from '../Icons/Trash';

const StyledCommentItem = styled.div`
  padding: ${theme.sizing.xlarge};
`;

const StyledTitle = styled.h4`
  color: ${theme.color.primary.base};
  margin: 0;
  padding-bottom: 3px;
`;

const StyledTimeStamp = styled.div`
  font-weight: 400;
  margin-right: ${theme.sizing.large};
`;

const StyledBody = styled.div`
  p {
    margin: ${theme.sizing.small} 0 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledOptions = styled.div`
  margin: ${theme.sizing.small} 0 0;
  color: ${theme.color.cards.red};
`;

const StyledCommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${theme.sizing.small};
  margin-bottom: ${theme.sizing.large};
  border-bottom: 1px dashed ${theme.color.gray.light};
`;

const StyledAction = styled(Button)`
  padding-top: 0;
`;

const StyledPencil = styled(Pencil)`
  fill: ${theme.color.primary.base};
`;

const StyledTrash = styled(Trash)`
  fill: ${theme.color.primary.base};
`;

const Label = styled.div`
  background-color: ${theme.color.gray.light};
  border: 1px solid #d7d7d7;
  color: ${theme.color.gray.darkest};
  font-weight: 700;
  width: auto;
  margin-right: ${theme.sizing.small};
  padding: 3px 6px;
  border-radius: 3px;
  margin-right: ${theme.sizing.medium};
  margin-top: ${theme.sizing.small};
  margin-bottom: ${theme.sizing.small};
`;

const StyledLabel = styled(Label)`
  background-color: #fff1e3;
  color: ${theme.color.primary.base};
  border: 1px solid ${theme.color.primary.base};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const Comment = ({
  id,
  CommentType,
  CommentTitle,
  CommentTimeStamp,
  CommentBody,
  CommentIsChangeRequest,
  AwayScore,
  AwayShootoutScore,
  HomeScore,
  HomeShootoutScore,
  showActions,
  onEdit,
  onDelete,
}) => {
  return (
    <StyledCommentItem data-testid={'comment-' + id}>
      <StyledCommentHeader>
        <div>
          <StyledTitle>{CommentTitle}</StyledTitle>
          <StyledTimeStamp>{CommentTimeStamp}</StyledTimeStamp>
          <Flex>
            {Boolean(CommentType) && <Label data-testid="comment-type">{CommentType.label}</Label>}
            {CommentIsChangeRequest && (
              <StyledLabel data-testid="change-request">Wijzigingsverzoek</StyledLabel>
            )}
          </Flex>
          {CommentType?.name === COMMENT_TYPES.SCORE && (
            <CommentScores
              home={HomeScore}
              homeShootout={HomeShootoutScore}
              away={AwayScore}
              awayShootout={AwayShootoutScore}
            />
          )}
        </div>
        {showActions && (
          <StyledOptions>
            <StyledAction
              data-testid={'editCommentButton-' + id}
              onClick={() => onEdit(id)}
              clear={true}
            >
              <StyledPencil />
            </StyledAction>
            <StyledAction
              data-testid={'deleteCommentButton-' + id}
              onClick={() => onDelete(id)}
              clear={true}
            >
              <StyledTrash />
            </StyledAction>
          </StyledOptions>
        )}
      </StyledCommentHeader>
      <StyledBody>
        <p>{CommentBody}</p>
      </StyledBody>
    </StyledCommentItem>
  );
};
