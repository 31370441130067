import React, { useContext, useEffect, useReducer } from 'react';
import { produce } from 'immer';
import { styled } from 'styled-components';
import {
  addMatchPlayer,
  getRecentSubstitutesForTeam,
  searchPlayers,
  unassignPlayerFromMatch,
} from '../../../api/matches';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { withMatch } from '../../../contexts/MatchContext';
import { RoleContext } from '../../../contexts/RoleContext/RoleContext';
import { toggleMatchPlayer } from '../../../services/match';
import Loader from '../../atoms/Icons/Loader';
import PlusIcon from '../../atoms/Icons/PlusIcon';
import SubstituteShirt from '../../atoms/Icons/SubstituteShirt';
import { SubstitutePlayers } from '../../atoms/SubstitutePlayers';
import { Wrapper } from '../../atoms/Wrapper';
import ButtonContainer from '../../molecules/ButtonContainer';
import IconButton from '../../molecules/IconButton';
import MatchMeta from '../../molecules/MatchMeta';
import Search from '../../molecules/Search';
import TopBar from '../../molecules/TopBar';
import { theme } from '../../templates/ui';

const StyledMatchMeta = styled(MatchMeta)`
  padding: ${theme.sizing.large} ${theme.sizing.xlarge};
`;

const StyledWrapper = styled(Wrapper)`
  padding: 0;
`;

const SubstituteList = styled.div`
  padding: ${theme.sizing.xlarge} 0;
`;

const StyledHeader = styled.div`
  display: flex;
  padding-bottom: ${theme.sizing.small}
  border-bottom: 2px solid ${theme.color.primary.base};

  div:first-child {
    flex: 1;
  }
`;

const HeaderItem = styled.div`
  color: ${theme.color.base.base};
  padding-right: ${theme.sizing.xlarge};

  &:last-child {
    padding-right: 25px;
  }
`;

const StyledButton = styled(IconButton)`
  width: auto;
  padding-left: 40px;

  svg {
    left: 0.75rem;
  }

  &:focus {
    background-color: ${theme.color.secondary.hover};
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  fill: ${theme.color.body.base};
`;

const StyledMain = styled.div``;

const StyledLoader = styled(Loader)`
  width: 25px;
  position: absolute;
  top: -8px;
`;

const StyledPreviousSubstitutesTitle = styled.h2`
  font-size: ${theme.font.large};
  margin-top: ${theme.sizing.large};
  margin-bottom: 0;
  border-bottom: 1px solid ${theme.color.gray.light};
  padding: ${theme.sizing.large};
  background-color: ${theme.color.gray.lighter};
  color: ${theme.color.primary.base};
`;

const initialState = {
  playerResult: [],
  recentSubstitutes: [],
  buttonDisabled: true,
  loading: false,
  searchQuery: '',
  searchTeam: {
    id: null,
    club: {
      id: null,
    },
  },
};

const stateReducer = (state, action) => ({
  ...state,
  ...(typeof action === 'function' ? action(state) : action),
});

const SubstitutePage = ({ user, matchGame, updateMatchGame, history, showGenericError }) => {
  const [state, setState] = useReducer(stateReducer, initialState);
  const { role } = useContext(RoleContext);

  useEffect(() => {
    const searchTeam =
      matchGame.home_team.id === role?.team_id ? matchGame.home_team : matchGame.away_team;
    setState({
      searchTeam: searchTeam,
    });
  }, [role]);

  useEffect(() => {
    if (!state.searchTeam.id) {
      return;
    }

    setState({ loading: true });
    getRecentSubstitutesForTeam(
      matchGame.uuid,
      state.searchTeam.id,
      role,
      'match-substitutes'
    ).then(({ data }) => {
      setState({ recentSubstitutes: data, loading: false });
    });
  }, [state.searchTeam]);

  useEffect(() => {
    throttledSearch();
  }, [state.searchQuery]);

  const handleAddSubstitute = async (matchUuid, clubMemberId, teamId, personList) => {
    setState({ buttonDisabled: false });

    try {
      const result = await addMatchPlayer(
        matchUuid,
        clubMemberId,
        teamId,
        true,
        undefined,
        role,
        'match-substitutes'
      );

      const person = personList.find((clubMember) => clubMember.id === clubMemberId).person;

      const matchPlayer = produce(result.data, (draft) => {
        draft.club_member.person = person;
      });

      const match = produce(matchGame, (draft) => {
        draft.match_players[result.data.club_member.id] = matchPlayer;
      });

      updateMatchGame(match, null, 'match-substitutes');

      return match;
    } catch (e) {
      showGenericError(e, true, true);
    }
  };

  const handleRemoveSubstitute = async (matchUuid, clubMemberId, teamId, matchPlayerId) => {
    setState({ buttonDisabled: false });
    try {
      if (!matchPlayerId) {
        throw new Error('Match player not found');
      }

      await unassignPlayerFromMatch(matchUuid, matchPlayerId, teamId, role, 'match-substitutes');

      const match = produce(matchGame, (draft) => {
        toggleMatchPlayer(draft, clubMemberId, teamId, matchPlayerId);
      });

      updateMatchGame(match, null, 'match-substitutes');

      return match;
    } catch (e) {
      showGenericError(e, true, true);
    }
  };

  const handleSearch = async (clubId, queryString) => {
    setState({ loading: true });
    const results = await searchPlayers(
      clubId,
      queryString,
      matchGame.uuid,
      state.searchTeam.id,
      role,
      'match-substitutes'
    );

    const players = Object.values(results.data).filter((player) => {
      return !matchGame.match_players[player.id];
    });

    setState({ playerResult: players, loading: false });
  };

  let searchDelay;
  const throttledSearch = () => {
    clearTimeout(searchDelay);

    const searchQuery = state.searchQuery;

    if (searchQuery.length < 3) {
      return;
    }

    searchDelay = setTimeout(() => {
      handleSearch(state.searchTeam.club.id, searchQuery);
    }, 1000);
  };

  return (
    <>
      <TopBar>Invaller toevoegen</TopBar>
      <StyledWrapper>
        <StyledMatchMeta
          user={user}
          date={matchGame.date}
          matchCode={matchGame.match_code}
          commentsCount={matchGame.match_comments.length}
          approvals={matchGame.match_approvals}
        />
      </StyledWrapper>
      <Search
        searchClub={state.searchTeam}
        label="Speler zoeken"
        icon={
          state.loading ? <StyledLoader color={theme.color.primary.base} /> : <SubstituteShirt />
        }
        placeholder="Typ een naam"
        onClubChange={(clubId) => {
          const searchTeam =
            matchGame.home_team.id === clubId ? matchGame.home_team : matchGame.away_team;
          setState({
            searchTeam,
            playerResult: [],
            searchQuery: '',
          });
        }}
        onChange={(e) =>
          setState({
            searchQuery: e.currentTarget.value,
          })
        }
        value={state.searchQuery}
      />

      <Wrapper>
        <SubstituteList>
          <StyledHeader>
            <HeaderItem>Naam</HeaderItem>
            <HeaderItem>Team</HeaderItem>
            <HeaderItem />
            <HeaderItem />
          </StyledHeader>
          <StyledMain>
            {(!state.searchQuery && !state.loading && (
              <div>
                <StyledPreviousSubstitutesTitle data-testid="recent-substitutes-header">
                  Laatst geselecteerde invallers
                </StyledPreviousSubstitutesTitle>
                <SubstitutePlayers
                  user={user}
                  players={state.recentSubstitutes}
                  match={matchGame}
                  matchUuid={matchGame.uuid}
                  playersForTeamId={state.searchTeam.id}
                  divergentStyling={true}
                  onAddSubstitute={async (matchUuid, clubMemberId, teamId) => {
                    return handleAddSubstitute(
                      matchUuid,
                      clubMemberId,
                      teamId,
                      state.recentSubstitutes
                    );
                  }}
                  onRemoveSubstitute={async (matchUuid, clubMemberId, teamId, matchPlayerId) => {
                    return handleRemoveSubstitute(matchUuid, clubMemberId, teamId, matchPlayerId);
                  }}
                >
                  <p>Geen recente invallers gevonden</p>
                </SubstitutePlayers>
              </div>
            )) ||
              (!state.loading && (
                <SubstitutePlayers
                  user={user}
                  match={matchGame}
                  players={state.playerResult}
                  matchUuid={matchGame.uuid}
                  playersForTeamId={state.searchTeam.id}
                  onAddSubstitute={async (matchUuid, clubMemberId, teamId) => {
                    return handleAddSubstitute(matchUuid, clubMemberId, teamId, state.playerResult);
                  }}
                  onRemoveSubstitute={async (matchUuid, clubMemberId, teamId, matchPlayerId) => {
                    return handleRemoveSubstitute(matchUuid, clubMemberId, teamId, matchPlayerId);
                  }}
                >
                  <p>Geen resultaten</p>
                </SubstitutePlayers>
              ))}
          </StyledMain>
        </SubstituteList>
      </Wrapper>
      <ButtonContainer>
        <StyledButton
          disabled={state.buttonDisabled}
          small={true}
          onClick={() => {
            history.goBack();
          }}
        >
          <StyledPlusIcon />
          Speler toevoegen
        </StyledButton>
      </ButtonContainer>
    </>
  );
};

export const Substitute = withErrorHandler(withMatch(SubstitutePage));
