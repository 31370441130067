import { styled } from 'styled-components';
import { theme } from '../../templates/ui';

export const Bar = styled.div`
  background-color: ${theme.color.gray.base};
  width: 100%;
  padding: ${theme.sizing.xlarge} 0;
  border-bottom: 1px solid ${theme.color.gray.dark};
  text-align: center;
  color: ${theme.color.base.base};
  margin-bottom: ${theme.sizing.xxxxlarge};
  min-height: 49px;
`;
