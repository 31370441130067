import React from 'react';

type Props = React.SVGAttributes<SVGElement>;

const Whistle = ({ className }: Props) => (
  <svg
    width="22"
    height="16"
    viewBox="0 0 22 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g transform="matrix(-1 0 0 1 22 0)">
      <circle cx="13.471" cy="9.841" r="2.293" />
      <path d="M21.626 8.293l-.416-1.737a.886.886 0 0 0-.414-.565.891.891 0 0 0-.695-.098l-1.732.457a6.006 6.006 0 0 0-4.898-2.53H9.112v1.034H5.791V3.821l-4.459.028a.458.458 0 0 0-.455.457v2.168c0 .251.197.51.44.577L7.499 9.12a6.005 6.005 0 0 0-.047.72 6.02 6.02 0 0 0 12.04.033l1.496-.46a.948.948 0 0 0 .64-1.12zm-8.155 4.772a3.225 3.225 0 1 1 0-6.45 3.225 3.225 0 0 1 0 6.45zm5.945-4.125a5.998 5.998 0 0 0-.56-1.778l1.466-.394.396 1.773-1.302.399zM7.223.169h1v2.225h-1zM5.433.829l1.112 1.927-.867.5-1.111-1.927zM9.47.829l.865.5-1.11 1.927-.867-.5z" />
    </g>
  </svg>
);

export default Whistle;
