import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { styled } from 'styled-components';
import { withErrorHandler } from '../../../contexts/ErrorContext';
import { withMatch } from '../../../contexts/MatchContext';
import CardIcons from '../../molecules/CardIcons';
import { theme } from '../../templates/ui';
import { renderCards } from '../CardInfoItem';

const Name = styled.h4`
  margin: 0;
  padding: 6px 0;
  transition: 0.1s all ease-in-out;
  color: ${theme.color.gray.darkest};
`;

const IconOuter = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledIconsWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: ${theme.sizing.small};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledTeamSupporter = styled.li`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: ${(props) => {
    if (props.disabled) {
      return `${theme.sizing.large} ${theme.sizing.xlarge}`;
    }
    if (props.divergentStyling) {
      return `${theme.sizing.large}`;
    }

    return `${theme.sizing.large} 0`;
  }};
  border-bottom: 1px dashed ${theme.color.gray.light};
  margin: ${({ disabled }) => (disabled ? `0 calc(0px - ${theme.sizing.xlarge})` : '0')};
  background: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, .025);' : 'transparent')};
  background-color: ${(props) =>
    props.divergentStyling ? theme.color.gray.lighter : 'transparent'};

  &:last-of-type {
    border-bottom: none;
  }
`;

const TeamSupporterComponent = ({ className, disabled, personId, fullName, cards }) => {
  const [height, setHeight] = useState(0);

  const toggle = () => {
    setHeight(height === 0 ? 'auto' : 0);
  };

  return (
    <>
      <StyledTeamSupporter
        className={className}
        disabled={disabled}
        data-testid={'team-supporter-' + personId}
      >
        <Wrapper>
          <Name>{fullName}</Name>
          <IconOuter>
            <StyledIconsWrapper onClick={toggle} data-testid={'card-container'}>
              <CardIcons cards={cards} cardRole="C" isTeamSupport={true} />
            </StyledIconsWrapper>
          </IconOuter>
        </Wrapper>
      </StyledTeamSupporter>
      <AnimateHeight duration={500} height={height}>
        {cards.map((card) => !!card.is_coach_card && renderCards(card))}
      </AnimateHeight>
    </>
  );
};

export const TeamSupporter = withErrorHandler(withMatch(TeamSupporterComponent));
