import React from 'react';
import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import env from '../env';

const { bugsnag_api_key, bugsnag_app_version, environment } = env;

let _notifyError = (_error, _pageKey, _apiCallKey) => {
  return null;
};

let _ErrorBoundary = React.createContext().Provider;

const handleGroupingApiErrors = (event, pageKey, apiCallKey) => {
  // Group all errors together by error class and status code
  let groupingData = {
    errorClass: event.errorClass,
  };

  // Add status code to grouping data
  if (event.originalError.response) {
    groupingData.statusCode = event.originalError.response.status;
  }

  // Network/request aborted errors do not have a response, group them together by message
  if (!event.originalError.response && event.originalError?.message) {
    groupingData.message = event.originalError.message;
  }

  // Group all non 401 and 422 errors by api call and page
  if (
    event.originalError.response &&
    event.originalError.response?.status !== 401 &&
    event.originalError.response?.status !== 422
  ) {
    groupingData.apiCall = apiCallKey ?? event.originalError?.config.url;
    groupingData.page = pageKey ?? window.location.toString();
  }

  event.groupingHash = JSON.stringify(groupingData);
  event.context = (pageKey ? `[${pageKey}]: ` : '') + window.location.toString();

  if (event.originalError?.response?.data?.message) {
    event.errorMessage = `${event.errorMessage}: ${event.originalError.response.data?.message}`;
  }
};

if (bugsnag_api_key !== '') {
  Bugsnag.start({
    apiKey: bugsnag_api_key,
    appVersion: bugsnag_app_version,
    releaseStage: environment,
    enabledReleaseStages: ['Acceptance', 'Production', 'Testing'],
    plugins: [new BugsnagPluginReact(React)],

    onError: (event) => {
      if (event.errorMessage === 'Network Error') {
        event.ignore();
      }

      event.addMetadata('localStorage', localStorage);
      event.addMetadata('sessionStorage', sessionStorage);
      if (event.originalError instanceof Object) {
        event.addMetadata('response', event.originalError.response || '');
        event.addMetadata('error', event.originalError);
      }
    },
  });

  const notifyError = (error, pageKey, apiCallKey, options = {}) => {
    options.originalOnError = options.onError;

    const onError = (event) => {
      handleGroupingApiErrors(event, pageKey, apiCallKey);

      if (!!options.originalonError) {
        options.originalOnError(event);
      }
    };

    Bugsnag.notify(error, onError);
  };

  const ErrorBoundary = Bugsnag.getPlugin('react');

  _notifyError = notifyError;
  _ErrorBoundary = ErrorBoundary;
}

export { _notifyError as notifyError, _ErrorBoundary as ErrorBoundary };
